import { useQuery } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { notification } from 'antd'
import { store } from './store'
import * as _ from './underscore'

let reactQueryClient

export const setQueryClient = (client) => {
  reactQueryClient = client
}

export const queryClient = {
  fetchQuery: async (props) => reactQueryClient?.fetchQuery(props) ?? {},
  getQueryData: (props) => reactQueryClient?.getQueryData(props),
  invalidateQueries: async (props) =>
    reactQueryClient?.invalidateQueries(props) ?? {},
  prefetchQuery: async (props) => reactQueryClient?.prefetchQuery(props),
  refetchQueries: async (props) => reactQueryClient?.refetchQueries(props),
  setQueryData: (...props) => reactQueryClient?.setQueryData(...props),
}
const defaultFilter = ['queryKey']

const defaultBuildPayload = (p) => {
  if (!p) return p

  return Object.fromEntries(
    Object.entries(p).filter(([key]) => !defaultFilter.includes(key))
  )
}

export const errorHandler = (result, options) => {
  const { hasLabel = true } = options ?? {}

  if (__DEV__) {
    console.error(result)
  }

  if (hasLabel) {
    notification.error(result.error)
  }
}

/**
 * Helper for queries that shortens the boilerplate code to do the request
 * @param {String} generatorProps.path the path to the endpoint
 * @param {String} generatorProps.method POST or GET, defaults to GET in buildFetch
 * @param {Function} generatorProps.buildPayload create the payload for the request, gets the props as arguments, defaults to identity
 * @returns {Function} async function that does the request
 */
export const buildRequest =
  (generatorProps) =>
  async (...props) => {
    const {
      buildPayload = defaultBuildPayload,
      path,
      method,
      fetchOptions,
    } = generatorProps

    const payload = buildPayload(...props)

    const accessToken = getAccessToken(store.getState())

    const [url, fetchConfig] = buildFetch(
      {
        path,
        method,
        accessToken,
        payload,
      },
      { accessToken, ...fetchOptions }
    )

    const response = await fetch(url, fetchConfig)

    return resolveOrReject(response, null, false)
  }

const debug = {
  all: ['debug'],
}

const fetchDebug = async () => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch({ path: 'debug' }, { accessToken })

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useDebugQuery = () => {
  const result = useQuery({
    queryKey: debug.all,
    queryFn: fetchDebug,
    staleTime: 15 * _.min,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

  return result
}

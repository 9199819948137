import { DeleteOutlined } from '@ant-design/icons'
import { useDebounceCallback } from '@react-hook/debounce'
import {
  groupModes,
  headerModes,
} from '@tellonym/core/artificialTells/constants'
import { colors } from '@tellonym/core/common/colorSystem'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_STATUS,
} from '@tellonym/enums/lib/Tell'
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  notification,
  Segmented,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import qs from 'qs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { getPermissions } from '../../app/selectors'
import {
  Box,
  BoxSecondary,
  Col,
  helpers,
  history,
  hooks,
  moment,
  Row,
  ScrollView,
  styleSheets,
  Text,
} from '../../common'
import { updateLocationSearch } from '../../common/helpers'
import { useQueryParams } from '../../common/hooks'
import {
  createGroup,
  editTopic,
  refreshTopic,
  setHeaderMode,
} from '../actionsV2'
import {
  useActivateInactiveGroupsMutation,
  useDeleteInactiveGroupsMutation,
  useValidateInactiveGroupsMutation,
} from '../queries'
import {
  getGroupMode,
  getHeaderMode,
  getIsRefreshingTopicDetails,
  getLanguage,
} from '../selectorsV2'
import { ArtificialTellTopicGroupsTable } from './ArtificialTellTopicGroupsTable'
import { BreadcrumbHeader } from './BreadcrumbHeader'
import { ButtonModalCreateTodo } from './ButtonModalCreateTodo'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { ModalNotLocalizedSpeedTranslation } from './ModalNotLocalizedSpeedTranslation'
import { ModalTopicTags } from './ModalTopicTags'
import { ModalVarianceGenerator } from './ModalVarianceGenerator'
import {
  TextAreaWithLabel,
  TypeSelect,
} from './PageArtificialTellsGroupDetailsV2'
import { TodoItem } from './PageArtificialTellsTodos'
import { PerformanceCheckIns } from './PerformanceCheckIns'
import { TopicTags } from './TopicTags'

const TAB_HEIGHT = 212

const styles = {
  medianLabelStyle: {
    fontWeight: 'bold',
    marginRight: 8,
    marginLeft: 8,
  },
  marginRight16: {
    marginRight: 16,
  },
  gptAnalysisTextArea: {
    flex: 1,
    resize: 'none',
    borderColor: colors.grey[4],
  },
  smallButton: {
    fontSize: 11,
    width: 168,
    marginBottom: 8,
    marginRight: 8,
    marginLeft: 8,
  },
  tinyButton: { fontSize: 9, marginBottom: 12 },
}

const TextInput = ({
  isDisabled,
  keyName,
  label,
  maxLength,
  sanitize,
  topic,
}) => {
  const dispatch = ReactRedux.useDispatch()

  const [text, setText] = React.useState(topic[keyName] || '')

  const saveDescription = useDebounceCallback(
    React.useCallback(
      (text) => {
        dispatch(
          editTopic({
            id: topic.id,
            [keyName]: text,
          })
        )
      },
      [dispatch, keyName, topic]
    ),
    1500,
    false
  )

  const onChange = (e) => {
    let { value } = e.target

    if (typeof sanitize === 'function') {
      value = sanitize(value)
    }

    setText(value)
    saveDescription(value)
  }

  return (
    <TextAreaWithLabel
      label={label}
      value={text}
      onChange={onChange}
      isDisabled={isDisabled}
      maxLength={maxLength}
    />
  )
}

const TimeFrame = ({ topic }) => {
  const dispatch = ReactRedux.useDispatch()

  const isRefreshing = ReactRedux.useSelector(getIsRefreshingTopicDetails)

  const startDate = topic.validPeriod.startDate
    ? moment(topic.validPeriod.startDate)
    : undefined

  const endDate = topic.validPeriod.endDate
    ? moment(topic.validPeriod.endDate)
    : undefined

  const [isLimited, setIsLimited] = React.useState(
    topic.validPeriod.endDate || topic.validPeriod.startDate
  )

  const onChangeStartDate = (date, dateString) => {
    if (dateString !== startDate?.format('YYYY-MM-DD')) {
      dispatch(
        editTopic({
          id: topic.id,
          validPeriod: { startDate: dateString },
        })
      )
    }
  }

  const onChangeEndDate = (date, dateString) => {
    if (dateString !== endDate?.format('YYYY-MM-DD')) {
      dispatch(
        editTopic({
          id: topic.id,
          validPeriod: { endDate: dateString },
        })
      )
    }
  }

  const onChangeIsRepeatedYearly = (value) => {
    dispatch(
      editTopic({
        id: topic.id,
        validPeriod: { repeatedYearly: value },
      })
    )
  }

  const toggleIsLimited = () => {
    if (isLimited) {
      dispatch(
        editTopic({
          id: topic.id,
          validPeriod: { startDate: '', endDate: '', repeatedYearly: false },
        })
      )

      setIsLimited(false)
    } else {
      setIsLimited(true)
    }
  }

  return (
    <BoxSecondary
      flexDirection="row"
      alignItems="center"
      marginTop={12}
      flexWrap="wrap">
      <Tooltip
        placement="topLeft"
        title={
          isLimited
            ? 'Remove the time frame that this topic is limited to'
            : 'Set a time frame this group is limited to'
        }>
        <Button
          onClick={toggleIsLimited}
          style={styleSheets.margin.right[12]}
          danger={isLimited}>
          {isLimited ? <DeleteOutlined /> : 'Set limited time frame'}
        </Button>
      </Tooltip>

      {isLimited && (
        <Switch
          loading={isRefreshing}
          checked={topic.validPeriod.repeatedYearly}
          disabled={!isLimited}
          onChange={onChangeIsRepeatedYearly}
          checkedChildren="Repeated yearly"
          unCheckedChildren="Repeated yearly"
          style={{ width: 120 }}
        />
      )}

      {isLimited && (
        <Box transparent flexDirection="row" alignItems="center" marginTop={12}>
          <DatePicker
            value={startDate}
            disabled={!isLimited}
            onChange={onChangeStartDate}
            placeholder="Start date"
            style={{ width: 140, marginRight: 2 }}
          />
          <DatePicker
            value={
              topic.validPeriod.endDate
                ? moment(topic.validPeriod.endDate)
                : undefined
            }
            disabled={!isLimited}
            onChange={onChangeEndDate}
            placeholder="End date"
            style={{ width: 140, marginRight: 12 }}
          />
        </Box>
      )}
    </BoxSecondary>
  )
}

const NOTIFICATION_KEY = 'artTellGroupValidationPartiallyScheduled'

const ButtonValidationPage = ({ language }) => {
  return (
    <Button
      onClick={() => {
        history.push(`/artificialtells_v2/${language}/validation`)
        notification.close(NOTIFICATION_KEY)
      }}>
      Go to validation page
    </Button>
  )
}

const onSuccessValidateInactiveGroups = (language) => (data) => {
  const onPressResetFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)

    searchParams.delete('filterIds')

    history.replace({
      search: searchParams.toString(),
    })

    notification.close('resetFilter')
  }

  const setQueryParamFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)

    searchParams.set('filterIds', qs.stringify(data.declinedGroupIds))

    history.replace({
      search: searchParams.toString(),
    })

    notification.close('artTellGroupValidationPartiallyScheduled')

    notification.info({
      key: 'resetFilter',
      message:
        'Reset the filter by clicking the filter icon on the first column or with the button below.',
      duration: 15,
      btn: <Button onClick={onPressResetFilter}>Reset filter</Button>,
    })
  }

  if (data.isSuccess) {
    if (data.reason === '') {
      notification.success({
        key: NOTIFICATION_KEY,
        message: 'All Groups set for validation',
        description:
          'Groups might not yet show as validation state if they are only scheduled for validation. Check the validation page to see the current state.',
        duration: 8,
        btn: <ButtonValidationPage language={language} />,
        style: { width: 600 },
      })

      return
    }

    notification.warning({
      key: NOTIFICATION_KEY,
      duration: 15,
      message: <Text semibold>Groups partially set for validation</Text>,
      description: (
        <>
          <Text type="small">
            <Text bold type="small">
              {data.declinedGroupIds.length}
            </Text>{' '}
            groups were not scheduled due to the following reason:{' '}
            <Text bold type="small">
              {data.reason}
            </Text>
          </Text>
          <Text type="small" style={{ marginTop: 12 }}>
            Successfully scheduled {data.allowedGroupIds.length} groups
          </Text>
        </>
      ),
      btn: (
        <Box flexDirection="row">
          <ButtonValidationPage language={language} />
          <Button
            type="primary"
            onClick={setQueryParamFilter}
            style={{ marginLeft: 8 }}>
            Show not scheduled
          </Button>
        </Box>
      ),
      style: { width: 600 },
    })

    return
  }

  notification.error({
    key: NOTIFICATION_KEY,
    message: 'Groups not set for validation',
    description: `Reason: ${data.reason}`,
    duration: 8,
    style: { width: 600 },
  })
}

const ActionsSection = ({ language, topic, toggleCreateGroupSection }) => {
  const dispatch = ReactRedux.useDispatch()

  const canGenerateVariances = ModalVarianceGenerator.shouldShow({
    topicId: topic.id,
  })

  const {
    mutate: deleteInactiveGroups,
    isLoading: isLoadingDeleteInactiveGroups,
  } = useDeleteInactiveGroupsMutation({ topicId: topic.id, language })

  const {
    mutate: activateInactiveGroups,
    isLoading: isActivatingInactiveGroups,
  } = useActivateInactiveGroupsMutation({ topicId: topic.id, language })

  const {
    mutate: validateInactiveGroups,
    isLoading: isLoadingValidateInactiveGroups,
  } = useValidateInactiveGroupsMutation({
    topicId: topic.id,
    language,
  })

  const isRefreshingTopicDetails = ReactRedux.useSelector(
    getIsRefreshingTopicDetails
  )

  const onChangeDataMode = () => {
    dispatch(refreshTopic({ topicId: topic.id }))
  }

  const openSpeedGeneration = () => {
    history.push(
      `/artificialtells_v2/${language}/topic/${topic.id}/content-speed-generation`
    )
  }

  const openVarianceGeneration = () => {
    ModalVarianceGenerator.show({ language, topicId: topic.id })
  }

  const onPressDeleteInactiveGroups = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete all variances?',
      onOk: () => {
        deleteInactiveGroups()
      },
    })
  }

  return (
    <>
      <MedianDataForDataMode
        isLoading={isRefreshingTopicDetails}
        onChange={onChangeDataMode}
      />
      <Row justifyContent="space-around">
        <Col align="center" xs={24} xxl={{ span: 12, order: 1 }}>
          <Button
            loading={isActivatingInactiveGroups}
            size="small"
            onClick={activateInactiveGroups}
            style={styles.smallButton}>
            Activate Inactive Groups ({language.toUpperCase()})
          </Button>
        </Col>

        <Col align="center" xs={24} xxl={{ span: 12, order: 3 }}>
          <Button
            loading={isLoadingValidateInactiveGroups}
            size="small"
            type="primary"
            onClick={() =>
              validateInactiveGroups(undefined, {
                onSuccess: onSuccessValidateInactiveGroups(language),
              })
            }
            style={styles.smallButton}>
            Validate Inactive Groups ({language.toUpperCase()})
          </Button>
        </Col>

        <Col align="center" xs={24} xxl={{ span: 12, order: 5 }}>
          <Button
            loading={isLoadingDeleteInactiveGroups}
            type="dashed"
            danger
            size="small"
            onClick={onPressDeleteInactiveGroups}
            style={styles.smallButton}>
            Delete Inactive Groups ({language.toUpperCase()})
          </Button>
        </Col>

        <Col align="center" xs={24} xxl={{ span: 12, order: 2 }}>
          <Button
            size="small"
            onClick={toggleCreateGroupSection}
            style={styles.smallButton}>
            New Group
          </Button>
        </Col>

        <Col align="center" xs={24} xxl={{ span: 12, order: 4 }}>
          <Tooltip placement="right" title="Content Speed-Generation">
            <Button
              size="small"
              onClick={openSpeedGeneration}
              style={styles.smallButton}>
              Generate Groups
            </Button>
          </Tooltip>
        </Col>

        <Col align="center" xs={24} xxl={{ span: 12, order: 6 }}>
          <Tooltip
            placement="right"
            title={
              canGenerateVariances
                ? 'Generate Variances is a tool to quickly create more variances for questions in this topic. It will open all groups with inactive status that currently have <4 variances.'
                : 'No groups with inactive status that currently have <4 variances.'
            }
            trigger={['hover']}>
            <Button
              disabled={canGenerateVariances === false}
              size="small"
              onClick={openVarianceGeneration}
              style={styles.smallButton}>
              Generate Variances
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  )
}

const TopicCategoryTooltip = (props) => {
  return (
    <Tooltip
      title={`Topic Category link topics to special sending logics (e.g. sharing cards). When users share a certain card, they only receive tells from topics that have specified categories attached.
By default, questions tied to special logic are also included in normal sending logic. To send them ONLY via special logic, uncheck the Default Question Pool option.`}
      {...props}
    />
  )
}

const TopicCategory = ({ topic }) => {
  const dispatch = ReactRedux.useDispatch()

  const sanitizeTopicCategory = (value) => {
    return value.replace(/\s/g, '')
  }

  const onChangeIsDefaultQuestionPool = (e) => {
    dispatch(
      editTopic({
        id: topic.id,
        isDefaultQuestionPool: e.target.checked,
      })
    )
  }

  return (
    <Box transparent flexDirection="row" alignItems="center" gap={8}>
      <Box transparent>
        <TopicCategoryTooltip>
          <TextInput
            keyName="topicCategory"
            label="Topic Category"
            topic={topic}
            maxLength={30}
            sanitize={sanitizeTopicCategory}
          />
        </TopicCategoryTooltip>
      </Box>

      <TopicCategoryTooltip>
        <Box alignItems="center" flexDirection="row" marginTop={4} transparent>
          <Checkbox
            name="isDefaultQuestionPool"
            checked={topic.isDefaultQuestionPool}
            onChange={onChangeIsDefaultQuestionPool}
            style={{ width: 20, height: 20 }}
          />

          <Typography.Text style={{ fontSize: 10, marginTop: 2 }}>
            Default Question Pool
          </Typography.Text>
        </Box>
      </TopicCategoryTooltip>
    </Box>
  )
}

const ColTodo = ({ topic, todo }) => {
  const language = ReactRedux.useSelector(getLanguage)
  const permissions = ReactRedux.useSelector(getPermissions)
  const isTranslator = helpers.checkIsTranslator(permissions)

  return (
    <Col xs={24} lg={12} xl={6} xxl={4}>
      <Box transparent alignItems="flex-end" justifyContent="space-between">
        <TodoItem item={todo} width="100%" isDisabled={isTranslator} />

        <ButtonModalCreateTodo
          topic={topic}
          language={language}
          size="small"
          style={styleSheets.margin.top[12]}
          disabled={isTranslator}
        />
      </Box>
    </Col>
  )
}

const HeaderEditTab = ({
  topic,
  todo,
  language,
  toggleCreateGroupSection,
  isDisabled,
}) => {
  if (!topic.id) {
    return <Skeleton />
  }

  return (
    <Row justify="space-between" gutter={[12, 24]}>
      <Col xs={24} md={12} lg={16} xl={6} xxl={7}>
        <TextInput
          isBold
          disabled={isDisabled}
          keyName="name"
          label="Topic Name"
          topic={topic}
          paddingBottom={8}
        />

        <TextInput
          keyName="description"
          label="Description"
          topic={topic}
          paddingVertical={8}
        />

        <TopicTags
          tags={topic.topicTags}
          onPressTag={() => {
            ModalTopicTags.show()
          }}
        />

        <TopicCategory topic={topic} />
        <TimeFrame topic={topic} />
      </Col>

      <Col xs={24} md={12} lg={8} xl={5} xxl={6}>
        <ActionsSection
          topic={topic}
          language={language}
          toggleCreateGroupSection={toggleCreateGroupSection}
        />
      </Col>

      <Col xs={24} lg={12} xl={6}>
        <PerformanceCheckIns topicId={topic.id} language={language} />
      </Col>

      <ColTodo topic={topic} todo={todo} />
    </Row>
  )
}

const HeaderTranslateTab = ({ groups, topic, todo, language }) => {
  if (!topic.id) {
    return <Skeleton />
  }

  const hasNotLocalizedGroups = groups.some(
    (group) =>
      group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED &&
      group.depth !== ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP
  )

  return (
    <Row justify="space-between" gutter={[12, 24]}>
      <Col xs={24} xl={8} xxl={10}>
        <TextInput
          isDisabled
          isBold
          keyName="name"
          label="Topic Name"
          topic={topic}
          paddingBottom={8}
        />

        <TextInput
          isDisabled
          keyName="description"
          label="Description"
          topic={topic}
          paddingVertical={8}
        />

        <Tooltip
          title={
            hasNotLocalizedGroups
              ? undefined
              : 'No groups available that need a translation.'
          }
          placement="right">
          <Button
            disabled={!groups?.length || hasNotLocalizedGroups === false}
            type="primary"
            onClick={() =>
              ModalNotLocalizedSpeedTranslation.show({
                language,
                topicId: topic.id,
              })
            }
            style={styleSheets.margin.top[12]}>
            Start Speed-Translation
          </Button>
        </Tooltip>
      </Col>

      <Col xs={24} lg={12} xl={8}>
        <PerformanceCheckIns topicId={topic.id} language={language} />
      </Col>

      <ColTodo topic={topic} todo={todo} />
    </Row>
  )
}

const config = {
  submitAction: createGroup,
  defaultState: {
    type: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
    depth: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
    name: '',
  },
}

const CreateGroupSection = ({ topic, language }) => {
  const dispatch = ReactRedux.useDispatch()
  const inputRef = React.useRef(null)
  const [state, setState] = React.useState(config.defaultState)

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      topicId: topic.id,
    }))
  }, [topic])

  const onSubmit = () => {
    if (state.name === '') return

    dispatch(config.submitAction(state))

    setState((state) => ({
      ...state,
      name: '',
    }))
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit,
    hasActionKey: true,
  })

  const langUpper =
    DETECTABLE_LANGUAGE[langDetectObjectsByType1[language]] ?? ''

  const lang = `${langUpper[0]}${langUpper.substring(1).toLowerCase()}`

  return (
    <Box transparent flexDirection="row" marginTop={12}>
      <Box transparent flexDirection="row" flex={3}>
        <Input.TextArea
          ref={inputRef}
          autoSize={{ minRows: 2, maxRows: 5 }}
          onChange={(e) => {
            setState((state) => ({ ...state, name: e.target.value }))
          }}
          value={state.name}
          placeholder={`Enter a first variance for ${lang}...`}
          style={styleSheets.margin.right[12]}
        />

        <Box transparent marginRight={12} justifyContent="space-between">
          <Box transparent width={200}>
            <Box marginBottom={6} transparent>
              <TypeSelect
                type={state.type}
                onChange={(type) => {
                  setState((state) => ({ ...state, type }))
                }}
              />
            </Box>

            <Segmented
              block
              value={state.depth}
              options={[
                { label: 'Intro', value: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO },
                { label: 'Deep', value: ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP },
              ]}
              onChange={(depth) => {
                setState((state) => ({ ...state, depth }))
              }}
              size="small"
            />
          </Box>
        </Box>

        <Box transparent justifyContent="flex-end">
          <Button type="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      </Box>

      <Box transparent flex={1} />
    </Box>
  )
}

const Header = ({ groups, topic = {}, todo = {}, language }) => {
  const dispatch = ReactRedux.useDispatch()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const [isCreateGroupSectionVisible, setIsCreateGroupSectionVisible] =
    React.useState(false)

  const toggleCreateGroupSection = () => {
    setIsCreateGroupSectionVisible(
      (isCreateGroupSectionVisible) => !isCreateGroupSectionVisible
    )
  }

  const SelectedTab = React.useMemo(() => {
    switch (headerMode) {
      case headerModes.CONTENT:
        return HeaderEditTab
      case headerModes.TRANSLATE:
        return HeaderTranslateTab
      default: {
        switch (groupMode) {
          case groupModes.EDIT:
          case groupModes.STATS:
            dispatch(setHeaderMode(headerModes.CONTENT))
            return HeaderEditTab
        }

        return () => null
      }
    }
  }, [dispatch, groupMode, headerMode])

  if (!topic.id) {
    return <Skeleton />
  }

  return (
    <BoxSecondary paddingVertical={12} paddingHorizontal={24}>
      <BreadcrumbHeader
        language={language}
        topic={topic}
        modes={[headerModes.CONTENT, headerModes.TRANSLATE]}
      />

      <Box transparent minHeight={TAB_HEIGHT}>
        <SelectedTab
          groups={groups}
          topic={topic}
          todo={todo}
          language={language}
          toggleCreateGroupSection={toggleCreateGroupSection}
        />
      </Box>

      {isCreateGroupSectionVisible && (
        <CreateGroupSection topic={topic} language={language} />
      )}
    </BoxSecondary>
  )
}

export const PageArtificialTellsTopicDetails = ({ match }) => {
  const dispatch = ReactRedux.useDispatch()
  const [queryParams, setQueryParams] = useQueryParams()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const table = hooks.useTableState()

  const topicId = parseInt(match.params.topicId, 10)
  const language = ReactRedux.useSelector(getLanguage)

  const artificialTellsV2 = ReactRedux.useSelector(
    (state) => state.artificialTellsV2
  )

  const allData = artificialTellsV2.topicDetails[topicId]

  const allDataGroupsFiltered = queryParams.filterIds
    ? allData?.groups?.filter((group) =>
        queryParams.filterIds?.includes(group.id)
      )
    : allData?.groups

  React.useEffect(() => {
    updateLocationSearch({ groupMode })
  }, [groupMode])

  React.useEffect(() => {
    updateLocationSearch({ headerMode })
  }, [headerMode])

  React.useEffect(() => {
    dispatch(refreshTopic({ topicId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <Box>
      <Header
        language={language}
        topic={allData?.topic}
        todo={allData?.todo}
        groups={allData?.groups}
      />
      <ScrollView>
        <ArtificialTellTopicGroupsTable
          {...table}
          topicId={topicId}
          dataSource={allDataGroupsFiltered}
          language={language}
          locale={{
            emptyText: allData?.groups ? null : <Skeleton active={true} />,
          }}
          isDataSourceFiltered={!!queryParams.filterIds}
          onPressResetFilter={() => {
            setQueryParams({ filterIds: undefined })
          }}
          scroll={{ x: 0 }}
        />
      </ScrollView>
    </Box>
  )
}

export const predefinedApiHosts = {
  PRODUCTION: 'Production',
  STAGING: 'Staging',
  STAGING_GREEN: 'Staging green',
  STAGING_PURPLE: 'Staging purple',
  TESTING: 'Testing',
  TESTING_RED: 'Testing red',
  TESTING_BLUE: 'Testing blue',
  LOCAL: 'Local',
}

export const apiHostsRouteMap = {
  [predefinedApiHosts.PRODUCTION]: 'https://api.tellonym.me',
  [predefinedApiHosts.STAGING]: 'https://api-staging.tellonym.me',
  [predefinedApiHosts.STAGING_GREEN]: 'https://api-green.tellonym.me',
  [predefinedApiHosts.STAGING_PURPLE]: 'https://api-purple.tellonym.me',
  [predefinedApiHosts.TESTING]: 'https://api.tnym.de',
  [predefinedApiHosts.TESTING_RED]: 'https://api-red.tnym.de',
  [predefinedApiHosts.TESTING_BLUE]: 'https://api-blue.tnym.de',
  [predefinedApiHosts.LOCAL]: 'http://localhost:3005',
}

export const apiHostsColorMap = {
  [predefinedApiHosts.PRODUCTION]: 'magenta',
  [predefinedApiHosts.STAGING]: 'orange',
  [predefinedApiHosts.STAGING_GREEN]: 'green',
  [predefinedApiHosts.STAGING_PURPLE]: 'purple',
  [predefinedApiHosts.TESTING]: 'blue',
  [predefinedApiHosts.TESTING_RED]: 'red',
  [predefinedApiHosts.TESTING_BLUE]: 'geekblue',
  [predefinedApiHosts.LOCAL]: 'default',
}

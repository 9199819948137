import { countriesToTrack } from '@tellonym/enums/lib/Stats'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Input,
  Radio,
  Row,
  Tag,
  Typography,
} from 'antd'
import React from 'react'
import Helmet from 'react-helmet'
import * as Redux from 'react-redux'
import { Box, ScrollView, Text, View, styleSheets } from '../../common'
import * as hooks from '../../common/hooks'
import { useDebugQuery } from '../../common/queries'
import { refreshChShortnames } from '../actions'
import { dateTypes, shortnameDataOptions, shortnameOptions } from '../constants'

import { useSelectedShortnames } from '../hooks'
import { useCompareMutation } from '../queries'
import { getChShortnamesState } from '../selectors'
import { InputValidation } from './InputValidation'
import { ShortNamesSelector } from './ShortNamesSelector'
import { TimeFramePicker } from './TimeFramePicker'

const styles = {
  checkboxText: { marginLeft: 8 },
  compareSelector: { width: 130, marginLeft: 12 },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 12,
  },
  italic: { fontStyle: 'italic' },
  smallTitle: { marginTop: 8, fontWeight: '700' },
}

const stringifyObject = (obj) =>
  Object.keys(obj)
    .reduce((acc, key) => {
      acc.push(`${key}: ${JSON.stringify(obj[key], null, 2)}`)

      return acc
    }, [])
    .join('\n')

const getCountryString = (expKey, countryMapping) => {
  const countries = Object.keys(countryMapping).reduce((acc, curr) => {
    if (countryMapping[curr][expKey] >= 0) {
      acc.push(curr)

      return acc
    }

    return acc
  }, [])

  const countriesString = countries
    .map(
      (c) => `${c.toLocaleUpperCase()} (${countryMapping[c][expKey] * 100}%)`
    )
    .join(', ')
    .replace('all', 'global')
    .toLocaleUpperCase()

  return countriesString
}

const createExpDataToRender = (remoteExperimentConfig) => {
  if (!remoteExperimentConfig) {
    return []
  }

  const { experimentsData, experimentsCountryMapping, newUserCountryMapping } =
    remoteExperimentConfig

  const { base, countriesBase, ...expData } = experimentsData

  const mappedExperiments = Object.keys(expData)
    .sort()
    .reverse()
    .reduce((acc, key) => {
      if (['base', 'countriesBase'].includes(key)) {
        return acc
      }

      const countriesString = getCountryString(key, experimentsCountryMapping)
      const newUserCountryString = getCountryString(key, newUserCountryMapping)

      const exp = experimentsData[key]

      const header = () => (
        <span>
          <span style={{ marginRight: 8 }}>{`${key} - ${exp.name}`}</span>
          {countriesString !== '' && (
            <Tag color="blue">Users: {countriesString}</Tag>
          )}
          {newUserCountryString !== '' && (
            <Tag color="orange">New Users: {newUserCountryString}</Tag>
          )}
        </span>
      )

      const triggers = exp.triggers?.join?.(', ')

      const configString = stringifyObject(exp.config)

      acc.push({
        id: key,
        header,
        triggers,
        shouldApplyOnRestart: exp.shouldApplyOnRestart,
        config: configString,
      })

      return acc
    }, [])

  return mappedExperiments
    .concat([
      {
        id: 'base',
        header: 'BASE',
        config: stringifyObject(base),
      },
    ])
    .concat(
      Object.keys(countriesBase)
        .sort()
        .map((key) => ({
          id: key,
          header: key.toLocaleUpperCase(),
          config: stringifyObject(countriesBase[key]),
        }))
    )
}

const ExpConfigViewer = () => {
  const [shouldShowRaw, setShouldShowRaw] = React.useState(false)
  const { data } = useDebugQuery()

  const exps = React.useMemo(
    () => createExpDataToRender(data?.remoteExperimentConfig),
    [data?.remoteExperimentConfig]
  )

  if (typeof data?.remoteExperimentConfig === 'undefined') {
    return null
  }

  const onChangeCheckbox = (e) => {
    setShouldShowRaw(e.target.checked)
  }

  return (
    <>
      <Box flexDirection="row" marginBottom={12} alignItems="center">
        <Checkbox onChange={onChangeCheckbox} />
        <Text style={styles.checkboxText}>show raw</Text>
      </Box>
      <ScrollView
        contentContainerStyle={{ backgroundColor: 'rgba(0,0,0,0.025)' }}
        style={{ height: 575 }}>
        {shouldShowRaw ? (
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              overflow: 'auto',
            }}>
            {JSON.stringify(data.remoteExperimentConfig, null, 2)}
          </pre>
        ) : (
          <Box transparent>
            <Collapse ghost>
              {exps.map((exp) => (
                <Collapse.Panel
                  header={
                    typeof exp.header === 'function' ? (
                      <exp.header />
                    ) : (
                      exp.header
                    )
                  }
                  key={exp.id}
                  style={{ backgroundColor: 'transparent' }}>
                  <Box transparent marginLeft={36}>
                    <Typography.Text type="secondary">{`Trigger: ${exp.triggers}`}</Typography.Text>
                    {exp.shouldApplyOnRestart && (
                      <Typography.Text type="secondary">
                        Applies on restart
                      </Typography.Text>
                    )}
                    <Typography.Text style={styles.smallTitle}>
                      Config
                    </Typography.Text>

                    <Text type="note" style={styleSheets.margin.left[12]}>
                      {exp.config ? (
                        exp.config
                      ) : (
                        <span style={{ fontStyle: 'italic' }}>No config</span>
                      )}
                    </Text>
                  </Box>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Box>
        )}
      </ScrollView>
    </>
  )
}

const useValueSelectorFactory = (payload) => {
  const [values, setValues] = React.useState({
    dateType: payload?.dateType ?? dateTypes.LAST_21_DAYS,
    country: 'DE',
  })

  const onChangeCreator = (key) => (value) => {
    setValues({ ...values, [key]: value })
  }

  const onChangeCountry = ([country]) => {
    setValues({ ...values, country })
  }

  const onChangeDates = (dates, dateType) => {
    setValues({ ...values, dates, dateType })
  }

  const setterCollection = {
    onChangeCohort: onChangeCreator('cohort'),
    onChangeCountry,
    onChangeDates,
    onChangeExperimentId: onChangeCreator('experimentId'),
    onChangeDateType: onChangeCreator('dateType'),
  }

  return { ...values, setter: setterCollection }
}

const shortnameOptionKeys = Object.keys(shortnameOptions)
const shortnameDataOptionKeys = Object.keys(shortnameDataOptions)

const PageChCompare = () => {
  const dispatch = Redux.useDispatch()

  const containerStyle = hooks.usePageContainerStyle()
  const control = useValueSelectorFactory()
  const experiment = useValueSelectorFactory({ dateType: dateTypes.SYNCED })
  const [expPercentage, setExpPercentage] = React.useState(0.5)
  const [statsType, setStatsType] = React.useState('')
  const [timeFrame, setTimeFrame] = React.useState('full')
  const [selectedShortnameOption, setSelectedShortnameOption] = React.useState(
    shortnameOptionKeys[0]
  )
  const [selectedDataOptions, setSelectedDataOptions] = React.useState([])
  const [shouldSummarize, setShouldSummarize] = React.useState(false)

  const { data, isLoading, isSuccess, mutate } = useCompareMutation()

  const { data: shortnamesData, isRefreshing: isRefreshingShortnames } =
    Redux.useSelector(getChShortnamesState)

  React.useEffect(() => {
    dispatch(refreshChShortnames())
  }, [])

  const selectedShortnames = useSelectedShortnames()

  const onPressRefresh = () => {
    mutate({
      control,
      experiment,
      expPercentage,
      shortNames: selectedShortnames,
      shouldSummarize,
      statsType,
    })
  }

  const onPressOpenSheet = () => {
    if (isSuccess && typeof data?.sheetUrl === 'string') {
      window.open(data.sheetUrl, '_blank')
    }
  }

  const onChangeTimeFrame = (e) => {
    setTimeFrame(e.target.value)
  }

  const onChangeExpPercentage = (value) => {
    setExpPercentage(value)
  }

  const onChangeStatsType = (event) => {
    setStatsType(event.target.value)
  }

  const onChangeShortnameOption = (e) => {
    setSelectedShortnameOption(e.target.value)
  }

  const onChangeDataOption = (values) => {
    setSelectedDataOptions(values)
  }

  const onChangeShouldSummarize = (e) => {
    setShouldSummarize(e.target.checked)
  }

  React.useEffect(() => {
    const hasExp = timeFrame === 'full'

    setStatsType(
      `${selectedShortnameOption}${
        selectedDataOptions.length > 0
          ? `-${selectedDataOptions.join('-')}`
          : ''
      }${hasExp ? '-EXP' : ''}`
    )
  }, [selectedShortnameOption, selectedDataOptions, timeFrame])

  return (
    <View style={containerStyle}>
      <Helmet title="Compare - Tellonym Modcp" />
      <Box padding={16}>
        <Typography.Title level={3}>Statistic Compare</Typography.Title>
        <Box flexDirection="row" gap={8}>
          <Box flex={3} gap={8}>
            <Typography.Title level={4}>Settings</Typography.Title>

            <Box flexDirection="row" alignItems="center">
              <Typography.Text strong style={{ width: 120, marginRight: 12 }}>
                Experiment IDs
              </Typography.Text>

              <Box flexDirection="row" gap={4}>
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={control.setter.onChangeExperimentId}
                  validateValue={(value) => Number.isInteger(Number(value))}
                  label="Control"
                  width={80}
                />
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={experiment.setter.onChangeExperimentId}
                  validateValue={(value) => Number.isInteger(Number(value))}
                  label="Exp"
                  width={80}
                />
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={onChangeExpPercentage}
                  validateValue={(value) => Number.isInteger(Number(value))}
                  label="Exp PCT"
                  placeholder="0.5"
                  width={80}
                />
              </Box>
            </Box>

            <InputValidation
              defaultValue="DE"
              options={countriesToTrack.map((v) => ({ label: v, value: v }))}
              isDisabled={isLoading}
              onChangeText={control.setter.onChangeCountry}
              text="Country"
            />

            <Box flexDirection="row" alignItems="center">
              <Typography.Text strong style={{ width: 120, marginRight: 12 }}>
                Cohort
              </Typography.Text>

              <Box flexDirection="row" gap={4}>
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={control.setter.onChangeCohort}
                  label="Control"
                  width={80}
                />
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={experiment.setter.onChangeCohort}
                  label="Exp"
                  width={80}
                />
              </Box>
            </Box>

            <Box flexDirection="row" alignItems="center">
              <Typography.Text strong style={{ width: 120, marginRight: 12 }}>
                Time Frame
              </Typography.Text>
              <Radio.Group
                buttonStyle="solid"
                options={[
                  { label: 'Full exp length', value: 'full' },
                  { label: 'Custom Dates', value: 'custom' },
                ]}
                onChange={onChangeTimeFrame}
                optionType="button"
                value={timeFrame}
                style={{ marginTop: 12 }}
              />
            </Box>

            {timeFrame === 'custom' && (
              <>
                <Typography.Text type="secondary">Control Time</Typography.Text>
                <TimeFramePicker
                  defaults={{
                    dateType: dateTypes.LAST_21_DAYS,
                    startDate: undefined,
                    endDate: undefined,
                  }}
                  isIntervalTypeVisible={false}
                  {...control.setter}
                />
                <Typography.Text type="secondary">EXP Time</Typography.Text>
                <TimeFramePicker
                  defaults={{
                    dateType: dateTypes.SYNCED,
                    startDate: undefined,
                    endDate: undefined,
                  }}
                  isIntervalTypeVisible={false}
                  {...control.setter}
                />
              </>
            )}
          </Box>
          <Box flex={5}>
            <Typography.Title level={4}>Shortnames</Typography.Title>
            <ShortNamesSelector
              groups={shortnamesData}
              isRefreshing={isRefreshingShortnames}
            />

            <Box marginTop={24}>
              <Typography.Title level={5}>Shortname Options</Typography.Title>
              <Radio.Group
                style={{ width: '100%' }}
                value={selectedShortnameOption}
                onChange={onChangeShortnameOption}>
                <Row>
                  {shortnameOptionKeys.map((option) => (
                    <Col span={12}>
                      <Radio
                        value={option}
                        style={styleSheets.margin.bottom[8]}>
                        {shortnameOptions[option]}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>

              <Typography.Title level={5} style={styleSheets.margin.top[12]}>
                Data Options
              </Typography.Title>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={selectedDataOptions}
                onChange={onChangeDataOption}>
                <Row>
                  {shortnameDataOptionKeys.map((option) => (
                    <Col span={24}>
                      <Checkbox
                        value={option}
                        style={styleSheets.margin.bottom[8]}>
                        {shortnameDataOptions[option]}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>

              <Checkbox
                checked={shouldSummarize}
                onChange={onChangeShouldSummarize}>
                Summarize Values
              </Checkbox>

              <Typography.Text
                type="secondary"
                style={{ marginTop: 24, fontSize: 12 }}>
                Stats Type
              </Typography.Text>
              <Input
                placeholder="Stats type"
                value={statsType}
                onChange={onChangeStatsType}
                style={{ width: 240 }}
              />
            </Box>
          </Box>

          <Box flex={3}>
            <Typography.Title level={4}>Experiment Config</Typography.Title>
            <ExpConfigViewer />
          </Box>
        </Box>

        <Box flex={1} marginTop={48} alignItems="center">
          <Box marginTop={48} flexDirection="row">
            <Button
              type="primary"
              size="large"
              shape="round"
              loading={isLoading}
              onClick={onPressRefresh}>
              Generate Sheet
            </Button>
            <Button
              disabled={!isSuccess}
              size="large"
              shape="round"
              onClick={onPressOpenSheet}
              style={styleSheets.margin.left[12]}>
              Open Sheet
            </Button>
          </Box>
        </Box>
      </Box>
    </View>
  )
}

export { PageChCompare }

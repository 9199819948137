import { INTERVAL_TYPE } from '@tellonym/enums/lib/Stats'
import { DatePicker, InputNumber, Radio, Select } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { View, styleSheets } from '../../common'
import { useQueryParams } from '../../common/hooks'
import { dateTypeDefaults, dateTypes, weekTypeDefaults } from '../constants'
import { dateTypeToDates, snakeToCamelCase } from '../services'

const dateTypeOptionDefaults = dateTypeDefaults.map((dateType) => ({
  value: dateType,
  label: snakeToCamelCase(dateType),
}))

const intervalOptions = [
  { value: INTERVAL_TYPE.DAILY, label: 'daily' },
  { value: INTERVAL_TYPE.WEEKLY, label: 'weekly' },
]

const getDisabledDate = (current) =>
  current && current > dayjs().subtract(1, 'day').endOf('day')

const getStateForDayDefaults = (defaults) => {
  const initialState = { ...defaults }

  if (
    !defaults.dateType ||
    dateTypeDefaults.includes(defaults.dateType) === false
  ) {
    initialState.dateType = dateTypeOptionDefaults[0].value
  }

  if (defaults.startDate || defaults.endDate) {
    initialState.dates = [dayjs(defaults.startDate), dayjs(defaults.endDate)]

    return initialState
  }

  if (defaults.dateType === dateTypes.SYNCED) {
    initialState.dates = []

    return initialState
  }

  const defaultDateTypeDates = dateTypeToDates({
    dateType: defaults.dateType,
    startDate: defaults.startDate,
    endDate: defaults.endDate,
  })

  initialState.dates = [
    dayjs(defaultDateTypeDates.startDate),
    dayjs(defaultDateTypeDates.endDate),
  ]

  return initialState
}

const DayPicker = ({
  dateTypeOptions = dateTypeOptionDefaults,
  defaults,
  isLoading,
  onChangeDateType: onChangeDateTypeCallback,
  onChangeDates: onChangeDatesCallback,
  style,
}) => {
  const [state, setState] = useState(() => getStateForDayDefaults(defaults))

  const onChangeDateType = (dateType) => {
    const newState = {
      dateType,
    }

    const dates = dateTypeToDates({
      dateType,
      startDate: defaults.startDate,
      endDate: defaults.endDate,
    })

    if (dates.startDate || dates.endDate) {
      newState.dates = [dayjs(dates.startDate), dayjs(dates.endDate)]
    }

    setState((s) => ({
      ...s,
      ...newState,
    }))

    if (newState.dateType !== dateTypes.CUSTOM_DATE) {
      onChangeDatesCallback?.(null, newState.dateType)
    }
  }

  const onChangeDates = (dates) => {
    const newState = {
      dates,
    }

    if (dates?.every((d) => d.isValid())) {
      if (state.dateType !== dateTypes.CUSTOM_DATE) {
        newState.dateType = dateTypes.CUSTOM_DATE
      }
    }

    setState((s) => ({
      ...s,
      ...newState,
    }))

    onChangeDatesCallback?.(newState.dates, newState.dateType ?? state.dateType)
  }

  // This is to sync when initial state for dateType is set differently as defaults are
  React.useEffect(() => {
    if (state.dateType !== defaults.dateType) {
      onChangeDateTypeCallback?.(state.dateType)
    }
  }, [state.dateType])

  return (
    <View
      style={[
        styleSheets.flex.direction.row,
        styleSheets.margin.bottom[16],
        style,
      ]}>
      <Select
        disabled={isLoading}
        options={dateTypeOptions}
        onChange={onChangeDateType}
        value={state.dateType}
        style={{ width: 160 }}
      />
      <DatePicker.RangePicker
        disabled={isLoading || state.dateType === dateTypes.SYNCED}
        defaultPickerValue={[
          dayjs().subtract(31, 'days'),
          dayjs().subtract(1, 'day'),
        ]}
        disabledDate={getDisabledDate}
        onChange={onChangeDates}
        value={state.dates}
        style={styleSheets.margin.left[12]}
      />
    </View>
  )
}

const weekTypeOptionDefaults = weekTypeDefaults.map((weekType) => ({
  value: weekType,
  label: snakeToCamelCase(weekType),
}))

const getStateForWeekDefaults = (defaults) => {
  const initialState = { ...defaults }

  if (
    !defaults.dateType ||
    weekTypeDefaults.includes(defaults.dateType) === false
  ) {
    initialState.dateType = weekTypeOptionDefaults[0].value
  }

  if (defaults.startDate || defaults.endDate) {
    initialState.week =
      dayjs(defaults.endDate).diff(dayjs(defaults.startDate), 'week') + 1

    return initialState
  }

  if (defaults.dateType === dateTypes.SYNCED) {
    initialState.week = 1

    return initialState
  }

  const defaultDateTypeDates = dateTypeToDates({
    dateType: defaults.dateType,
    startDate: defaults.startDate,
    endDate: defaults.endDate,
  })

  initialState.week =
    dayjs(defaultDateTypeDates.endDate).diff(
      dayjs(defaultDateTypeDates.startDate),
      'week'
    ) + 1

  return initialState
}

const getDatesForWeek = (week) => {
  const endDate = dayjs().subtract(1, 'week').endOf('isoWeek')

  const startDate = dayjs().subtract(week, 'week').startOf('isoWeek')

  return [startDate, endDate]
}

const WeekPicker = ({
  dateTypeOptions = weekTypeOptionDefaults,
  defaults,
  isLoading,
  onChangeDateType: onChangeDateTypeCallback,
  onChangeDates: onChangeDatesCallback,
  style,
}) => {
  const [state, setState] = useState(() => getStateForWeekDefaults(defaults))

  const onChangeDateType = (dateType) => {
    const newState = {
      dateType,
    }

    const [_, timespan] = dateType.match(/_(\d*)_WEEKS/) ?? []

    if (timespan) {
      newState.week = Number(timespan)
    }

    setState((s) => ({
      ...s,
      ...newState,
    }))

    if (newState.dateType !== dateTypes.CUSTOM_DATE) {
      onChangeDatesCallback?.(null, newState.dateType)
    }
  }

  const onChangeWeekNumber = (week) => {
    const newState = {
      week,
    }

    if (state.dateType !== dateTypes.CUSTOM_DATE) {
      newState.dateType = dateTypes.CUSTOM_DATE
    }

    setState((s) => ({
      ...s,
      ...newState,
    }))

    const dates = getDatesForWeek(newState.week)

    onChangeDatesCallback?.(dates, newState.dateType ?? state.dateType)
  }

  // This is to sync when initial state for dateType is set differently as defaults are
  React.useEffect(() => {
    if (state.dateType !== defaults.dateType) {
      onChangeDateTypeCallback?.(state.dateType)
    }
  }, [state.dateType])

  return (
    <View
      style={[
        styleSheets.flex.direction.row,
        styleSheets.margin.bottom[16],
        style,
      ]}>
      <Select
        disabled={isLoading}
        options={dateTypeOptions}
        onChange={onChangeDateType}
        value={state.dateType}
        style={{ width: 160 }}
      />
      <InputNumber
        disabled={isLoading}
        min={1}
        max={53}
        value={state.week}
        onChange={onChangeWeekNumber}
        style={styleSheets.margin.left[12]}
      />
    </View>
  )
}

/**
 * defaults: dateType, selectedInterval, startDate, endDate
 */
export const TimeFramePicker = ({
  dateTypeOptions,
  defaults = {},
  isLoading,
  isIntervalTypeVisible = true,
  onChangeDateType,
  onChangeDates,
  onChangeInterval: onChangeIntervalCallback,
  style,
}) => {
  const [selectedInterval, setSelectedInterval] = useState(
    defaults.selectedInterval
  )

  const onChangeInterval = (e) => {
    setSelectedInterval(e.target.value)
    onChangeIntervalCallback?.(e.target.value)
  }

  return (
    <>
      {isIntervalTypeVisible && (
        <Radio.Group
          disabled={isLoading}
          buttonStyle="solid"
          onChange={onChangeInterval}
          options={intervalOptions}
          optionType="button"
          value={selectedInterval}
          style={styleSheets.margin.bottom[16]}
        />
      )}

      {selectedInterval === INTERVAL_TYPE.WEEKLY ? (
        <WeekPicker
          defaults={defaults}
          dateTypeOptions={dateTypeOptions}
          isLoading={isLoading || defaults.dateType === dateTypes.SYNCED}
          onChangeDateType={onChangeDateType}
          onChangeDates={onChangeDates}
          style={style}
        />
      ) : (
        <DayPicker
          defaults={defaults}
          dateTypeOptions={dateTypeOptions}
          isLoading={isLoading || defaults.dateType === dateTypes.SYNCED}
          onChangeDateType={onChangeDateType}
          onChangeDates={onChangeDates}
          style={style}
        />
      )}
    </>
  )
}

/**
 * Uses TimeFramePicker component and adds query param logic to it. This means all values are
 * part of the url, defaults are read from it and values get updated.
 *
 * @param {{defaultQueryParams}} param0
 * @returns
 */
export const TimeFramePickerQueryParam = ({ defaultQueryParams, ...props }) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultQueryParams)

  const defaults = React.useMemo(
    () => ({
      dateType: queryParams.dateType,
      selectedInterval: Number(queryParams.intervalType),
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
    }),
    [
      queryParams.dateType,
      queryParams.endDate,
      queryParams.intervalType,
      queryParams.startDate,
    ]
  )

  const onChangeDateType = React.useCallback(
    (dateType) => {
      setQueryParams({ dateType })
    },
    [setQueryParams]
  )

  const onChangeDates = React.useCallback(
    (dates) => {
      if (dates === null) {
        setQueryParams({ startDate: '', endDate: '' })
      } else {
        setQueryParams({
          startDate: dates[0].format('YYYY-MM-DD'),
          endDate: dates[1].format('YYYY-MM-DD'),
        })
      }
    },
    [setQueryParams]
  )

  const onChangeInterval = React.useCallback(
    (intervalType) => {
      setQueryParams({ intervalType })
    },
    [setQueryParams]
  )

  return (
    <TimeFramePicker
      {...props}
      defaults={defaults}
      onChangeDateType={onChangeDateType}
      onChangeInterval={onChangeInterval}
      onChangeDates={onChangeDates}
    />
  )
}

import { faEyeSlash, faRedo } from '@fortawesome/free-solid-svg-icons'
import { getImageSizeWeb } from '@tellonym/core/helpers'
import React, { useState } from 'react'
import { theme } from '../styles/theme'
import { Icon } from './Icon'
import { Image } from './Image'
import { Modal } from './Modal'
import { Spinner } from './Spinner'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

const Warning = () => (
  <View
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderRadius: 8,
      zIndex: 99,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    }}>
    <Icon
      icon={faEyeSlash}
      color={theme.colors.white}
      style={{ marginBottom: 8 }}
    />
    <Text
      type="small"
      bold
      center
      color={theme.colors.white}
      style={{ marginBottom: 8 }}>
      Sensitive Content
    </Text>
    <Text type="small" center color={theme.colors.white}>
      This picture could contain content which some people may find offensive or
      disturbing.
    </Text>
  </View>
)

const ShowImageFullscreen = ({ fullscreenSource, onPressHide }) => {
  const isRemotePicture = /^https?:\/\//.test(fullscreenSource)

  const [hasLoaded, setHasLoaded] = useState(!isRemotePicture)
  const [size, setSize] = useState({ width: 0, height: 0 })

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.black,
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <TouchableOpacity
        onPress={onPressHide}
        style={{ position: 'absolute', top: 16, right: 16 }}>
        <Text color={theme.colors.white}>Close</Text>
      </TouchableOpacity>
      {!hasLoaded ? (
        <View
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 2,
          }}>
          <Spinner />
        </View>
      ) : null}
      <View style={{ maxWidth: 600 }}>
        <Image
          source={fullscreenSource}
          onLoad={(e) => {
            setSize({
              width: e.nativeEvent.target.width,
              height: e.nativeEvent.target.height,
            })

            setHasLoaded(true)
          }}
          style={
            size.height > 0
              ? {
                  aspectRatio: size.width / size.height,
                  height:
                    size.height > window.innerHeight
                      ? window.innerHeight
                      : size.height,
                }
              : undefined
          }
        />
      </View>
    </View>
  )
}
export class FullscreenImage extends React.PureComponent {
  state = {
    hasFailedToLoad: false,
    hasWarning: this.props.hasWarning,
    height: 0,
  }

  async componentDidMount() {
    const size = await getImageSizeWeb(
      typeof this.props.fullscreenSource === 'string'
        ? this.props.fullscreenSource
        : this.props.fullscreenSource.uri
    )

    this.setState({ height: Math.min(size.height, 400) })
  }

  _onPressHide = () => {
    Modal.hide({ id: 'FullscreenImage' })
  }

  _onPressShow = () => {
    const { eventName, fullscreenSource } = this.props
    const { hasWarning } = this.state

    if (hasWarning) {
      this.setState({ hasWarning: false })
      return
    }

    Modal.show({
      animationType: Modal.Animations.FADE,
      event: {
        name: eventName,
      },
      id: 'FullscreenImage',
      shouldHideOnBackgroundPress: true,
      render: () => (
        <ShowImageFullscreen
          fullscreenSource={fullscreenSource}
          onPressHide={this._onPressHide}
        />
      ),
    })
  }

  _onError = () => {
    this.setState({ hasFailedToLoad: true }, this.props.onError)
  }

  _onRetry = () => {
    this.setState({ hasFailedToLoad: false })
  }

  render() {
    const {
      containerStyle,
      disabled,
      fullscreenSource, // eslint-disable-line
      hasWarning, // eslint-disable-line
      render,
      thumbnailSource, // eslint-disable-line
      ...props
    } = this.props

    if (this.state.hasFailedToLoad) {
      const { style = {} } = props
      const { height, width } = style

      return (
        <TouchableOpacity
          onPress={this._onRetry}
          style={[
            containerStyle,
            style,
            {
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.colors.greyBackground,
              width,
              height,
            },
          ]}>
          <Icon icon={faRedo} color={theme.colors.darkerGrey} size="lg" />
        </TouchableOpacity>
      )
    }

    const style = {
      ...props.style,
      width: '100%',
      height: '100%',
    }

    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={this._onPressShow}
        style={[
          containerStyle,
          {
            position: 'relative',
            overflow: 'hidden',
            height: this.state.height,
          },
        ]}>
        {typeof render === 'function' ? (
          render()
        ) : (
          <>
            {this.state.hasWarning ? <Warning /> : null}
            <Image
              {...props}
              onError={this._onError}
              style={[style, this.state.hasWarning && { filter: 'blur(8px)' }]}
            />
          </>
        )}
      </TouchableOpacity>
    )
  }
}

import { headerModes } from '@tellonym/core/artificialTells/constants'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Dropdown } from 'antd'
import qs from 'qs'
import React, { useMemo } from 'react'
import * as ReactRedux from 'react-redux'
import { Box, TableEndlessScroll, _ } from '../../common'
import { editGroup } from '../actionsV2'
import { useDontLocalizeGroupMutation } from '../queries'
import { getGroupMode, getHeaderMode, getLanguage } from '../selectorsV2'
import { ModalMoveGroupToOtherTopic } from './ModalMoveGroupToOtherTopic'
import {
  SharedCells,
  SharedColumns,
  useColumnSearchProps,
} from './SharedColumns'

export const ArtificialTellTopicGroupsTable = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)
  const language = ReactRedux.useSelector(getLanguage)
  const searchProps = useColumnSearchProps('name')

  const { mutate: fetchDontLocalizeGroup } = useDontLocalizeGroupMutation()

  const columns = useMemo(() => {
    return (
      props.columns ??
      [
        {
          ...SharedColumns.id,
          ...SharedCells.statusCountsTitle(props.dataSource),
          sorter: { multiple: 1, compare: SharedColumns.id.sorter },
          /**
           * This allows to pass specific ids that should be filtered for
           * Requires onPressResetFilter to be passed as well
           */
          ...(props.isDataSourceFiltered
            ? {
                filtered: true,
                filters: [],
                filterDropdownOpen: false,
                onFilterDropdownOpenChange: (open) => {
                  props.onPressResetFilter?.(open)
                },
              }
            : {}),
        },
        SharedColumns.status,
        SharedColumns.groupDepth,
        SharedColumns.groupType,
        props.hasTopic && SharedColumns.groupTopicName,
        { ...SharedColumns.question, ...searchProps },
        { ...SharedColumns.groupTotalSent, defaultSortOrder: 'descend' },
        SharedColumns.answerRate,
        SharedColumns.p50Length,
        SharedColumns.shareRate,
        {
          ...SharedColumns.reaskInDays,
          onHeaderCell: () => ({
            onClick: () => {
              props.setTableState((tableState) => ({
                ...tableState,
                filters: {
                  ...tableState.filters,
                  reaskInDays: Array.isArray(tableState.filters.reaskInDays)
                    ? undefined
                    : [1],
                },
              }))
            },
          }),
        },
        props.hasTimeLimited && SharedColumns.timeLimited,
        SharedColumns.variances,
        SharedColumns.groupSenderHint,
        SharedColumns.lastUpdatedAt,
        {
          title: 'Actions',
          dataIndex: 'id',
          key: 'actions',
          width: 80,
          render: (id, group) => {
            const items =
              group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED
                ? [
                    {
                      key: 'dontLocalize',
                      text: "Don't localize",
                    },
                  ]
                : [
                    {
                      key: 'move',
                      label: 'Move group to other topic',
                      text: 'Move',
                    },
                    {
                      key: 'inactiveCurrentLanguage',
                      label: 'Set inactive for selected language',
                    },
                    {
                      key: 'inactiveAllLanguages',
                      label: 'Set inactive for all languages',
                    },
                    group.status === ARTIFICIAL_TELL_STATUS.INACTIVE && {
                      key: 'deleteAllLanguages',
                      label: 'Delete for all languages',
                    },
                  ].filter(Boolean)

            const onClick = ({ key, domEvent }) => {
              domEvent.stopPropagation()

              switch (key) {
                case 'move': {
                  ModalMoveGroupToOtherTopic.show({
                    topicId: props.topicId,
                    group,
                  })
                  break
                }

                case 'inactiveCurrentLanguage': {
                  dispatch(
                    editGroup({
                      id: group.id,
                      status: ARTIFICIAL_TELL_STATUS.INACTIVE,
                      languageForChangingStatus:
                        langDetectObjectsByType1[props.language],
                    })
                  )
                  break
                }

                case 'inactiveAllLanguages': {
                  dispatch(
                    editGroup({
                      id: group.id,
                      status: ARTIFICIAL_TELL_STATUS.INACTIVE,
                      languageForChangingStatus: 'ALL',
                    })
                  )

                  break
                }

                case 'deleteAllLanguages': {
                  if (group.status === ARTIFICIAL_TELL_STATUS.INACTIVE) {
                    dispatch(
                      editGroup({
                        id: group.id,
                        status: ARTIFICIAL_TELL_STATUS.DELETED,
                        languageForChangingStatus: 'ALL',
                      })
                    )
                  }
                  break
                }

                case 'dontLocalize': {
                  fetchDontLocalizeGroup({
                    id: group.id,
                    language: langDetectObjectsByType1[language],
                  })
                  break
                }

                default:
                  break
              }
            }

            return (
              <Box
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                transparent
                width={55}>
                <Button
                  onClick={(e) => onClick({ key: items[0].key, domEvent: e })}
                  size="small">
                  {items[0].text}
                </Button>

                {items.length > 1 && (
                  <Dropdown
                    menu={{ items, onClick }}
                    placement="bottomLeft"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}>
                    <Button size="small" style={{ letterSpacing: -0.5 }}>
                      ...
                    </Button>
                  </Dropdown>
                )}
              </Box>
            )
          },
        },
      ].filter(Boolean)
    )
  }, [dispatch, props, searchProps])

  const onRow = React.useCallback(
    (group) => {
      const queryString = qs.stringify({
        groupMode,
        headerMode: headerMode === headerModes.CONTENT ? groupMode : headerMode,
      })

      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${
          group.id
        }?${queryString}`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language, groupMode, headerMode]
  )

  return (
    <TableEndlessScroll
      {...props}
      hasSmallRows
      hasZebraRows
      rowKey="id"
      columns={columns}
      onRow={onRow}
    />
  )
}

import { intHash } from '@tellonym/core/helpers'
import { Tag } from 'antd'
import React from 'react'
import { antdTagColors } from '../styles/colors'

export const TagColored = ({ children }: { children: string }) => {
  return (
    <Tag color={antdTagColors[intHash(children) % antdTagColors.length]}>
      {children}
    </Tag>
  )
}

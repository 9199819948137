import eol from 'eol'
import { pipe } from 'ramda'
import blacklist from 'validator/lib/blacklist'
import escape from 'validator/lib/escape'
import _isLength from 'validator/lib/isLength'
import trim from 'validator/lib/trim'

const zalgoChars =
  '\u0624\u0652\u032B\u035B\u030d\u030e\u0304\u0305\u033f\u0311\u0306\u0310\u0352\u0357\u0351\u0307\u0308\u030a\u0342\u0343\u0344\u034a\u034b\u034c\u0303\u0302\u030c\u0350\u0300\u030b\u030f\u0312\u0313\u0314\u033d\u0309\u0363\u0364\u0365\u0366\u0367\u0368\u0369\u036a\u036b\u036c\u036d\u036e\u036f\u033e\u035b\u0346\u031a\u0315\u031b\u0340\u0341\u0358\u0321\u0322\u0327\u0328\u0334\u0335\u0336\u034f\u035c\u035d\u035e\u035f\u0360\u0362\u0338\u0337\u0361\u0489\u0316\u0317\u0318\u0319\u031c\u031d\u031e\u031f\u0320\u0324\u0325\u0326\u0329\u032a\u032b\u032c\u032d\u032e\u032f\u0330\u0331\u0332\u0333\u0339\u033a\u033b\u033c\u0345\u0347\u0348\u0349\u034d\u034e\u0353\u0354\u0355\u0356\u0359\u035a\u0323'

const removeZalgo = (str) => blacklist(str, [zalgoChars])

const byteLength = (str) => {
  let s = str.length
  for (let i = str.length - 1; i >= 0; i -= 1) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) {
      s += 1
    } else if (code > 0x7ff && code <= 0xffff) {
      s += 2
    }
    if (code >= 0xdc00 && code <= 0xdfff) {
      i -= 1
    }
  }
  return s
}

const isBufferLength = (min, max) => (str) => {
  const bufferLength = byteLength(str)
  return bufferLength >= min && bufferLength <= max
}

const isLength = (min, max) => (str) => _isLength(str, { min, max })

const limitEOLs = (str) =>
  eol
    .lf(str)
    .replace(/[\r\n]{3,}/gi, '<br><br><br>')
    .replace(/(?:\r\n|\r|\n)/g, '<br>')

const isBetween =
  (min, max, options = {}) =>
  (str = '') =>
    pipe(
      trim,
      escape,
      removeZalgo,
      options.limitEOLs ? limitEOLs : (str) => str,
      options.isBufferLength ? isBufferLength(min, max) : isLength(min, max)
    )(str)

export const aboutMe = isBetween(0, 150, {
  isBufferLength: true,
  limitEOLs: true,
})

export const answer = isBetween(1, 14999, {
  isBufferLength: true,
  limitEOLs: true,
})

export const communityAvatar =
  /^(?:\p{Emoji}(?:\p{Emoji_Modifier}|\uFE0F|\u200D\p{Emoji})*)$/u

export const communityDescription = isBetween(0, 350, {
  isBufferLength: true,
  limitEOLs: true,
})

export const communityId =
  /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))?)*[^\s]\1*$/

export const deviceName = isBetween(2, 100)

export const dmSharingComment = isBetween(4, 4999, {
  isBufferLength: true,
  limitEOLs: true,
})

export const displayName = isBetween(3, 30, {
  isBufferLength: true,
  limitEOLs: true,
})

export const email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emojis =
  /(?:[\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g

export const everythingExceptAlphanumAndWhitespace = /[^\w\säöüß]|_/g

export const isAlphaNum = (str) => /^[a-zA-Z0-9]*$/i.test(str)

export const isSocialUsername = (str) => /^[a-zA-Z0-9._-]*$/i.test(str)

export const snapchat = (username) =>
  username === '' || /^[a-zA-Z][0-9a-zA-Z\-_.]{1,13}[0-9a-zA-Z]$/.test(username) // source: https://www.wikidata.org/wiki/Property:P2984
export const instagram = (username) =>
  username === '' || /^[a-zA-Z0-9._]+$/.test(username) // source: https://stackoverflow.com/questions/32543090/instagram-username-regex-php#comment52943178_32543090
export const spotify = (username) =>
  username === '' || /^[a-zA-Z0-9._]+$/.test(username) // source: trying
export const youtube = (username) =>
  username === '' || /^[a-zA-Z0-9]+$|^channel\/[a-zA-Z0-9_-]+$/.test(username) // source: trying with https://regex101.com/r/nyUlcI/1
export const twitter = (username) =>
  username === '' || /^[a-zA-Z0-9_]{1,15}$/.test(username) // source: https://www.nutt.net/regex-twitter-username/
export const musically = (username) =>
  username === '' || /^[a-zA-Z0-9_.-]{1,25}$/.test(username) // source: idc

export const isChecked = /^true$/

export const password = isBetween(6, 300)

export const reason = isBetween(4, 1000)

export const report = isBetween(4, 350)

export const rtPost = isBetween(1, 14999, {
  isBufferLength: true,
  limitEOLs: true,
})

export const tell = isBetween(1, 14999, {
  isBufferLength: true,
  limitEOLs: true,
})

export const link =
  /^([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)?)$/

export const tagOrLinkInText =
  /(\s|^)(@(?:c:)?[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))?|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)?)(?=$|.*)/gm

export const username =
  /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))?)$/

export const usernameWithTag =
  /^(@(?:c:)?[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))?)$/

export const usernameWithTagInText =
  /(\s|^)(@(?:c:)?[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))?)(?=$|.*)/gm

export const usernameWithTagInTextLast =
  /(\s|^)(@[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,30}(?:[A-Za-z0-9_]))?)$/

export const endsWithAtSign = /(\s|^)(@$)/

export const endsWithAtSignNotCapturing = /((\n|\s|.)*|^)@$/

export const communityAdminsString = /^@/

export const isCommitOrRollback = (str) =>
  str.includes('_SUCCESS') || str.includes('_ERROR')

// Any -> Boolean
export const isError = (value) => value instanceof Error

// Any -> Boolean
export const isResponse = (obj) =>
  typeof obj === 'object' &&
  typeof obj.headers === 'object' &&
  typeof obj.url === 'string'

// Any -> Boolean
export const isObject = (value) =>
  typeof value === 'object' &&
  !Array.isArray(value) &&
  value !== null &&
  !isResponse(value)

/**
 * Checks whether the input is a function
 * @param {any} val
 * @returns {boolean}
 */
export const isFunction = (val) => typeof val === 'function'

/**
 * Checks whether the input is an array of functions
 * @param {any} val
 * @returns {boolean}
 */
export const isArrayOfFunctions = (val) =>
  Array.isArray(val) && val.every(isFunction)

import { getProfile } from '@tellonym/core/profile/selectors'
import { Dropdown, Switch, Tag } from 'antd'
import React, { useState } from 'react'
import * as ReactRedux from 'react-redux'
import { setIsDarkModeEnabled, toggleSidemenu } from '../../app/actions'
import {
  getCustomApiHost,
  getIsDarkModeEnabled,
  getPermissions,
} from '../../app/selectors'
import { Avatar } from '../../common/components/Avatar'
import { Box } from '../../common/components/Box'
import { Link } from '../../common/components/Link'
import { View } from '../../common/components/View'
import { checkPermission } from '../../common/helpers'
import * as hooks from '../../common/hooks'
import { styleSheets } from '../../common/styles/styleSheets'
import { theme } from '../../common/styles/theme'
import {
  apiHostsColorMap,
  apiHostsRouteMap,
  predefinedApiHosts,
} from '../constants'
import { DebugModal } from './DebugModal'

const headerHeight = 50
const height = headerHeight

const Bar = () => (
  <View
    style={{
      width: 20,
      height: 2,
      backgroundColor: theme.colors.primary,
      transition: '0.4s',
      margin: '2px 0',
    }}
  />
)

const BurgerMenu = ({ onPress }) => (
  <View
    style={{
      cursor: 'pointer',
    }}
    onPress={onPress}>
    <Bar />
    <Bar />
    <Bar />
  </View>
)

const CustomApiTag = () => {
  const customHost = ReactRedux.useSelector(getCustomApiHost)

  const customHostName = Object.values(predefinedApiHosts).find(
    (environment) => {
      return apiHostsRouteMap[environment] === customHost
    }
  )

  if (!customHost || !customHostName) {
    return null
  }

  return (
    <Tag
      color={apiHostsColorMap[customHostName]}
      style={styleSheets.margin.right[12]}>
      {customHostName}
    </Tag>
  )
}

export const Header = () => {
  const dispatch = ReactRedux.useDispatch()
  const profile = ReactRedux.useSelector(getProfile)
  const isDarkModeEnabled = ReactRedux.useSelector(getIsDarkModeEnabled)
  const permissions = ReactRedux.useSelector(getPermissions)
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false)

  const getThemedColor = hooks.useThemedColor()

  // show debug menu on all other domains than modcp.tellonym.me or only if permissions exist
  const shouldShowDebugMenu =
    !window.location.host.includes('modcp.tellonym.me') ||
    checkPermission('debugmenu', permissions)

  const menuItems = [
    {
      key: 'darkmode',
      label: (
        <span>
          {'Dark Mode (beta)'}
          <Switch checked={isDarkModeEnabled} style={{ marginLeft: 12 }} />
        </span>
      ),
    },
    shouldShowDebugMenu && {
      key: 'debugmodal',
      label: 'Debug Menu',
      onClick: () => setIsDebugModalOpen(true),
    },
  ].filter(Boolean)

  const onClickMenuItem = ({ key, domEvent }) => {
    domEvent.preventDefault()

    switch (key) {
      case 'darkmode':
        dispatch(setIsDarkModeEnabled(!isDarkModeEnabled))
        break
      case 'debugmodal':
        setIsDebugModalOpen(true)
        break
    }
  }

  return (
    <View
      style={{
        zIndex: 99,
        position: 'fixed',
        left: 0,
        right: 0,
        height: headerHeight,
        boxShadow: getThemedColor(
          theme.styles.shadow,
          '0px 0px 12px 0px rgba(0,0,0,0.35)'
        ),
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: getThemedColor(
          theme.colors.background,
          theme.colors.antdDarkModeBackground
        ),
        flexDirection: 'row',
        paddingHorizontal: 12,
      }}>
      <DebugModal
        open={isDebugModalOpen}
        onRequestClose={() => setIsDebugModalOpen(false)}
      />
      <BurgerMenu onPress={() => dispatch(toggleSidemenu())} />

      <Link
        to="/"
        style={{
          justifyContent: 'center',
        }}>
        <img
          src="/logo.png"
          alt="logo"
          style={{
            height: 25,
            margin: 0,
            width: 126,
          }}
        />
      </Link>

      <Box flexDirection="row" alignItems="center">
        <CustomApiTag />

        <Dropdown menu={{ items: menuItems, onClick: onClickMenuItem }}>
          <Box>
            <Avatar size={30} user={profile} />
          </Box>
        </Dropdown>
      </Box>
    </View>
  )
}

Header.height = height

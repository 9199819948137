import { DeleteOutlined } from '@ant-design/icons'
import { Button, Divider, Input, List, message, Modal, Typography } from 'antd'
import React from 'react'
import { AntdModal, Box, styleSheets, TagColored } from '../../common'
import {
  useAddTopicTagMutation,
  useRemoveTopicTagMutation,
  useTopicTagsQuery,
} from '../queries'

const ModalTopicTagsComponent = () => {
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')

  const { data } = useTopicTagsQuery()

  const { mutate: addTag } = useAddTopicTagMutation()
  const { mutate: removeTag } = useRemoveTopicTagMutation()

  const onPressSave = () => {
    const cleanedName = name.trim()
    if (data?.tags?.find((tag) => tag.tag === cleanedName)) {
      message.error('Tag already exists')
      return
    }

    addTag({
      tag: cleanedName,
      description,
      onSuccess: () => {
        setName('')
        setDescription('')
      },
    })
  }

  const onPressRemoveTag = (tag) => {
    Modal.confirm({
      title: 'Remove Tag',
      content: `Are you sure you want to remove the tag "${tag}"?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        removeTag({ tag })
      },
    })
  }

  return (
    <Box transparent>
      <Typography.Title level={3}>Edit Topic Tags</Typography.Title>

      <Typography.Title level={5} style={styleSheets.margin.top[24]}>
        Add new tag
      </Typography.Title>

      <Box transparent maxWidth={460}>
        <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
          Name
        </Typography.Text>
        <Input
          autoFocus
          onChange={(e) => {
            setName(e.target.value)
          }}
          value={name}
          style={styleSheets.margin.bottom[12]}
        />

        <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
          Description
        </Typography.Text>
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
          value={description}
          style={styleSheets.margin.bottom[12]}
        />

        <Box transparent alignItems="flex-start">
          <Button
            type="primary"
            style={styleSheets.margin.top[12]}
            onClick={onPressSave}>
            Save
          </Button>
        </Box>
      </Box>

      <Divider />

      <Typography.Title level={5}>Edit tags</Typography.Title>
      <List
        size="small"
        bordered
        dataSource={data?.tags}
        renderItem={({ tag, description }) => (
          <List.Item
            actions={[
              <Button
                danger
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => onPressRemoveTag(tag)}
              />,
            ]}>
            <Box transparent width={160} alignItems="flex-end" marginRight={8}>
              <TagColored>{tag}</TagColored>
            </Box>
            <Typography.Text
              editable={{
                onChange: (text) => {
                  addTag({ tag, description: text }) // addTag updates the tag if it is existing
                },
              }}
              type="secondary"
              style={{ fontSize: 12, flex: 1 }}>
              {description}
            </Typography.Text>
          </List.Item>
        )}
      />
    </Box>
  )
}

const show = () =>
  AntdModal.show({
    id: 'ModalTopicTags',
    render: (props) => <ModalTopicTagsComponent {...props} />,
    footer: null,
    width: '65%',
  })

export const ModalTopicTags = { show }

import {
  DeleteOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  HolderOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { Column } from '@ant-design/plots'
import { colors } from '@tellonym/core/common/colorSystem'
import { formatNumber, uuidv4 } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import equal from 'fast-deep-equal'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, hooks, styleSheets } from '../../common'
import {
  useAnalyticsEventNamesQuery,
  useAnalyticsEventPropsQueries,
} from '../../tools/queries'
import { useChFunnelMutation } from '../queries'
import { valueToPercentageWithSign } from '../services'
import { QueryBuilder } from './PageClickhouseEvents'

const quickSelectionRanges = {
  Yesterday: [
    dayjs().subtract(1, 'day').startOf('day'),
    dayjs().subtract(1, 'day').endOf('day'),
  ],
  'Last 7 Days': [
    dayjs().subtract(7, 'day').startOf('day'),
    dayjs().endOf('day'),
  ],
  'Last 30 Days': [
    dayjs().subtract(30, 'day').startOf('day'),
    dayjs().endOf('day'),
  ],
  'Last 90 Days': [
    dayjs().subtract(90, 'day').startOf('day'),
    dayjs().endOf('day'),
  ],
}

const InputWithLabel = ({ label, value, onChange, style, placeholder }) => {
  return (
    <Box>
      <Typography.Title level={5}>{label}</Typography.Title>

      <Input
        value={value}
        onChange={onChange}
        style={style}
        placeholder={placeholder}
      />
    </Box>
  )
}

const arePropsEqualColumnChart = (prev, next) => {
  const isDataEqual = equal(prev.data, next.data)
  const isHiddenEqual = prev.hiddenEvents.length === next.hiddenEvents.length

  return isDataEqual && isHiddenEqual
}

const ColumnChart = React.memo(
  ({ data, hiddenEvents, toggleEventIsHidden }) => {
    /**
     * Process funnel data by:
     * 1. Calculate cumulative amounts for each step
     * 2. Filter out hidden events
     * 3. Add sequential index for x-axis positioning
     */
    const mappedData = data?.reduce((acc, event, index, array) => {
      if (hiddenEvents.includes(event.eventName)) {
        return acc
      }

      const cumulativeAmount = array
        .slice(index)
        .reduce((sum, item) => sum + item.amount, 0)

      return [
        ...acc,
        {
          step: event.step,
          eventName: event.eventName,
          amount: cumulativeAmount,
          index: acc.length,
        },
      ]
    }, [])

    const config = {
      data: mappedData,
      xField: 'index',
      yField: 'amount',
      conversionTag: {
        text: {
          formatter: (prevAmount, nextAmount) => {
            return valueToPercentageWithSign(nextAmount / prevAmount - 1)
          },
        },
      },
      label: {
        position: 'middle',
        formatter: (entry) => {
          return formatNumber(entry.amount)
        },
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
          formatter: (index) => {
            const item = mappedData.find((d) => d.index === Number(index))
            return item?.eventName.replace('app_', '') ?? index
          },
        },
      },
      meta: {
        amount: {
          alias: 'Amount',
        },
      },
      interactions: [{ type: 'element-active' }],
      onReady: (plot) => {
        plot.on('element:click', (evt) => {
          const { data: clickedData } = evt.data || {}
          if (clickedData?.step !== undefined) {
            toggleEventIsHidden(clickedData.eventName)
          }
        })
      },
    }

    if (typeof mappedData === 'undefined') {
      return null
    }

    return (
      <Box marginRight={48} marginTop={48}>
        <Column {...config} />
      </Box>
    )
  },
  arePropsEqualColumnChart
)

const defaultQueryParams = {
  country: '',
  dates: [
    quickSelectionRanges.Yesterday[0].format(),
    quickSelectionRanges.Yesterday[1].format(),
  ],
  secondsBetweenEvents: 10,
  mode: 'device_id',
  funnelEvents: [{ id: uuidv4(), name: '' }],
}

export const PageChFunnel = () => {
  const containerStyle = hooks.usePageContainerStyle()

  const dispatch = ReactRedux.useDispatch()

  const inputRefs = React.useRef([])

  const [queryParams, setQueryParams] = hooks.useQueryParams(defaultQueryParams)

  const [country, setCountry] = React.useState(queryParams.country)
  const [dates, setDates] = React.useState([
    dayjs(queryParams.dates[0]),
    dayjs(queryParams.dates[1]),
  ])
  const [secondsBetweenEvents, setSecondsBetweenEvents] = React.useState(
    Number(queryParams.secondsBetweenEvents)
  )
  const [mode, setMode] = React.useState(queryParams.mode)
  const [funnelEvents, setFunnelEvents] = React.useState(
    queryParams.funnelEvents.map((e) => ({
      ...e,
      isRestrictedToFirstOpen: e.isRestrictedToFirstOpen === 'true',
      isHidden: e.isHidden === 'true',
    }))
  )

  const [expandedEventIds, setExpandedEventIds] = React.useState([])

  const { isLoading: isLoadingTables, data: availableEventNames } =
    useAnalyticsEventNamesQuery()

  const finishedTableNames = funnelEvents
    .map((e) => e.name)
    .filter(
      (name) =>
        (availableEventNames ?? []).findIndex((n) => n.value === name) > -1
    )

  const tablePropsDataMap = useAnalyticsEventPropsQueries({
    tableNames: finishedTableNames,
  })

  const modeMismatchEvents = funnelEvents.reduce((acc, e) => {
    const tablePropsData = tablePropsDataMap[e.name]

    if (
      typeof tablePropsData === 'undefined' ||
      tablePropsData?.properties?.some((p) => p.name === mode)
    ) {
      return acc
    }

    acc.push(e.name)

    return acc
  }, [])

  const hasModeMismatch = modeMismatchEvents.length > 0

  const hiddenEvents = React.useMemo(() => {
    return funnelEvents.reduce((acc, e) => {
      if (e.isHidden) {
        acc.push(e.name)
      }

      return acc
    }, [])
  }, [funnelEvents])

  const {
    mutate: fetchChFunnel,
    isLoading: isLoadingFunnel,
    data,
    isError,
  } = useChFunnelMutation()

  const onPressQuickTimeCreator = (range) => () => {
    setDates(quickSelectionRanges[range])
  }

  const onChangeCountry = (e) => {
    setCountry(e.target.value)
  }

  const onChangeSecondsBetweenEvents = (value) => {
    setSecondsBetweenEvents(value)
  }

  const onChangeEvent = (event, value) => {
    setFunnelEvents((f) =>
      f.map((e) => (e.id === event.id ? { ...e, name: value } : e))
    )
  }

  const onChangeIsRestrictedToFirstOpen = (event) => (domEvent) => {
    setFunnelEvents((f) =>
      f.map((e) =>
        e.id === event.id
          ? { ...e, isRestrictedToFirstOpen: domEvent.target.checked }
          : e
      )
    )
  }

  const onChangeEventFilters =
    ({ id }) =>
    (filters) => {
      setFunnelEvents((events) =>
        events.map((event) =>
          event.id === id
            ? {
                ...event,
                /**
                 * When removing a row the QueryBuilder filters and uses the newest entries
                 * like in a setState(s => s.filter(...)) way, so we need to call it as a function
                 */
                filters:
                  typeof filters === 'function'
                    ? filters(event.filters)
                    : filters,
              }
            : event
        )
      )
    }

  const toggleEventIsHidden = React.useCallback(
    (eventName) => {
      setFunnelEvents((f) =>
        f.map((e) =>
          e.name === eventName ? { ...e, isHidden: !e.isHidden } : e
        )
      )
    },
    [setFunnelEvents]
  )

  const onPressFetch = () => {
    fetchChFunnel({
      country,
      dateFrom: dates[0],
      dateTo: dates[1],
      mode,
      secondsBetweenEvents,
      events: funnelEvents.map((e) => ({
        name: e.name,
        filters: e.filters?.filter((f) => f.length > 0),
        isRestrictedToFirstOpen: e.isRestrictedToFirstOpen,
      })),
    })
  }

  const onKeyDown = (event, currentEvent) => {
    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault()
      const newEvent = { id: uuidv4(), name: '' }
      setFunnelEvents([...funnelEvents, newEvent])
    }

    if (event.key === 'Enter' && event.metaKey) {
      event.preventDefault()
      onPressFetch()
    }
  }

  const onDragStart = (e, index) => {
    e.dataTransfer.setData('index', index)
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDrop = (e, dropIndex) => {
    const dragIndex = parseInt(e.dataTransfer.getData('index'))
    if (dragIndex === dropIndex) return

    const newEvents = [...funnelEvents]
    const [removed] = newEvents.splice(dragIndex, 1)
    newEvents.splice(dropIndex, 0, removed)
    setFunnelEvents(newEvents)
  }

  React.useEffect(() => {
    setQueryParams({
      funnelEvents: funnelEvents.map((e) => ({
        ...e,
        isHidden: Boolean(e.isHidden),
      })),
      country,
      dates: [dates[0].format(), dates[1].format()],
      secondsBetweenEvents,
      mode,
    })
  }, [funnelEvents, country, dates, secondsBetweenEvents, mode, setQueryParams])

  React.useEffect(() => {
    if (inputRefs.current[funnelEvents.length - 1]) {
      inputRefs.current[funnelEvents.length - 1].focus()
    }
  }, [funnelEvents.length])

  return (
    <Box style={containerStyle}>
      <Box padding={32}>
        <Typography.Title level={3}>Clickhouse Funnel</Typography.Title>
        <Box flexDirection="row">
          <Box flex={2}>
            <ColumnChart
              data={data?.data}
              hiddenEvents={hiddenEvents}
              toggleEventIsHidden={toggleEventIsHidden}
            />
          </Box>
          <Box flex={1}>
            <Box>
              <Typography.Title level={5}>Timeframe</Typography.Title>
              <DatePicker.RangePicker
                value={dates}
                onChange={(newDates) => {
                  setDates(newDates)
                }}
                style={{ maxWidth: 380 }}
              />

              <Box
                transparent
                flexDirection="row"
                alignItems="center"
                marginTop={12}>
                {Object.keys(quickSelectionRanges).map((range) => (
                  <Tag
                    key={range}
                    color="blue"
                    onClick={onPressQuickTimeCreator(range)}
                    style={styleSheets.pointer}>
                    {range}
                  </Tag>
                ))}
              </Box>
            </Box>

            <Box marginTop={24} flexDirection="row">
              <Box>
                <Tooltip title="Using device_id or user_id to join the events. It needs to be the same for all events in the funnel.">
                  <Typography.Title level={5}>Mode</Typography.Title>
                </Tooltip>

                <Radio.Group
                  options={['device_id', 'user_id']}
                  optionType="button"
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                />
              </Box>

              <Box marginLeft={24}>
                <InputWithLabel
                  label="Country"
                  placeholder="optional"
                  value={country}
                  onChange={onChangeCountry}
                  style={{ width: 100 }}
                />
              </Box>

              <Box marginLeft={24}>
                <Tooltip title="The number of seconds between events">
                  <Typography.Title level={5}>Cadence</Typography.Title>
                </Tooltip>

                <InputNumber
                  value={secondsBetweenEvents}
                  onChange={onChangeSecondsBetweenEvents}
                  style={{ width: 100 }}
                />
              </Box>
            </Box>

            <Box alignItems="flex-start" marginTop={24}>
              <Button
                type="primary"
                disabled={
                  funnelEvents.length === 1 && funnelEvents[0].name === ''
                }
                loading={isLoadingFunnel}
                onClick={onPressFetch}>
                Run Query
              </Button>
            </Box>

            <Box marginTop={24} alignItems="flex-start">
              <Typography.Title level={5}>Funnel</Typography.Title>

              <Timeline>
                {funnelEvents.map((event, index) => {
                  // disable if event does not have device_id as a column or mode is not user_id
                  const isRestrictToFirstOpenDisabled =
                    tablePropsDataMap[event.name]?.properties?.some(
                      (p) => p.name === 'device_id'
                    ) === false || mode !== 'user_id'

                  return (
                    <Timeline.Item key={event.id}>
                      <Box
                        draggable
                        onDragStart={(e) => onDragStart(e, index)}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, index)}>
                        <Box flexDirection="row" alignItems="center">
                          <HolderOutlined
                            style={{
                              cursor: 'grab',
                              marginRight: 8,
                              color: '#999',
                            }}
                          />
                          <AutoComplete
                            ref={(el) => {
                              inputRefs.current[index] = el
                            }}
                            placeholder="Select event..."
                            options={availableEventNames}
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onChange={(v) => {
                              onChangeEvent(event, v)
                            }}
                            onKeyDown={(e) => onKeyDown(e, event)}
                            value={event.name}
                            style={{ width: 360 }}
                          />

                          <Box
                            marginLeft={24}
                            backgroundColor={
                              event.filters?.filter((f) => f[0]?.length > 0)
                                .length > 0 || event.isRestrictedToFirstOpen
                                ? colors.yellow[1]
                                : 'transparent'
                            }
                            borderRadius={8}
                            paddingHorizontal={8}
                            paddingVertical={8}>
                            {expandedEventIds.includes(event.id) ? (
                              <UpOutlined
                                onClick={() => {
                                  setExpandedEventIds((prev) =>
                                    prev.filter((id) => id !== event.id)
                                  )
                                }}
                              />
                            ) : (
                              <DownOutlined
                                onClick={() => {
                                  setExpandedEventIds((prev) => [
                                    ...prev,
                                    event.id,
                                  ])
                                }}
                              />
                            )}
                          </Box>

                          <Box
                            onPress={() => {
                              toggleEventIsHidden(event.name)
                            }}
                            marginLeft={12}>
                            {event.isHidden ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined style={{ color: colors.grey[5] }} />
                            )}
                          </Box>

                          <Button
                            danger
                            onClick={(e) => {
                              e.stopPropagation()
                              setFunnelEvents(
                                funnelEvents.filter((e) => e.id !== event.id)
                              )
                            }}
                            style={{
                              marginLeft: 24,
                              paddingLeft: 8,
                              paddingRight: 8,
                            }}>
                            <DeleteOutlined />
                          </Button>
                        </Box>

                        {expandedEventIds.includes(event.id) && (
                          <Box marginTop={16} paddingHorizontal={24}>
                            <Tooltip
                              placement="topLeft"
                              title={`${
                                isRestrictToFirstOpenDisabled
                                  ? 'Mode needs to be user_id and event needs to have device_id as a column.\n\n'
                                  : ''
                              }Use this if you want only users that open the app for the first time and your funnel events are user_id based. This event will be joined with app_first_open on device_id and only returns the matching events with INNER JOIN.`}>
                              <Checkbox
                                disabled={isRestrictToFirstOpenDisabled}
                                onChange={onChangeIsRestrictedToFirstOpen(
                                  event
                                )}
                                checked={event.isRestrictedToFirstOpen}>
                                Restrict to first open event
                              </Checkbox>
                            </Tooltip>

                            <Box marginTop={8}>
                              <QueryBuilder
                                entries={event.filters ?? [[]]}
                                isDisabled={event.name === ''}
                                onChange={onChangeEventFilters(event)}
                                tablePropsData={tablePropsDataMap[event.name]}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Timeline.Item>
                  )
                })}
              </Timeline>

              <Button
                type="dashed"
                onClick={() => {
                  setFunnelEvents([...funnelEvents, { id: uuidv4(), name: '' }])
                }}>
                Add Event
              </Button>

              {hasModeMismatch && (
                <Alert
                  message={`The following events do not have the configured property set via 'Mode': ${modeMismatchEvents.join(
                    ', '
                  )}`}
                  type="warning"
                  showIcon
                  style={{ marginTop: 24 }}
                />
              )}

              {isError === false && typeof data?.query === 'string' && (
                <Box marginTop={36} marginBottom={12} gap={6}>
                  <pre
                    style={{
                      fontSize: 10,
                      backgroundColor: 'rgba(0,0,0,0.025)',
                      padding: 8,
                      borderRadius: 12,
                      overflow: 'scroll',
                      whiteSpace: 'break-spaces',
                      wordBreak: 'break-word',
                    }}>
                    {data?.query.trim()}
                  </pre>
                  <Button
                    onClick={() => {
                      dispatch(copyToClipboard(data?.query.trim()))
                    }}>
                    Copy Query
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import { Input, Modal, Tag } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import { setCustomApiHost } from '../../app/actions'
import { getCustomApiHost } from '../../app/selectors'
import { Box } from '../../common/components/Box'
import { Text } from '../../common/components/Text'
import { styleSheets } from '../../common/styles/styleSheets'
import {
  apiHostsColorMap,
  apiHostsRouteMap,
  predefinedApiHosts,
} from '../constants'

const rowMap = [
  [predefinedApiHosts.PRODUCTION],
  [
    predefinedApiHosts.STAGING,
    predefinedApiHosts.STAGING_GREEN,
    predefinedApiHosts.STAGING_PURPLE,
  ],
  [
    predefinedApiHosts.TESTING,
    predefinedApiHosts.TESTING_RED,
    predefinedApiHosts.TESTING_BLUE,
  ],
  [predefinedApiHosts.LOCAL],
]

export const DebugModal = ({ open, onRequestClose }) => {
  const dispatch = ReactRedux.useDispatch()
  const customHost = ReactRedux.useSelector(getCustomApiHost)

  const [apiHost, setApiHost] = React.useState(
    typeof customHost === 'string' ? customHost : config.api.host
  )

  const changeApiHost = (host) => {
    setApiHost(host)
  }

  const onOk = () => {
    dispatch(setCustomApiHost({ customApiHost: apiHost }))
    onRequestClose()
  }

  return (
    <Modal
      open={open}
      title="Debug Modal"
      okText="Save"
      width={600}
      onOk={onOk}
      onCancel={onRequestClose}>
      <Box flex={1} marginBottom={12}>
        <Input
          label="Api Host"
          value={apiHost}
          onChange={(e) => changeApiHost(e.target.value)}
          style={{ marginTop: 24 }}
        />
        <Text bold style={{ marginTop: 24, marginBottom: 12 }}>
          Presets
        </Text>
        {rowMap.map((row) => (
          <Box
            key={row.join('-')}
            flexDirection="row"
            flexWrap="wrap"
            marginBottom={12}>
            {row.map((environment) => (
              <Tag
                key={environment}
                color={apiHostsColorMap[environment]}
                onClick={() => {
                  changeApiHost(apiHostsRouteMap[environment])
                }}
                style={styleSheets.pointer}>
                {environment}
              </Tag>
            ))}
          </Box>
        ))}
      </Box>
    </Modal>
  )
}

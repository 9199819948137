import { ARTIFICIAL_TELL_USER_GROUP_TYPE } from '@tellonym/enums/lib/Tell'
import { PERFORMANCE_TYPE } from '@tellonym/enums/lib/TellArtificial'

import { capitalize } from '@tellonym/core/helpers'
import { Segmented, Select, Spin, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, BoxSecondary, hooks, TagColored, theme } from '../../common'
import { useActivityGroupsQuery } from '../queries'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellTopicGroupsTable } from './ArtificialTellTopicGroupsTable'
import { SelectorsLanguage } from './SelectorsLanguage'
import {
  SharedCells,
  SharedColumns,
  useColumnSearchProps,
} from './SharedColumns'

/**
 * This is manually measured (with chrome debug element inspection) and necessary to prevent the page from
 * being scrollable. We don't want that because the table is already scrollable.
 */
const HEADER_HEIGHT = 96

const performanceTypeOptions = [
  PERFORMANCE_TYPE.MONITOR,
  PERFORMANCE_TYPE.OPPORTUNITY,
].map((value) => ({
  value,
  label: capitalize(
    PERFORMANCE_TYPE[value].replace('CHANGELOG_', '').toLocaleLowerCase()
  ),
}))

const Header = ({
  dataMode,
  onChangeDataMode,
  performanceType,
  onChangePerformanceType,
}) => {
  return (
    <BoxSecondary padding={24} width="100%" flexDirection="row">
      <Box flex={1} transparent alignItems="flex-start">
        <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
          Performance Filter
        </Typography.Text>
        <Select
          options={performanceTypeOptions}
          onChange={onChangePerformanceType}
          value={performanceType}
          dropdownMatchSelectWidth={false}
          style={{ width: 160 }}
        />
      </Box>
      <BoxSecondary
        flex={1}
        justifyContent="center"
        alignItems="center"
        paddingHorizontal={48}>
        <Box transparent alignItems="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            Data mode:
          </Typography.Title>

          <Segmented
            value={dataMode}
            options={[
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[2],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
              },
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[0],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW,
              },
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[3],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL,
              },
              {
                label: ARTIFICIAL_TELL_USER_GROUP_TYPE[4],
                value: ARTIFICIAL_TELL_USER_GROUP_TYPE.YESTERDAY,
              },
            ]}
            onChange={onChangeDataMode}
            size="small"
          />
        </Box>
      </BoxSecondary>
      <Box flex={1} alignItems="flex-end" transparent>
        <SelectorsLanguage />
      </Box>
    </BoxSecondary>
  )
}

export const PageArtificialTellsActivityGroups = () => {
  const language = ReactRedux.useSelector(getLanguage)
  const getThemedColor = hooks.useThemedColor()

  const table = hooks.useTableState()

  const [dataMode, setDataMode] = React.useState(
    ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH
  )
  const [performanceType, setPerformanceType] = React.useState(
    PERFORMANCE_TYPE.MONITOR
  )

  const { data, isLoading } = useActivityGroupsQuery({
    language,
    dataMode,
    performanceType,
  })

  const searchProps = useColumnSearchProps('name')

  const columns = React.useMemo(() => {
    return [
      {
        ...SharedColumns.id,
        ...SharedCells.statusCountsTitle(data?.groups),
        sorter: { multiple: 1, compare: SharedColumns.id.sorter },
      },
      { ...SharedColumns.status, defaultSortOrder: null },
      { ...SharedColumns.groupDepth, defaultSortOrder: null },
      { ...SharedColumns.groupType, defaultSortOrder: null },
      SharedColumns.groupTopicName,
      { ...SharedColumns.question, ...searchProps },
      SharedColumns.groupTotalSent,
      { ...SharedColumns.answerRate, defaultSortOrder: 'ascend' },
      SharedColumns.p50Length,
      SharedColumns.shareRate,
      {
        ...SharedColumns.reaskInDays,
        onHeaderCell: () => ({
          onClick: () => {
            table.setTableState((tableState) => ({
              ...tableState,
              filters: {
                ...tableState.filters,
                reaskInDays: Array.isArray(tableState.filters.reaskInDays)
                  ? undefined
                  : [1],
              },
            }))
          },
        }),
      },
      SharedColumns.timeLimited,
      SharedColumns.variances,
      {
        title: 'Perf. type',
        dataIndex: ['latestPerformanceCheck', 'performanceType'],
        key: 'performanceType',
        width: 80,
        render: (performanceType) => {
          return (
            <TagColored>
              {capitalize(
                PERFORMANCE_TYPE[performanceType]?.toLocaleLowerCase()
              )}
            </TagColored>
          )
        },
      },
      {
        title: 'Performance note',
        dataIndex: ['latestPerformanceCheck', 'content'],
        key: 'performanceContent',
        width: 220,
        render: (content) => {
          return (
            <Typography.Text style={{ fontSize: 10 }}>
              {content}
            </Typography.Text>
          )
        },
      },
      {
        title: 'Commented',
        dataIndex: ['latestPerformanceCheck', 'createdAt'],
        key: 'performanceCommentCreatedAt',
        width: 110,
        render: (createdAt) => {
          const date = dayjs(createdAt).format('DD.MM.YY')

          return (
            <Box transparent alignItems="center">
              <Typography.Text>{date}</Typography.Text>
            </Box>
          )
        },
        sorter: (a, b) => {
          const timeA = a.latestPerformanceCheck?.createdAt
          const timeB = b.latestPerformanceCheck?.createdAt

          if (timeA && timeB) {
            return dayjs(timeA).diff(dayjs(timeB))
          }

          if (!timeA && !timeB) {
            return 0
          }

          if (timeA) {
            return 1
          }

          return -1
        },
      },
    ].filter(Boolean)
  }, [data?.groups, searchProps, table])

  return (
    <Spin
      spinning={isLoading}
      style={{
        backgroundColor: getThemedColor(
          theme.colors.background,
          theme.colors.antdDarkModeBackground
        ),
      }}>
      <Box>
        <Header
          dataMode={dataMode}
          onChangeDataMode={setDataMode}
          language={language}
          performanceType={performanceType}
          onChangePerformanceType={setPerformanceType}
        />
        <ArtificialTellTopicGroupsTable
          {...table}
          columns={columns}
          dataSource={data?.groups}
          language={language}
          offset={HEADER_HEIGHT}
        />
      </Box>
    </Spin>
  )
}

import { getMessageForBanReason } from '@tellonym/core/reporting/constants'
import { COMMENT_STATUS } from '@tellonym/enums/lib/Comments'
import { REPORT_DECISION } from '@tellonym/enums/lib/Report'
import { BAN_REASON } from '@tellonym/enums/lib/User'
import dayjs from 'dayjs'
import { append } from 'ramda'
import * as t from './types'

export const changeSettings = ({ id, ...payload }) => ({
  type: t.CHANGE_SETTINGS,
  payload,
  meta: {
    offline: {
      effect: { path: `admin/user/settings/${id}`, method: 'POST' },
      commit: { type: t.CHANGE_SETTINGS_SUCCESS, meta: { userId: id } },
      rollback: { type: t.CHANGE_SETTINGS_ERROR },
    },
  },
})

export const clearSelection = (payload) => ({
  type: t.CLEAR_SELECTION,
  payload,
})

/**
 * Action to copy tell, answer or sentTell information with user and device info
 * 'type' is a required prop in the payload; either set 'items' or 'userId' - the latter will use
 * items from state according to 'type'
 * @param {{ items: Array<any>, type: string }} payload
 * @returns action
 */
export const copyItems = (payload) => ({
  type: t.COPY_ITEMS,
  payload,
})

export const createBan = (payload) => ({
  type: t.CREATE_BAN,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/createban', method: 'POST' },
      commit: { type: t.CREATE_BAN_SUCCESS, meta: payload },
      rollback: { type: t.CREATE_BAN_ERROR },
    },
  },
})

export const createWarning = (payload) => ({
  type: t.CREATE_WARNING,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/warning/create', method: 'POST' },
      commit: { type: t.CREATE_WARNING_SUCCESS, meta: payload },
      rollback: { type: t.CREATE_WARNING_ERROR },
    },
  },
})

export const blockAllSentTells = (payload) => ({
  type: t.BLOCK_ALL_SENT_TELLS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/tells/${payload.userId}/sent/deleteall`,
        method: 'POST',
      },
      commit: { type: t.BLOCK_ALL_SENT_TELLS_SUCCESS },
      rollback: { type: t.BLOCK_ALL_SENT_TELLS_ERROR },
    },
  },
})

export const blockAnswer = (payload) => ({
  type: t.BLOCK_ANSWER,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/posts/delete/${payload.id}`,
        method: 'POST',
      },
      commit: { type: t.BLOCK_ANSWER_SUCCESS, meta: payload },
      rollback: { type: t.BLOCK_ANSWER_ERROR },
    },
  },
})

export const blockComment = (payload) => ({
  type: t.BLOCK_COMMENT,
  payload: {
    ...payload,
    status: COMMENT_STATUS.BLOCKED,
  },
  meta: {
    offline: {
      effect: { path: 'admin/comment/remove', method: 'POST', ...payload },
      commit: { type: t.BLOCK_COMMENT_SUCCESS, meta: { payload } },
      rollback: { type: t.BLOCK_COMMENT_ERROR, meta: { payload } },
    },
  },
})

export const deleteProfilePic = (payload) => ({
  type: t.DELETE_PROFILE_PIC,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/deletepb', method: 'POST' },
      commit: { type: t.DELETE_PROFILE_PIC_SUCCESS, meta: payload },
      rollback: { type: t.DELETE_PROFILE_PIC_ERROR },
    },
  },
})

export const deletePublicLink = (payload) => ({
  type: t.DELETE_PUBLIC_LINK,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/deletelink', method: 'POST' },
      commit: { type: t.DELETE_PUBLIC_LINK_SUCCESS, meta: payload },
      rollback: { type: t.DELETE_PUBLIC_LINK_ERROR },
    },
  },
})

export const blockPost = (payload) => ({
  type: t.BLOCK_POST,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/posts/delete/${payload.id}`,
        method: 'POST',
      },
      commit: { type: t.BLOCK_POST_SUCCESS, meta: payload },
      rollback: { type: t.BLOCK_POST_ERROR },
    },
  },
})

export const blockTell = (payload) => ({
  type: t.BLOCK_TELL,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/tells/delete/${payload.id}`,
        method: 'POST',
      },
      commit: { type: t.BLOCK_TELL_SUCCESS, meta: payload },
      rollback: { type: t.BLOCK_TELL_ERROR },
    },
  },
})

export const deleteUser = (payload) => ({
  type: t.DELETE_USER,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/deleteuser', method: 'POST' },
      commit: { type: t.DELETE_USER_SUCCESS, meta: payload },
      rollback: { type: t.DELETE_USER_ERROR },
    },
  },
})

export const generateGdprExport = (payload) => ({
  type: t.GENERATE_GDPR_EXPORT,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/exportdata', method: 'POST' },
      commit: { type: t.GENERATE_GDPR_EXPORT_SUCCESS },
      rollback: { type: t.GENERATE_GDPR_EXPORT_ERROR },
    },
  },
})

export const logoutAllDevices = (payload) => ({
  type: t.LOGOUT_ALL_DEVICES,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/devices/${payload.userId}/logout`,
        method: 'POST',
      },
      commit: { type: t.LOGOUT_ALL_DEVICES_SUCCESS, meta: payload },
      rollback: { type: t.LOGOUT_ALL_DEVICES_ERROR },
    },
  },
})

export const logoutDevice = (payload) => ({
  type: t.LOGOUT_DEVICE,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/devices/${payload.userId}/logout/${payload.id}`,
        method: 'POST',
      },
      commit: { type: t.LOGOUT_DEVICE_SUCCESS, meta: payload },
      rollback: { type: t.LOGOUT_DEVICE_ERROR },
    },
  },
})

export const refreshUsersSharingDevices = (payload) => ({
  type: t.REFRESH_USERS_SHARING_DEVICES,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/user/shared-devices/users',
        limit: 100,
        ...payload,
      },
      commit: { type: t.REFRESH_USERS_SHARING_DEVICES_SUCCESS, meta: { payload } }, // prettier-ignore
      rollback: { type: t.REFRESH_USERS_SHARING_DEVICES_ERROR },
    },
  },
})

export const hideBanCandidate = (payload) => ({
  type: t.HIDE_BAN_CANDIDATE,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/users/bancandidates/${payload.userId}`,
        method: 'DELETE',
      },
      commit: { type: t.HIDE_BAN_CANDIDATE_SUCCESS },
      rollback: { type: t.HIDE_BAN_CANDIDATE_ERROR },
    },
  },
})

export const restoreAnswer = (payload) => ({
  type: t.RESTORE_ANSWER,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/posts/unblock/${payload.id}`,
        method: 'POST',
      },
      commit: { type: t.RESTORE_ANSWER_SUCCESS, meta: payload },
      rollback: { type: t.RESTORE_ANSWER_ERROR },
    },
  },
})

export const restoreComment = (payload) => ({
  type: t.RESTORE_COMMENT,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/comment/restore', method: 'POST' },
      commit: { type: t.RESTORE_COMMENT_SUCCESS, meta: { payload } },
      rollback: { type: t.RESTORE_COMMENT_ERROR, meta: { payload } },
    },
  },
})

export const restoreIap = (payload) => ({
  type: t.RESTORE_IAP,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/iap/${payload.userId}/restore`,
        method: 'POST',
      },
      commit: { type: t.RESTORE_IAP_SUCCESS, meta: payload },
      rollback: { type: t.RESTORE_IAP_ERROR },
    },
  },
})

export const restoreTell = (payload) => ({
  type: t.RESTORE_TELL,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/tells/unblock/${payload.id}`,
        method: 'POST',
      },
      commit: { type: t.RESTORE_TELL_SUCCESS, meta: payload },
      rollback: { type: t.RESTORE_TELL_ERROR },
    },
  },
})

export const refreshAnswers = (payload) => ({
  type: t.REFRESH_ANSWERS,
  meta: {
    offline: {
      effect: {
        path: `admin/user/posts/${payload.userId}`,
        ...payload,
      },
      commit: {
        type: t.REFRESH_ANSWERS_SUCCESS,
        meta: { userId: payload.userId },
      },
      rollback: { type: t.REFRESH_ANSWERS_ERROR },
    },
  },
})

export const fetchAnswers = (payload) => ({
  type: t.FETCH_ANSWERS,
  meta: {
    offline: {
      effect: {
        path: `admin/user/posts/${payload.userId}`,
        ...payload,
      },
      commit: {
        type: t.FETCH_ANSWERS_SUCCESS,
        meta: payload,
      },
      rollback: { type: t.FETCH_ANSWERS_ERROR },
    },
  },
})

export const refreshBanCandidates = (payload) => ({
  type: t.REFRESH_BAN_CANDIDATES,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/users/bancandidates', ...payload },
      commit: { type: t.REFRESH_BAN_CANDIDATES_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_BAN_CANDIDATES_ERROR },
    },
  },
})

export const fetchBanCandidates = (payload) => ({
  type: t.FETCH_BAN_CANDIDATES,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/users/bancandidates',
        ...payload,
      },
      commit: { type: t.FETCH_BAN_CANDIDATES_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_BAN_CANDIDATES_ERROR },
    },
  },
})

export const refreshBannedDevices = (payload) => ({
  type: t.REFRESH_BANNED_DEVICES,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/ban/devices', ...payload },
      commit: { type: t.REFRESH_BANNED_DEVICES_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_BANNED_DEVICES_ERROR },
    },
  },
})

export const fetchBannedDevices = (payload) => ({
  type: t.FETCH_BANNED_DEVICES,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/ban/devices', ...payload },
      commit: { type: t.FETCH_BANNED_DEVICES_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_BANNED_DEVICES_ERROR },
    },
  },
})

export const refreshComments = (payload) => ({
  type: t.REFRESH_COMMENTS,
  meta: {
    offline: {
      effect: {
        path: 'admin/user/comments',
        ...payload,
      },
      commit: {
        type: t.REFRESH_COMMENTS_SUCCESS,
        meta: { userId: payload.userId },
      },
      rollback: { type: t.REFRESH_COMMENTS_ERROR },
    },
  },
})

export const fetchComments = (payload) => ({
  type: t.FETCH_COMMENTS,
  meta: {
    offline: {
      effect: {
        path: 'admin/user/comments',
        ...payload,
      },
      commit: {
        type: t.FETCH_COMMENTS_SUCCESS,
        meta: payload,
      },
      rollback: { type: t.FETCH_COMMENTS_ERROR },
    },
  },
})

export const refreshCommentsForPost = (payload) => ({
  type: t.REFRESH_COMMENTS_FOR_POST,
  meta: {
    offline: {
      effect: {
        path: 'admin/comment/list',
        ...payload,
      },
      commit: {
        type: t.REFRESH_COMMENTS_FOR_POST_SUCCESS,
        meta: { postId: payload.postId },
      },
      rollback: { type: t.REFRESH_COMMENTS_FOR_POST_ERROR },
    },
  },
})

export const fetchCommentsForPost = (payload) => ({
  type: t.FETCH_COMMENTS_FOR_POST,
  meta: {
    offline: {
      effect: {
        path: 'admin/comment/list',
        ...payload,
      },
      commit: {
        type: t.FETCH_COMMENTS_FOR_POST_SUCCESS,
        meta: payload,
      },
      rollback: { type: t.FETCH_COMMENTS_FOR_POST_ERROR },
    },
  },
})

export const deleteDeviceBan = (payload) => ({
  type: t.DELETE_DEVICE_BAN,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/ban/deletedeviceban',
        method: 'POST',
        ...payload,
      },
      commit: { type: t.DELETE_DEVICE_BAN_SUCCESS, meta: payload },
      rollback: { type: t.DELETE_DEVICE_BAN_ERROR },
    },
  },
})

export const refreshDevices = (payload) => ({
  type: t.REFRESH_DEVICES,
  payload,
  shouldAdjustData: false,
  meta: {
    offline: {
      effect: { path: `admin/user/devices/${payload.userId}` },
      commit: { type: t.REFRESH_DEVICES_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_DEVICES_ERROR },
    },
  },
})

export const fetchDevices = (payload) => ({
  type: t.FETCH_DEVICES,
  payload,
  shouldAdjustData: false,
  meta: {
    offline: {
      effect: {
        path: `admin/user/devices/${payload.userId}`,
        ...payload,
      },
      commit: { type: t.FETCH_DEVICES_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_DEVICES_ERROR },
    },
  },
})

export const refreshAccountVerifications = (payload) => ({
  type: t.REFRESH_ACCOUNT_VERIFICATIONS,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/accountverification/list' },
      commit: { type: t.REFRESH_ACCOUNT_VERIFICATIONS_SUCCESS },
      rollback: { type: t.REFRESH_ACCOUNT_VERIFICATIONS_ERROR },
    },
  },
})

/**
 *
 * @param {object} payload
 * @param {number[]} payload.userIds
 * @returns {object}
 */
export const updateAccountVerifications = (payload) => ({
  type: t.UPDATE_ACCOUNT_VERIFICATIONS,
  payload,
  meta: {
    offline: {
      effect: {
        path: '/admin/accountverification/approve',
        method: 'POST',
        ...payload,
      },
      commit: { type: t.UPDATE_ACCOUNT_VERIFICATIONS_SUCCESS },
      rollback: { type: t.UPDATE_ACCOUNT_VERIFICATIONS_ERROR },
    },
  },
})

export const refreshNsfwPictures = (payload = {}) => {
  const { limit = 70, newerThanId, olderThanId } = payload
  return {
    type: t.REFRESH_NSFW_PICTURES,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/reports/list/pictures',
          limit,
          newerThanId,
          olderThanId,
        },
        commit: { type: t.REFRESH_NSFW_PICTURES_SUCCESS },
        rollback: { type: t.REFRESH_NSFW_PICTURES_ERROR },
      },
    },
  }
}

export const fetchNsfwPictures = (payload = {}) => {
  const { limit = 70, newerThanId, olderThanId } = payload
  return {
    type: t.FETCH_NSFW_PICTURES,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/reports/list/pictures',
          limit,
          newerThanId,
          olderThanId,
        },
        commit: { type: t.FETCH_NSFW_PICTURES_SUCCESS },
        rollback: { type: t.FETCH_NSFW_PICTURES_ERROR },
      },
    },
  }
}

export const refreshProfile = ({ isModeration = false, ...payload }) => ({
  type: t.REFRESH_PROFILE,
  payload,
  meta: {
    offline: {
      effect: { path: `admin/user/settings/${payload.userId}` },
      commit: { type: t.REFRESH_PROFILE_SUCCESS, meta: { isModeration } },
      rollback: { type: t.REFRESH_PROFILE_ERROR },
    },
  },
})

export const refreshTells = ({ searchPhrase, userId, ...payload }) => {
  const basePath = `admin/user/tells/${userId}`
  const path = searchPhrase
    ? `${basePath}?searchPhrase=${searchPhrase}`
    : basePath

  return {
    type: t.REFRESH_TELLS,
    payload,
    meta: {
      offline: {
        effect: { path },
        commit: {
          type: t.REFRESH_TELLS_SUCCESS,
          meta: { userId },
        },
        rollback: { type: t.REFRESH_TELLS_ERROR },
      },
    },
  }
}

export const fetchTells = (payload) => ({
  type: t.FETCH_TELLS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/tells/${payload.userId}`,
        ...payload,
      },
      commit: { type: t.FETCH_TELLS_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_TELLS_ERROR },
    },
  },
})

export const refreshSentTells = ({ searchPhrase, userId, ...payload }) => {
  const basePath = `admin/user/tells/${userId}/sent`
  const path = searchPhrase
    ? `${basePath}?searchPhrase=${searchPhrase}`
    : basePath

  return {
    type: t.REFRESH_SENT_TELLS,
    payload,
    meta: {
      offline: {
        effect: { path },
        commit: { type: t.REFRESH_SENT_TELLS_SUCCESS, meta: { userId } },
        rollback: { type: t.REFRESH_SENT_TELLS_ERROR },
      },
    },
  }
}

export const fetchSentTells = (payload) => ({
  type: t.FETCH_SENT_TELLS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/tells/${payload.userId}/sent`,
        ...payload,
      },
      commit: { type: t.FETCH_SENT_TELLS_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_SENT_TELLS_ERROR },
    },
  },
})

export const refreshUserLog = (payload) => ({
  type: t.REFRESH_USER_LOG,
  payload,
  meta: {
    offline: {
      effect: { path: `admin/user/log/${payload.id}`, types: payload.types },
      commit: { type: t.REFRESH_USER_LOG_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_USER_LOG_ERROR },
    },
  },
})

export const fetchUserLog = ({ oldestId, ...payload }) => ({
  type: t.FETCH_USER_LOG,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/user/log/${payload.id}`,
        olderThanId: oldestId,
        types: payload.types,
        ...payload,
      },
      commit: { type: t.FETCH_USER_LOG_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_USER_LOG_ERROR },
    },
  },
})

export const refreshIap = (payload) => ({
  type: t.REFRESH_IAP,
  payload,
  meta: {
    offline: {
      effect: { path: `admin/user/iap/${payload.id}` },
      commit: { type: t.REFRESH_IAP_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_IAP_ERROR },
    },
  },
})

export const fetchIap = (payload) => ({
  type: t.FETCH_IAP,
  payload,
  meta: {
    offline: {
      effect: { path: `admin/user/iap/${payload.id}` },
      commit: { type: t.FETCH_IAP_SUCCESS, meta: payload },
      rollback: { type: t.FETCH_IAP_ERROR },
    },
  },
})

export const recount = (payload) => ({
  type: t.RECOUNT,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/recount', method: 'POST' },
      commit: { type: t.RECOUNT_SUCCESS, meta: payload },
      rollback: { type: t.RECOUNT_ERROR },
    },
  },
})

export const search = (payload) => ({
  type: t.SEARCH,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/search/users',
        method: 'POST',
        limit: 500,
        ...payload,
      },
      commit: { type: t.SEARCH_SUCCESS },
      rollback: { type: t.SEARCH_ERROR },
    },
  },
})

export const selectAll = (payload) => ({
  type: t.SELECT_ALL,
  payload,
})

export const sendModerationInvitation = ({ lang, userId }) => ({
  type: t.SEND_MODERATION_INVITATION,
  payload: {
    announcementId: lang === 'de' ? 22 : 23, // announcementId can be found in DB table announcements
    userId,
  },
  meta: {
    offline: {
      effect: { path: 'admin/announcements/assign', method: 'POST' },
      commit: { type: t.SEND_MODERATION_INVITATION_SUCCESS },
      rollback: { type: t.SEND_MODERATION_INVITATION_ERROR },
    },
  },
})

export const sendPushNotification = (payload) => ({
  type: t.SEND_PUSH_NOTIFICATITON,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/notifications/sendpushnotification',
        method: 'POST',
      },
    },
  },
})

export const sendRecoveryMail = (payload) => ({
  type: t.SEND_RECOVERY_MAIL,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/sendpwrecoverymail', method: 'POST' },
      commit: { type: t.SEND_RECOVERY_MAIL_SUCCESS, meta: payload },
      rollback: { type: t.SEND_RECOVERY_MAIL_ERROR },
    },
  },
})

export const sendTell = (payload) => ({
  type: t.SEND_TELL,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/user/actions/sendtell', method: 'POST' },
      commit: { type: t.SEND_TELL_SUCCESS, meta: payload },
      rollback: { type: t.SEND_TELL_ERROR },
    },
  },
})

export const setHasTakenModerationAction = (payload) => ({
  type: t.SET_HAS_TAKEN_MODERATION_ACTION,
  payload,
})

export const setNsfwPictureStatus = (payload) => ({
  type: t.SET_NSFW_PICTURE_STATUS,
  payload,
})

export const setSelection = (payload) => ({
  type: t.SET_SELECTION,
  payload,
})

export const refreshChatMessages = (payload) => ({
  type: t.REFRESH_CHAT_MESSAGES,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/chat', ...payload, limit: 100 },
      commit: { type: t.REFRESH_CHAT_MESSAGES_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_CHAT_MESSAGES_ERROR, meta: payload },
    },
  },
})

export const unhideBanCandidate = (payload) => ({
  type: t.UNHIDE_BAN_CANDIDATE,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/users/bancandidates/${payload.userId}`,
        method: 'PUT',
      },
      commit: { type: t.UNHIDE_BAN_CANDIDATE_SUCCESS },
      rollback: { type: t.UNHIDE_BAN_CANDIDATE_ERROR },
    },
  },
})

export const updateNsfwPictures = () => (dispatch, getState) => {
  const state = getState()

  const nsfwPictures = state.moderator.nsfwPictures.data

  const payload = {
    decisions: nsfwPictures.ids.reduce((acc, id) => {
      const picture = nsfwPictures.data[id]

      return append(
        {
          pictureId: picture.id,
          reportId: picture.reportId,
          decision:
            picture._decision === REPORT_DECISION.NOT_OKAY
              ? REPORT_DECISION.NOT_OKAY
              : REPORT_DECISION.OKAY,
          isNSFW:
            picture._decision === REPORT_DECISION.SKIP ||
            picture._decision === REPORT_DECISION.NOT_OKAY,
        },
        acc
      )
    }, []),
  }

  return dispatch({
    type: t.UPDATE_NSFW_PICTURES,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/reports/decide/pictures',
          method: 'POST',
        },
        commit: { type: t.UPDATE_NSFW_PICTURES_SUCCESS },
        rollback: { type: t.UPDATE_NSFW_PICTURES_ERROR },
      },
    },
  })
}

export const addBlacklistEntry = ({ ...payload }) => ({
  type: t.ADD_BLACKLIST_ENTRY,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/blacklist/add', method: 'POST', ...payload },
      commit: { type: t.ADD_BLACKLIST_ENTRY_SUCCESS, meta: payload },
      rollback: { type: t.ADD_BLACKLIST_ENTRY_ERROR },
    },
  },
})

export const refreshBlacklists = (payload) => ({
  type: t.REFRESH_BLACKLISTS,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/blacklist', ...payload },
      commit: { type: t.REFRESH_BLACKLISTS_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_BLACKLISTS_ERROR },
    },
  },
})

export const deleteBlacklistEntry = (payload) => ({
  type: t.REMOVE_BLACKLIST,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/blacklist/remove',
        method: 'POST',
        ...payload,
      },
      commit: { type: t.REMOVE_BLACKLIST_SUCCESS, meta: payload },
      rollback: { type: t.REMOVE_BLACKLIST_ERROR },
    },
  },
})
export const refreshFollowers = ({ userId, ...payload }) => {
  const path = `admin/followers/${userId}`

  return {
    type: t.REFRESH_FOLLOWERS,
    payload,
    meta: {
      offline: {
        effect: { path },
        commit: { type: t.REFRESH_FOLLOWERS_SUCCESS, meta: { userId } },
        rollback: { type: t.REFRESH_FOLLOWERS_ERROR },
      },
    },
  }
}

export const fetchFollowers = ({ userId, oldestTime, ...payload }) => {
  const path = `admin/followers/${userId}`
  let olderThanTime

  if (oldestTime) {
    olderThanTime = new Date(oldestTime).getTime()
  }

  return {
    type: t.FETCH_FOLLOWERS,
    payload,
    meta: {
      offline: {
        effect: { path, olderThanTime },
        commit: {
          type: t.FETCH_FOLLOWERS_SUCCESS,
          meta: { userId, olderThanTime },
        },
        rollback: { type: t.FETCH_FOLLOWERS_ERROR },
      },
    },
  }
}

export const refreshFollowings = ({ userId, ...payload }) => {
  const path = `admin/followings/${userId}`

  return {
    type: t.REFRESH_FOLLOWINGS,
    payload,
    meta: {
      offline: {
        effect: { path },
        commit: { type: t.REFRESH_FOLLOWINGS_SUCCESS, meta: { userId } },
        rollback: { type: t.REFRESH_FOLLOWINGS_ERROR },
      },
    },
  }
}

export const fetchFollowings = ({ userId, oldestTime, ...payload }) => {
  const path = `admin/followings/${userId}`
  let olderThanTime

  if (oldestTime) {
    olderThanTime = new Date(oldestTime).getTime()
  }

  return {
    type: t.FETCH_FOLLOWINGS,
    payload,
    meta: {
      offline: {
        effect: { path, olderThanTime },
        commit: {
          type: t.FETCH_FOLLOWINGS_SUCCESS,
          meta: { userId, olderThanTime },
        },
        rollback: { type: t.FETCH_FOLLOWINGS_ERROR },
      },
    },
  }
}

export const removePostComment = (payload) => ({
  type: t.REMOVE_POST_COMMENT,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/comment/remove', method: 'POST', ...payload },
      commit: { type: t.REMOVE_POST_COMMENT_SUCCESS },
      rollback: { type: t.REMOVE_POST_COMMENT_ERROR },
    },
  },
})

export const createQuick7DayBan =
  ({ userId }) =>
  (dispatch) => {
    if (window.location.pathname.includes('moderation')) {
      dispatch(setHasTakenModerationAction(true))
    }

    for (const position of [0, 1, 2]) {
      dispatch(
        deleteProfilePic({
          userId,
          position,
        })
      )
    }

    dispatch(
      createBan({
        userId,
        banCause: getMessageForBanReason(),
        reason: BAN_REASON.SEXUAL_CONTENT,
        shouldBanDevices: false,
        shouldBanUsersUsingSameDevice: false,
        timeEnd: dayjs().add(7, 'day'),
      })
    )
  }

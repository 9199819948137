import { CloseOutlined } from '@ant-design/icons'
import { Line } from '@ant-design/plots'
import { colors } from '@tellonym/core/common/colorSystem'
import { getRandomInt } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Spin,
  Tag,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import qs from 'qs'
import { compose } from 'ramda'
import React from 'react'
import { useDispatch } from 'react-redux'
import { _, Box, styleSheets } from '../../common'
import { convertArrayToOptions } from '../../common/helpers'
import * as hooks from '../../common/hooks'
import { hexWithAlpha } from '../../common/services'
import {
  useAnalyticsEventNamesQuery,
  useAnalyticsEventPropsQuery,
} from '../../tools/queries'
import { useChEventDataMutation } from '../queries'
import { downloadCsv } from '../services'

const lineColors = [
  '#00B7FF',
  '#004DFF',
  '#00FFFF',
  '#826400',
  '#580041',
  '#FF00FF',
  '#00FF00',
  '#C500FF',
  '#B4FFD7',
  '#FFCA00',
  '#969600',
  '#B4A2FF',
  '#C20078',
  '#0000C1',
  '#FF8B00',
  '#FFC8FF',
  '#666666',
  '#FF0000',
  '#CCCCCC',
  '#009E8F',
  '#D7A870',
  '#8200FF',
  '#960000',
  '#BBFF00',
  '#FFFF00',
  '#006F00',
]

const styles = {
  clickable: { cursor: 'pointer' },
}

const { Title, Text, Link } = Typography

const defaultEntries = [
  ['event_time', '>', dayjs().subtract(1, 'day').unix()],
  ['event_time', '<', dayjs().unix()],
  [],
]

const hoursFormatting = (v) => {
  const string = dayjs(v).format('HH:mm')

  if (string === '00:00') {
    return dayjs(v).format('DD.MM.')
  }

  return string
}

const timeIntervalFormat = {
  minute: {
    group: 'YYYY-MM-DD HH:mm',
    format: hoursFormatting,
  },
  hour: {
    group: 'YYYY-MM-DD HH:[00]',
    format: hoursFormatting,
  },
  day: { group: 'YYYY-MM-DD', format: (v) => dayjs(v).format('DD.MM.') },
}

const timeIntervalOptions = convertArrayToOptions(
  Object.keys(timeIntervalFormat)
)

const LINE_CONFIG = {
  padding: 'auto',
  xField: 'date',
  yField: 'value',
  seriesField: 'dataType',
  connectNulls: false,
  legend: {
    layout: 'horizontal',
    position: 'top',
    flipPage: false,
  },
  tooltip: {
    position: 'right',
    offset: 120,
  },
  interactions: [{ type: 'legendFilter' }, { type: 'elementSelect' }],
}

const sortBy = (sortedDataTypes) => (originalItems) =>
  originalItems.slice().sort((a, b) => {
    const aIndex = sortedDataTypes.indexOf(a.data.dataType)
    const bIndex = sortedDataTypes.indexOf(b.data.dataType)

    if (aIndex < bIndex) {
      return -1
    }
    if (aIndex > bIndex) {
      return 1
    }
    return 0
  })

const GRAPH_DEFAULTS = {
  dataSource: [],
  lineConfig: LINE_CONFIG,
}

const Graph = React.memo(
  ({
    data,
    compareDaysAmount,
    comparisonData,
    err,
    groupBy,
    isLoading,
    timeInterval,
  }) => {
    const { dataSource, lineConfig } = React.useMemo(() => {
      if (
        typeof data === 'undefined' ||
        (!groupBy && typeof comparisonData === 'undefined')
      ) {
        return GRAPH_DEFAULTS
      }

      if (!groupBy) {
        const dataByTimeInterval = {}

        /**
         * We compare the current day data with the data for the whole comparison day.
         * Therefore there might be more events for the comparison data than the data.
         */
        const maxEventCount = Math.max(
          data?.eventData?.length ?? 0,
          comparisonData?.eventData?.length ?? 0
        )

        for (let i = 0; i < maxEventCount; i++) {
          const pointEvent = data.eventData[i]
          const pointComparison = comparisonData.eventData[i]
          const time = pointEvent?.time ?? pointComparison?.time

          const date = dayjs
            .utc(time)
            .format(timeIntervalFormat[timeInterval].group)

          if (pointEvent) {
            const key = `${date}-event`

            dataByTimeInterval[key] = dataByTimeInterval[key] ?? {
              date,
              value: 0,
              dataType: 'event',
            }

            dataByTimeInterval[key].value += Number(pointEvent.amount)
          }

          if (pointComparison) {
            const key = `${date}-comparison`

            dataByTimeInterval[key] = dataByTimeInterval[key] ?? {
              date,
              value: 0,
              dataType: `${compareDaysAmount} days ago`,
            }

            dataByTimeInterval[key].value += Number(pointComparison.amount)
          }
        }

        return {
          dataSource: Object.values(dataByTimeInterval),
          lineConfig: {
            ...LINE_CONFIG,
            color: [colors.saphire[6], hexWithAlpha(colors.saphire[6], 0.5)],
            xAxis: {
              label: {
                formatter: timeIntervalFormat[timeInterval].format,
              },
            },
          },
        }
      }

      const dataTypeSum = {}

      const dataSource = Object.values(
        data.eventData.reduce((acc, { time, amount, ...groupByObject }) => {
          const date = dayjs(time).format(
            timeIntervalFormat[timeInterval].group
          )
          const [, groupByValue] = Object.entries(groupByObject)[0] ?? []
          const dataType = String(groupByValue)
          const key = `${date}-${dataType}`

          dataTypeSum[dataType] = (dataTypeSum[dataType] ?? 0) + Number(amount)

          acc[key] = acc[key] ?? { date, value: 0, dataType }
          acc[key].value += Number(amount)

          return acc
        }, {})
      )

      // create sorting of dataTypes by their sum
      const sortedDataTypes = Object.keys(dataTypeSum).sort(
        (a, b) => dataTypeSum[b] - dataTypeSum[a]
      )

      // sort dataSource by date and sortedDataTypes
      dataSource.sort((a, b) => {
        if (a.date < b.date) {
          return -1
        }
        if (a.date > b.date) {
          return 1
        }

        const aIndex = sortedDataTypes.indexOf(a.dataType)
        const bIndex = sortedDataTypes.indexOf(b.dataType)

        if (aIndex < bIndex) {
          return -1
        }
        if (aIndex > bIndex) {
          return 1
        }
        return 0
      })

      return {
        dataSource,
        lineConfig: {
          ...LINE_CONFIG,
          tooltip: {
            ...LINE_CONFIG.tooltip,
            customItems: sortBy(sortedDataTypes),
            // eslint-disable-next-line react/no-unstable-nested-components
            customContent: (title, data) => {
              /**
               * We use groupBy from the query params as the customContent function is somehow
               * cached by antd and would show wrong data otherwise.
               */
              const { groupBy } = qs.parse(
                window.location.search.split('?')[1],
                {
                  arrayLimit: 100,
                }
              )

              const total = data.reduce(
                (acc, { data }) => acc + Number(data.value),
                0
              )
              return (
                <Box>
                  <Box paddingVertical={12}>{title}</Box>
                  {data.map(({ mappingData, name, value }) => {
                    const percentage =
                      total > 0 ? (Number(value) / total) * 100 : 0

                    return (
                      <Box
                        key={name}
                        paddingBottom={12}
                        flexDirection="row"
                        justifyContent="space-between"
                        gap={30}>
                        <Box flexDirection="row" gap={8} alignItems="center">
                          <Box
                            width={8}
                            height={8}
                            backgroundColor={mappingData.color}
                            borderRadius={4}
                          />
                          <Box>{name}</Box>
                        </Box>
                        <Box gap={12} flexDirection="row">
                          <Box>
                            {new Intl.NumberFormat('en-US').format(value)}
                          </Box>
                          {groupBy ? (
                            <Box>{`${percentage.toFixed(2)}%`}</Box>
                          ) : undefined}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              )
            },
          },
          color: sortedDataTypes.map((_, i) => {
            if (i < lineColors.length) {
              return lineColors[i]
            }

            return hexWithAlpha(
              lineColors[i % lineColors.length],
              (getRandomInt(9) + 1) / 10
            )
          }),
          xAxis: {
            label: {
              formatter: timeIntervalFormat[timeInterval].format,
            },
          },
        },
      }
    }, [comparisonData, data, timeInterval, groupBy, compareDaysAmount])

    return (
      <Spin spinning={isLoading}>
        <Line data={dataSource} {...lineConfig} />
        <Box alignItems="center" paddingTop={12}>
          <Button
            disabled={dataSource.length === 0}
            onClick={() => {
              const data = {}

              for (const { date, value, dataType } of dataSource) {
                data[date] = data[date] ?? {}
                data[date][dataType] = value
              }

              const dates = Object.keys(data)

              const dataTypes = Object.keys(data[dates[0]]).map((type) =>
                /^\d+$/.test(type) ? Number(type) : type
              )

              const isNumeric =
                dataTypes.some((type) => typeof type !== 'number') === false

              const dataTypesSorted = [...dataTypes].sort((a, b) => {
                if (isNumeric) {
                  return a - b
                }

                return a < b ? -1 : 1
              })

              const csv = Object.keys(data).reduce((acc, date) => {
                return `${acc}${date},${dataTypesSorted
                  .map((dataType) => data[date][dataType] ?? 0)
                  .join(',')}\n`
              }, `date,${dataTypesSorted.join(',')}\n`)

              downloadCsv(csv)
            }}>
            Download CSV
          </Button>
        </Box>
        {typeof err?.msg === 'string' && (
          <Box alignItems="center" paddingTop={12}>
            <Box
              style={{
                fontSize: 10,
                backgroundColor: 'rgba(0,0,0,0.025)',
                padding: 8,
                borderRadius: 12,
                borderColor: 'red',
                borderWidth: 1,
                borderStyle: 'solid',
                maxWidth: 600,
              }}>
              {err?.msg}
            </Box>
          </Box>
        )}
      </Spin>
    )
  },
  /**
   * We only want to change the graph after the query has run.
   */
  (prev, next) =>
    prev.comparisonData === next.comparisonData &&
    prev.data === next.data &&
    prev.err === next.err
)

const OptionsInput = ({
  dropdownStyle,
  onChange,
  isDisabled,
  options,
  placeholder,
  value,
  style,
  onFocus,
  onBlur,
}) => {
  const [internalValue, setInternalValue] = React.useState(value ? [value] : [])

  const onChangeInternal = (v) => {
    const nextValue = v[0] ? [v[v.length - 1]] : []

    setInternalValue(nextValue)
    onChange?.(nextValue[0])
  }

  React.useEffect(() => {
    if (value !== internalValue[0]) {
      setInternalValue([value])
    }
  }, [value])

  return (
    <Select
      dropdownMatchSelectWidth={false}
      disabled={isDisabled}
      dropdownStyle={dropdownStyle}
      mode="tags"
      maxTagCount={1}
      placeholder={placeholder}
      options={options}
      onChange={onChangeInternal}
      onFocus={onFocus}
      onBlur={onBlur}
      value={internalValue}
      style={{ ...style, fontSize: 12 }}
    />
  )
}

const operatorOptions = convertArrayToOptions([
  '>',
  '<',
  '==',
  '!=',
  "!= ''",
  'IN',
  'NOT IN',
  'has',
  '!has',
  'IS NULL',
  'IS NOT NULL',
])

const QueryRow = ({
  entry,
  isDisabled,
  keyOptions,
  keyType,
  onChange,
  onPressRemove,
}) => {
  const [key, operator, values = ''] = entry

  const onChangeKey = (v) => {
    onChange?.([v, operator ?? '==', values])
  }

  const onChangeOperator = (v) => {
    onChange?.([key, v, values])
  }

  const onChangeValues = (e) => {
    switch (keyType) {
      case 'DateTime':
        onChange?.([key, operator, e.unix()])
        break

      default:
        onChange?.([key, operator, e.target.value])
    }
  }

  const renderExtraFooter = React.useCallback(() => {
    const onPressCreator = (amount) => () => {
      onChange?.([key, operator, dayjs().subtract(amount).unix()])
    }

    const onPressDayCreator = (amount) => () => {
      onChange?.([
        key,
        operator,
        dayjs().hour(0).minute(0).subtract(amount).unix(),
      ])
    }

    return (
      <Box flexDirection="row" justifyContent="space-between">
        <Link onClick={onPressCreator(15 * _.min)}>15min</Link>
        <Link onClick={onPressCreator(1 * _.h)}>1h</Link>
        <Link onClick={onPressCreator(3 * _.h)}>3h</Link>
        <Link onClick={onPressDayCreator(1 * _.d)}>1D</Link>
        <Link onClick={onPressDayCreator(7 * _.d)}>7D</Link>
        <Link onClick={onPressDayCreator(30 * _.d)}>30D</Link>
        <Link onClick={onPressDayCreator(90 * _.d)}>90D</Link>
      </Box>
    )
  }, [])

  const InputComponent = React.useMemo(() => {
    switch (true) {
      case keyType === 'DateTime' && ['>', '<', '==', '!='].includes(operator):
        return (props) => (
          <DatePicker
            {...props}
            showToday={false}
            showTime
            renderExtraFooter={renderExtraFooter}
            format="YYYY-MM-DD HH:mm"
            value={dayjs.unix(values)}
          />
        )

      default:
        return Input
    }
  }, [keyType, operator, renderExtraFooter, values])

  return (
    <Box flexDirection="row" marginBottom={12} alignItems="center">
      <OptionsInput
        isDisabled={isDisabled}
        onChange={onChangeKey}
        options={keyOptions}
        placeholder="Key"
        value={key}
        style={{ width: '33%', marginRight: 12 }}
      />
      <OptionsInput
        isDisabled={isDisabled}
        onChange={onChangeOperator}
        placeholder="Operation"
        options={operatorOptions}
        value={operator}
        dropdownStyle={{ width: 120, minWidth: 120 }}
        style={{ width: 70, marginRight: 12 }}
      />
      <InputComponent
        disabled={isDisabled}
        onChange={onChangeValues}
        placeholder="values"
        value={values}
        style={{ flex: 1 }}
      />
      <CloseOutlined
        onClick={onPressRemove}
        style={{
          fontSize: 12,
          marginLeft: 4,
          padding: 8,
          alignSelf: 'center',
        }}
      />
    </Box>
  )
}

export const QueryBuilder = ({
  entries,
  groupBy,
  isDisabled,
  onChange,
  setGroupBy,
  tablePropsData,
}) => {
  const keyOptions = React.useMemo(() => {
    if (typeof tablePropsData === 'undefined') {
      return undefined
    }

    return convertArrayToOptions(
      tablePropsData?.properties?.map(({ name }) => name)
    )
  }, [tablePropsData])

  const onChangeRow = (index) => (row) => {
    const newEntries = [...entries]

    newEntries[index] = row

    onChange(newEntries)
  }

  const onPressRemoveRow = (index) => () => {
    if (
      entries[index]?.[0] === groupBy &&
      entries.some((e, idx) => index !== idx && e[0] === groupBy) === false
    ) {
      setGroupBy(null)
    }

    onChange((entries) => entries.filter((_, i) => i !== index))
  }

  React.useEffect(() => {
    // if the last entry is filled, add a new one
    const lastEntry = entries[entries.length - 1]

    if (typeof lastEntry?.[0] !== 'undefined') {
      onChange([...entries, []])
    }
  }, [entries])

  return entries.map((entry, index) => (
    <QueryRow
      key={index}
      onPressRemove={onPressRemoveRow(index)}
      isDisabled={isDisabled}
      entry={entry}
      keyOptions={keyOptions}
      keyType={
        tablePropsData?.properties?.find(({ name }) => name === entry[0])?.type
      }
      onChange={onChangeRow(index)}
    />
  ))
}

const QueryBuilderSection = ({
  entries,
  groupBy,
  isDisabled,
  onChange,
  setGroupBy,
  tablePropsData,
}) => {
  const keyOptions = React.useMemo(() => {
    if (typeof tablePropsData === 'undefined') {
      return undefined
    }

    return convertArrayToOptions(
      tablePropsData?.properties?.map(({ name }) => name)
    )
  }, [tablePropsData])

  const onChangeGroupBy = (groupBy) => {
    if (groupBy) {
      setGroupBy(groupBy)
    } else {
      setGroupBy(null)
    }
  }

  return (
    <>
      <Title level={5}>Query</Title>
      <Box flexDirection="row" alignItems="center" paddingBottom={12}>
        <Text>Group by</Text>
        <Select
          allowClear
          disabled={isDisabled}
          options={keyOptions?.filter(({ value }) => value !== 'event_time')}
          onChange={onChangeGroupBy}
          value={groupBy}
          style={{ marginLeft: 12, flex: 1 }}
        />
      </Box>
      <QueryBuilder
        entries={entries}
        groupBy={groupBy}
        isDisabled={isDisabled}
        onChange={onChange}
        setGroupBy={setGroupBy}
        tablePropsData={tablePropsData}
      />
    </>
  )
}

const getChangedStartDate =
  ({ days, operator }) =>
  (filters) => {
    // filters look like this: [column, operator, value]

    const index = filters.findIndex(
      (f) => f[0] === 'event_time' && f[1] === operator
    )

    if (index === -1) {
      return filters
    }

    const dateUnix = filters[index][2]

    const dateAgo = dayjs.unix(dateUnix).subtract(days, 'day').unix()

    const newFilters = window.structuredClone(filters)

    const isEndDateCurrentDay =
      operator === '<' && dayjs.unix(dateUnix).isSame(dayjs(), 'day')

    /**
     * While the current day is still in progress, we can still show
     * the whole day for the comparison data.
     */
    if (isEndDateCurrentDay) {
      newFilters[index][2] = dayjs.unix(dateAgo).add(1, 'second').unix()
    } else {
      newFilters[index][2] = dateAgo
    }

    return newFilters
  }

const changeFilterToDaysAgo = (days, filters) =>
  compose(
    getChangedStartDate({ days, operator: '>' }),
    getChangedStartDate({ days, operator: '<' })
  )(filters)

const amount = {
  ALL: 'All',
  UNIQUE: 'Unique users',
}
const amountOptions = convertArrayToOptions(Object.values(amount))

const defaultQueryParams = {
  amount: amount.ALL,
  timeInterval: 'minute',
  entries: defaultEntries,
  groupBy: null,
  selectedTable: undefined,
}

export const PageClickhouseEvents = () => {
  const dispatch = useDispatch()
  const containerStyle = hooks.usePageContainerStyle()

  const [queryParams, setQueryParams] = hooks.useQueryParams(defaultQueryParams)

  const onPressFetchRef = React.useRef(null)
  const prevSelectedTable = React.useRef(null)
  const prevTablePropsData = React.useRef(null)

  const [isDirty, setIsDirty] = React.useState(false)

  const [hasUsedQuickTimeAction, setHasUsedQuickTimeAction] =
    React.useState(false)

  const [selectedTable, setSelectedTable] = React.useState(
    queryParams.selectedTable
  )
  const [timeInterval, setTimeInterval] = React.useState(
    queryParams.timeInterval
  )
  const [amountValue, setAmountValue] = React.useState(queryParams.amount)
  const [entries, setEntries] = React.useState(queryParams.entries)
  const [groupBy, setGroupBy] = React.useState(queryParams.groupBy || null) // needs to use || to handle "" case
  const [err, setErr] = React.useState(undefined)

  const [compareDaysAmount, setCompareDaysAmount] = React.useState(7)

  const { isLoading: isLoadingTables, data: availableEventNames } =
    useAnalyticsEventNamesQuery()

  const { isFetching: isLoadingProps, data: tablePropsData } =
    useAnalyticsEventPropsQuery({
      tableName: selectedTable,
    })

  const {
    data,
    isLoading: isLoadingData,
    mutate,
  } = useChEventDataMutation({
    onMutate: () => {
      setErr(undefined)
    },
    onSuccess: () => {
      setIsDirty(false)
    },
    onError: async (error) => {
      try {
        const { err } = await error.json()
        setErr(err)
      } catch (e) {
        console.error(e)
      }
    },
  })

  const { data: comparisonData, mutate: mutateComparison } =
    useChEventDataMutation()

  const onChangeTable = (v) => {
    setIsDirty(true)
    setSelectedTable(v)
  }

  const onChangeTimeInterval = (e) => {
    setIsDirty(true)
    setTimeInterval(e.target.value)
  }

  const onChangeEntries = (v) => {
    setIsDirty(true)
    setEntries(v)
  }

  const onChangeAmount = (e) => {
    setIsDirty(true)
    setAmountValue(e.target.value)
  }

  const onChangeCompareDaysAmount = (v) => {
    setIsDirty(true)
    setCompareDaysAmount(v)
  }

  const onPressQuickTimeCreator = (amountBackwards) => () => {
    const existingEntriesWithoutEventTime = entries.filter(
      (entry) => entry[0] !== 'event_time'
    )

    const newEntries = [
      ['event_time', '>', dayjs().subtract(amountBackwards).unix()],
      ['event_time', '<', dayjs().unix()],
      ...existingEntriesWithoutEventTime,
    ]

    setIsDirty(true)
    setEntries(newEntries)
    setHasUsedQuickTimeAction(true)
  }

  const onPressToday = () => {
    const existingEntriesWithoutEventTime = entries.filter(
      (entry) => entry[0] !== 'event_time'
    )

    const newEntries = [
      ['event_time', '>', dayjs().startOf('day').unix()],
      ['event_time', '<', dayjs().endOf('day').unix()],
      ...existingEntriesWithoutEventTime,
    ]

    setIsDirty(true)
    setEntries(newEntries)
    setHasUsedQuickTimeAction(true)
  }

  const onPressFetch = () => {
    const sanitisedEntries = entries
      .filter(
        (entry) =>
          entry.length === 3 && entry.every((v) => typeof v !== 'undefined')
      )
      .map(([key, operator, value]) => {
        if (/^\d+$/.test(value)) {
          return [key, operator, parseInt(value, 10)]
        }

        return [key, operator, value]
      })

    const hasGroupBy = typeof groupBy === 'string'

    mutate({
      tableName: selectedTable,
      filters: sanitisedEntries,
      groupBy: hasGroupBy ? groupBy : undefined,
      interval: timeInterval,
      shouldShowUniqueUsers: amountValue === amount.UNIQUE,
    })

    if (hasGroupBy) {
      return
    }

    const comparisonFilters = changeFilterToDaysAgo(
      compareDaysAmount ?? 7,
      sanitisedEntries
    )

    mutateComparison({
      tableName: selectedTable,
      filters: comparisonFilters,
      interval: timeInterval,
      shouldShowUniqueUsers: amountValue === amount.UNIQUE,
    })
  }

  onPressFetchRef.current = onPressFetch

  React.useEffect(() => {
    setQueryParams({
      amount: amountValue,
      timeInterval,
      entries,
      groupBy,
      selectedTable,
    })
  }, [
    amountValue,
    entries,
    groupBy,
    selectedTable,
    setQueryParams,
    timeInterval,
  ])

  React.useEffect(() => {
    if (
      typeof tablePropsData !== 'undefined' &&
      prevTablePropsData.current !== tablePropsData
    ) {
      prevTablePropsData.current = tablePropsData

      // if the props have changed reset entries that are not existing anymore
      const newEntries = entries.filter(([key]) =>
        tablePropsData?.properties?.some(({ name }) => name === key)
      )

      setEntries(newEntries)

      // if the props have changed reset groupBy if it is not existing anymore
      if (typeof groupBy === 'string') {
        const hasGroupBy = tablePropsData?.properties?.some(
          ({ name }) => name === groupBy
        )

        if (!hasGroupBy) {
          setGroupBy(null)
        }
      }
    }
  }, [entries, groupBy, tablePropsData])

  React.useEffect(() => {
    if (typeof selectedTable !== 'undefined') {
      onPressFetch()
    }

    const submitListener = (e) => {
      if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
        onPressFetchRef.current?.()
      }
    }

    document.addEventListener('keydown', submitListener)

    return () => {
      document.removeEventListener('keydown', submitListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const hasChangedTable =
      typeof selectedTable !== 'undefined' &&
      selectedTable !== prevSelectedTable.current

    if (hasChangedTable) {
      prevSelectedTable.current = selectedTable
      onPressFetch()
    } else if (hasUsedQuickTimeAction) {
      setHasUsedQuickTimeAction(false)
      onPressFetch()
    }
  }, [selectedTable, hasUsedQuickTimeAction])

  const query = data?.query ?? err?.query

  return (
    <Box style={containerStyle}>
      <Box padding={32} backgroundColor={colors.background}>
        <Typography.Title level={3}>Clickhouse Events</Typography.Title>
        <Box flexDirection="row">
          <Box padding={12} flex={2} marginRight={24}>
            <Graph
              compareDaysAmount={compareDaysAmount}
              comparisonData={comparisonData}
              data={data}
              err={err}
              groupBy={groupBy}
              isLoading={isLoadingData}
              timeInterval={timeInterval}
            />
          </Box>

          <Box flex={1}>
            <Box marginBottom={24}>
              <Title level={5}>Table</Title>
              <OptionsInput
                isDisabled={isLoadingTables}
                placeholder="Select table..."
                options={availableEventNames}
                onChange={onChangeTable}
                value={selectedTable}
              />
            </Box>

            <Box marginBottom={24}>
              <Title level={5}>Config</Title>
              <Box flexDirection="row">
                <Radio.Group
                  onChange={onChangeTimeInterval}
                  optionType="button"
                  options={timeIntervalOptions}
                  value={timeInterval}
                />

                <Box flex={1} alignItems="flex-end">
                  <Button
                    disabled={typeof selectedTable === 'undefined'}
                    type={isDirty ? 'primary' : undefined}
                    onClick={onPressFetch}>
                    Run Query
                  </Button>
                </Box>
              </Box>

              <Box marginTop={24}>
                <Radio.Group
                  optionType="button"
                  options={amountOptions}
                  onChange={onChangeAmount}
                  value={amountValue}
                />
              </Box>

              <Box marginTop={24} flexDirection="row" alignItems="center">
                <InputNumber
                  disabled={typeof groupBy === 'string' && groupBy !== ''}
                  onChange={onChangeCompareDaysAmount}
                  value={compareDaysAmount}
                  style={{ width: 60 }}
                />
                <Text style={styleSheets.margin.left[12]}>
                  {' '}
                  days comparison
                </Text>
              </Box>

              <Box marginTop={24}>
                <Title level={5}>Time quick actions</Title>
                <Box flexDirection="row" alignItems="center">
                  <Tag
                    color="blue"
                    onClick={onPressToday}
                    style={styles.clickable}>
                    Today
                  </Tag>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(15 * _.min)}
                    style={styles.clickable}>
                    Last 15min
                  </Tag>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(3 * _.h)}
                    style={styles.clickable}>
                    Last 3h
                  </Tag>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(24 * _.h)}
                    style={styles.clickable}>
                    Last 24h
                  </Tag>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(3 * _.d)}
                    style={styles.clickable}>
                    Last 3d
                  </Tag>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(7 * _.d)}
                    style={styles.clickable}>
                    Last 7d
                  </Tag>
                </Box>
                <Box flexDirection="row" alignItems="center" paddingTop={4}>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(30 * _.d)}
                    style={styles.clickable}>
                    Last 30d
                  </Tag>
                  <Tag
                    color="blue"
                    onClick={onPressQuickTimeCreator(90 * _.d)}
                    style={styles.clickable}>
                    Last 90d
                  </Tag>
                </Box>
              </Box>
            </Box>

            <Box marginBottom={12}>
              <Spin spinning={isLoadingProps}>
                <QueryBuilderSection
                  entries={entries}
                  groupBy={groupBy}
                  isDisabled={typeof selectedTable === 'undefined'}
                  onChange={onChangeEntries}
                  onPressRun={onPressFetch}
                  setGroupBy={setGroupBy}
                  tablePropsData={tablePropsData}
                />
              </Spin>
            </Box>
            {(isDirty === false || Boolean(err)) && typeof query === 'string' && (
              <Box marginBottom={12} gap={6}>
                <pre
                  style={{
                    fontSize: 10,
                    backgroundColor: 'rgba(0,0,0,0.025)',
                    padding: 8,
                    borderRadius: 12,
                    overflow: 'scroll',
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-word',
                  }}>
                  {query.trim()}
                </pre>
                <Button
                  onClick={() => {
                    dispatch(copyToClipboard(query.trim()))
                    message.success('Copied to clipboard')
                  }}>
                  Copy Query
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

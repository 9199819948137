import { normalize } from '@tellonym/core/helpers'
import { COMMENT_STATUS } from '@tellonym/enums/lib/Comments'
import { REPORT_DECISION } from '@tellonym/enums/lib/Report'
import { TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { POST_STATUS } from '@tellonym/enums/lib/post'
import {
  always,
  assoc,
  evolve,
  findIndex,
  inc,
  lensPath,
  mergeDeepRight,
  over,
  pathEq,
  remove,
  set,
  update,
} from 'ramda'
import { getMergedReducerModules } from '../common/helpers'
import * as banCandidatesReducerModule from './reducerBanCandidates'
import * as t from './types'

export const { name } = t

const defaultInitialState = {
  accountVerifications: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  answers: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  bannedDevices: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  chats: {},
  comments: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  commentsForPost: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  devices: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  followers: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  followings: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  logEntries: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  iapEntries: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  iapRestore: {
    isRefreshing: false,
    hasRestoredItems: false,
  },
  nsfwPictures: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  profiles: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  results: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  usersSharingDevices: [],
  usersSharingDevicesByDeviceUUID: {},
  sentTells: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  tells: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  blacklists: {
    isRefreshing: false,
    data: {
      aboutMe: { ids: [], data: {} },
      socialLink: { ids: [], data: {} },
      tell: { ids: [], data: {} },
    },
  },
  error: null,
  hasSearched: false,
  isLoading: false,
  moderation: [],
  moderationOpenReports: 0,
  hasTakenModerationAction: false,
}

// eslint-disable-next-line no-use-before-define
const getInitialState = () => initialState

const defaultReducer = (state = getInitialState(), action) => {
  const { type, payload, meta } = action

  switch (type) {
    case t.LOGOUT_ALL_DEVICES:
    case t.LOGOUT_DEVICE:
    case t.CHANGE_SETTINGS:
      return {
        ...state,
        isLoading: true,
      }

    case t.LOGOUT_ALL_DEVICES_ERROR:
    case t.LOGOUT_DEVICE_ERROR:
    case t.CHANGE_SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }

    case t.REFRESH_ACCOUNT_VERIFICATIONS:
      return mergeDeepRight(state, {
        accountVerifications: { isRefreshing: true },
      })

    case t.REFRESH_ACCOUNT_VERIFICATIONS_ERROR:
      return mergeDeepRight(state, {
        accountVerifications: { isRefreshing: false },
      })

    case t.REFRESH_ACCOUNT_VERIFICATIONS_SUCCESS: {
      const { data, hasMore } = payload
      const normalized = normalize(data)

      return mergeDeepRight(state, {
        accountVerifications: {
          hasMore,
          isRefreshing: false,
          data: normalized,
        },
      })
    }

    case t.REFRESH_CHAT_MESSAGES: {
      const { issuerUserId, reportedUserId } = payload
      const hasReportedUser = !!state.chats[reportedUserId]
      const hasChatBetweenReportedAndIssuer =
        state.chats[reportedUserId] && state.chats[reportedUserId][issuerUserId]

      return {
        ...state,
        chats: {
          ...state.chats,
          [reportedUserId]: {
            ...(hasReportedUser && state.chats[reportedUserId]),
            [issuerUserId]: {
              ...(hasChatBetweenReportedAndIssuer &&
                state.chats[reportedUserId][issuerUserId]),
              isLoading: true,
            },
          },
        },
      }
    }

    case t.REFRESH_CHAT_MESSAGES_ERROR: {
      const { issuerUserId, reportedUserId } = meta

      return {
        ...state,
        chats: {
          ...state.chats,
          [reportedUserId]: {
            ...state.chats[reportedUserId],
            [issuerUserId]: {
              ...state.chats[reportedUserId][issuerUserId],
              isLoading: false,
              error: payload,
            },
          },
        },
      }
    }

    case t.REFRESH_CHAT_MESSAGES_SUCCESS: {
      const { issuerUserId, reportedUserId } = meta

      return {
        ...state,
        chats: {
          ...state.chats,
          [reportedUserId]: {
            ...state.chats[reportedUserId],
            [issuerUserId]: {
              ...state.chats[reportedUserId][issuerUserId],
              ...payload,
              isLoading: false,
            },
          },
        },
      }
    }

    case t.LOGOUT_ALL_DEVICES_SUCCESS:
    case t.LOGOUT_DEVICE_SUCCESS:
    case t.CHANGE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case t.REFRESH_ANSWERS:
      return {
        ...state,
        answers: {
          ...state.answers,
          isRefreshing: true,
        },
      }

    case t.REFRESH_ANSWERS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.posts
      const normalized = normalize(data)
      return {
        ...state,
        answers: {
          ...state.answers,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.answers.data,
            [userId]: normalized,
          },
        },
      }
    }

    case t.REFRESH_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        answers: {
          ...state.answers,
          isRefreshing: false,
        },
      }

    case t.FETCH_ANSWERS:
      return {
        ...state,
        answers: {
          ...state.answers,
          isFetching: true,
        },
      }

    case t.FETCH_ANSWERS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.posts
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.answers.data[userId].ids, ...normalized.ids]),
      ]
      return {
        ...state,
        answers: {
          ...state.answers,
          hasMore:
            hasMore && ids.length > state.answers.data[userId].ids.length,
          isFetching: false,
          data: {
            ...state.answers.data,
            [userId]: {
              ids,
              data: {
                ...state.answers.data[userId].data,
                ...normalized.data,
              },
            },
          },
        },
      }
    }

    case t.FETCH_ANSWERS_ERROR:
      return {
        ...state,
        error: payload,
        answers: {
          ...state.answers,
          isFetching: false,
        },
      }

    case t.REFRESH_BANNED_DEVICES:
      return {
        ...state,
        bannedDevices: {
          ...state.bannedDevices,
          isRefreshing: true,
        },
      }

    case t.REFRESH_BANNED_DEVICES_ERROR:
      return {
        ...state,
        bannedDevices: {
          ...state.bannedDevices,
          isRefreshing: false,
        },
      }

    case t.REFRESH_BANNED_DEVICES_SUCCESS: {
      const { data, hasMore } = payload.devices
      const normalized = normalize(data)

      return {
        ...state,
        bannedDevices: {
          ...state.bannedDevices,
          data: normalized,
          hasMore,
          isRefreshing: false,
        },
      }
    }

    case t.FETCH_BANNED_DEVICES:
      return {
        ...state,
        bannedDevices: {
          ...state.bannedDevices,
          isFetching: true,
        },
      }

    case t.FETCH_BANNED_DEVICES_ERROR:
      return {
        ...state,
        bannedDevices: {
          ...state.bannedDevices,
          isFetching: false,
        },
      }

    case t.FETCH_BANNED_DEVICES_SUCCESS: {
      const { data, hasMore } = payload.devices
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.bannedDevices.data.ids, ...normalized.ids]),
      ]

      return {
        ...state,
        bannedDevices: {
          ...state.bannedDevices,
          hasMore: hasMore && ids.length > state.bannedDevices.data.ids.length,
          isFetching: false,
          data: {
            ids,
            data: {
              ...state.bannedDevices.data.data,
              ...normalized.data,
            },
          },
        },
      }
    }

    case t.REFRESH_COMMENTS:
      return mergeDeepRight(state, {
        comments: {
          isRefreshing: true,
        },
      })

    case t.REFRESH_COMMENTS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.comments
      const normalized = normalize(data)

      return mergeDeepRight(state, {
        comments: {
          hasMore,
          isRefreshing: false,
          data: {
            [userId]: normalized,
          },
        },
      })
    }

    case t.REFRESH_COMMENTS_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        comments: {
          isRefreshing: false,
        },
      })

    case t.REFRESH_COMMENTS_FOR_POST:
      return mergeDeepRight(state, {
        commentsForPost: {
          isRefreshing: true,
        },
      })

    case t.REFRESH_COMMENTS_FOR_POST_SUCCESS: {
      const { postId } = meta
      const { data, hasMore } = payload.comments
      const normalized = normalize(data)

      return mergeDeepRight(state, {
        commentsForPost: {
          hasMore,
          isRefreshing: false,
          data: {
            [postId]: normalized,
          },
        },
      })
    }

    case t.REFRESH_COMMENTS_FOR_POST_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        commentsForPost: {
          isRefreshing: false,
        },
      })

    case t.FETCH_COMMENTS:
      return mergeDeepRight(state, {
        comments: {
          isFetching: true,
        },
      })

    case t.FETCH_COMMENTS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.comments
      const normalized = normalize(data)

      const ids = [
        ...new Set([...state.comments.data[userId].ids, ...normalized.ids]),
      ]

      return mergeDeepRight(state, {
        comments: {
          hasMore:
            hasMore && ids.length > state.comments.data[userId].ids.length,
          isFetching: false,
          data: {
            [userId]: {
              ids,
              data: normalized.data,
            },
          },
        },
      })
    }

    case t.FETCH_COMMENTS_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        comments: {
          isFetching: false,
        },
      })

    case t.REFRESH_DEVICES:
      return {
        ...state,
        devices: {
          ...state.answers,
          isRefreshing: true,
        },
      }

    case t.REFRESH_DEVICES_SUCCESS: {
      const { userId } = meta
      const normalized = normalize(payload.devices)
      return {
        ...state,
        devices: {
          ...state.devices,
          hasMore: false,
          isRefreshing: false,
          data: {
            ...state.devices.data,
            [userId]: normalized,
          },
        },
      }
    }

    case t.REFRESH_DEVICES_ERROR:
      return {
        ...state,
        error: payload,
        devices: {
          ...state.devices,
          isRefreshing: false,
        },
      }

    case t.REFRESH_USERS_SHARING_DEVICES_SUCCESS:
      if (action.meta.payload?.deviceUUID) {
        return mergeDeepRight(state, {
          usersSharingDevicesByDeviceUUID: {
            [action.meta.payload.deviceUUID]: action.payload.users.data,
          },
        })
      }

      return mergeDeepRight(state, {
        usersSharingDevices: action.payload.users.data,
      })

    case t.REFRESH_NSFW_PICTURES:
      return mergeDeepRight(state, { nsfwPictures: { isRefreshing: true } })

    case t.REFRESH_NSFW_PICTURES_ERROR:
      return mergeDeepRight(state, { nsfwPictures: { isRefreshing: false } })

    case t.REFRESH_NSFW_PICTURES_SUCCESS: {
      const { data, hasMore } = payload.pictures
      const normalized = normalize(data)

      return mergeDeepRight(state, {
        nsfwPictures: { hasMore, isRefreshing: false, data: normalized },
      })
    }

    case t.REFRESH_PROFILE:
      return {
        ...state,
        results: {
          ...state.results,
          isRefreshing: true,
        },
      }

    case t.DELETE_PROFILE_PIC_SUCCESS: {
      const { userId, position } = meta

      if (typeof state.results?.data?.[userId]?.avatars === 'undefined') {
        return state
      }

      return {
        ...state,
        results: {
          ...state.results,
          isRefreshing: false,
          data: {
            ...state.results.data,
            [userId]: {
              ...state.results.data[userId],
              avatars: {
                ...state.results.data[userId].avatars,
                data: state.results.data[userId].avatars.data.filter(
                  (avatar) => avatar.position !== position
                ),
              },
            },
          },
        },
      }
    }

    case t.REFRESH_PROFILE_SUCCESS: {
      const { isModeration } = meta
      const profileId = payload.id
      const results = {
        ...state.results,
        isRefreshing: false,
        data: {
          ...state.results.data,
          [profileId]: {
            ...payload,
            phoneNumberCombined:
              payload.phonePrefix + payload.phoneNumber || '',
          },
        },
      }

      if (isModeration) {
        const modItemIndex = findIndex(
          pathEq(['user', 'id'], profileId),
          state.moderation
        )

        if (!modItemIndex) {
          return { ...state, results }
        }

        const modItem = state.moderation[modItemIndex]
        const newModItem = assoc('user', payload, modItem)
        const moderation = update(modItemIndex, newModItem, state.moderation)

        return { ...state, moderation, results }
      }

      return { ...state, results }
    }

    case t.REFRESH_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        results: {
          ...state.results,
          isRefreshing: false,
        },
      }

    case t.REFRESH_TELLS:
      return {
        ...state,
        tells: {
          ...state.tells,
          isRefreshing: true,
        },
      }

    case t.REFRESH_TELLS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.tells
      const normalized = normalize(data)
      return {
        ...state,
        tells: {
          ...state.tells,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.tells.data,
            [userId]: normalized,
          },
        },
      }
    }

    case t.REFRESH_TELLS_ERROR:
      return {
        ...state,
        error: payload,
        tells: {
          ...state.tells,
          isRefreshing: false,
        },
      }

    case t.FETCH_TELLS:
      return {
        ...state,
        tells: {
          ...state.tells,
          isFetching: true,
        },
      }

    case t.FETCH_TELLS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.tells
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.tells.data[userId].ids, ...normalized.ids]),
      ]
      return {
        ...state,
        tells: {
          ...state.tells,
          hasMore: hasMore && ids.length > state.tells.data[userId].ids.length,
          isFetching: false,
          data: {
            ...state.tells.data,
            [userId]: {
              ids,
              data: {
                ...state.tells.data[userId].data,
                ...normalized.data,
              },
            },
          },
        },
      }
    }

    case t.FETCH_TELLS_ERROR:
      return {
        ...state,
        error: payload,
        tells: {
          ...state.tells,
          isFetching: false,
        },
      }

    case t.REFRESH_SENT_TELLS:
      return {
        ...state,
        sentTells: {
          ...state.sentTells,
          isRefreshing: true,
        },
      }

    case t.REFRESH_SENT_TELLS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.tells
      const normalized = normalize(data)
      return {
        ...state,
        sentTells: {
          ...state.sentTells,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.sentTells.data,
            [userId]: normalized,
          },
        },
      }
    }

    case t.REFRESH_SENT_TELLS_ERROR:
      return {
        ...state,
        error: payload,
        sentTells: {
          ...state.sentTells,
          isRefreshing: false,
        },
      }

    case t.FETCH_SENT_TELLS:
      return {
        ...state,
        sentTells: {
          ...state.sentTells,
          isFetching: true,
        },
      }

    case t.FETCH_SENT_TELLS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.tells
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.sentTells.data[userId].ids, ...normalized.ids]),
      ]
      return {
        ...state,
        sentTells: {
          ...state.sentTells,
          hasMore:
            hasMore && ids.length > state.sentTells.data[userId].ids.length,
          isFetching: false,
          data: {
            ...state.sentTells.data,
            [userId]: {
              ids,
              data: {
                ...state.sentTells.data[userId].data,
                ...normalized.data,
              },
            },
          },
        },
      }
    }

    case t.FETCH_SENT_TELLS_ERROR:
      return {
        ...state,
        error: payload,
        sentTells: {
          ...state.sentTells,
          isFetching: false,
        },
      }

    case t.REFRESH_USER_LOG:
      return mergeDeepRight(state, {
        logEntries: {
          isRefreshing: true,
        },
      })

    case t.REFRESH_USER_LOG_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        logEntries: {
          isRefreshing: false,
        },
      })

    case t.REFRESH_USER_LOG_SUCCESS: {
      const { id } = meta
      const { hasMore, data } = payload.logEntries
      const normalized = normalize(data)

      return mergeDeepRight(state, {
        logEntries: {
          hasMore,
          isRefreshing: false,
          data: {
            [id]: normalized,
          },
        },
      })
    }

    case t.FETCH_USER_LOG:
      return mergeDeepRight(state, {
        logEntries: {
          isFetching: true,
        },
      })

    case t.FETCH_USER_LOG_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        logEntries: {
          isFetching: false,
        },
      })

    case t.FETCH_USER_LOG_SUCCESS: {
      const { id } = meta
      const { data, hasMore } = payload.logEntries
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.logEntries.data[id].ids, ...normalized.ids]),
      ]

      return mergeDeepRight(state, {
        logEntries: {
          hasMore: hasMore && ids.length > state.logEntries.data[id].ids.length,
          isFetching: false,
          data: {
            [id]: {
              ids,
              data: normalized.data,
            },
          },
        },
      })
    }

    case t.REFRESH_IAP:
      return mergeDeepRight(state, {
        iapEntries: {
          isRefreshing: true,
        },
      })

    case t.REFRESH_IAP_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        iapEntries: {
          isRefreshing: false,
        },
      })

    case t.REFRESH_IAP_SUCCESS: {
      const { id } = meta
      const { hasMore, data } = payload.iapEntries
      const normalized = normalize(data)

      return mergeDeepRight(state, {
        iapEntries: {
          hasMore,
          isRefreshing: false,
          data: {
            [id]: normalized,
          },
        },
      })
    }

    case t.FETCH_IAP:
      return mergeDeepRight(state, {
        iapEntries: {
          isFetching: true,
        },
      })

    case t.FETCH_IAP_ERROR:
      return mergeDeepRight(state, {
        error: payload,
        iapEntries: {
          isFetching: false,
        },
      })

    case t.FETCH_IAP_SUCCESS: {
      const { id } = meta
      const { data, hasMore } = payload.iapEntries
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.iapEntries.data[id].ids, ...normalized.ids]),
      ]

      return mergeDeepRight(state, {
        iapEntries: {
          hasMore: hasMore && ids.length > state.iapEntries.data[id].ids.length,
          isFetching: false,
          data: {
            [id]: {
              ids,
              data: normalized.data,
            },
          },
        },
      })
    }

    case t.RESTORE_IAP:
      return mergeDeepRight(state, {
        iapRestore: {
          isRefreshing: true,
        },
      })

    case t.RESTORE_IAP_ERROR:
      return mergeDeepRight(state, {
        iapRestore: {
          isRefreshing: false,
          hasRestoredItems: false,
        },
      })

    case t.RESTORE_IAP_SUCCESS:
      return mergeDeepRight(state, {
        iapRestore: {
          isRefreshing: false,
          hasRestoredItems: !!payload.didRestoreItems,
        },
      })

    case t.RESTORE_ANSWER_SUCCESS:
      return mergeDeepRight(state, {
        answers: {
          _rerenderItem: {
            ids: [meta.id],
            count: state.answers._rerenderItem.count + 1,
          },
          data: {
            [meta._userId]: {
              data: {
                [meta.id]: {
                  postStatus: POST_STATUS.ACTIVE,
                },
              },
            },
          },
        },
      })

    case t.BLOCK_ANSWER_SUCCESS:
    case t.BLOCK_POST_SUCCESS:
      return mergeDeepRight(state, {
        answers: {
          _rerenderItem: {
            ids: [meta.id],
            count: state.answers._rerenderItem.count + 1,
          },
          data: {
            [meta._userId]: {
              data: {
                [meta.id]: {
                  postStatus: POST_STATUS.BLOCKED,
                },
              },
            },
          },
        },
      })

    case t.RESTORE_TELL_SUCCESS:
      return mergeDeepRight(state, {
        [meta._type]: {
          _rerenderItem: {
            ids: [meta.id],
            count: state[meta._type]._rerenderItem.count + 1,
          },
          data: {
            [meta._userId]: {
              data: {
                [meta.id]: {
                  tellStatus: TELL_STATUS.ACTIVE,
                },
              },
            },
          },
        },
      })

    case t.BLOCK_TELL_SUCCESS:
      if (!meta._type) {
        return state
      }

      return mergeDeepRight(state, {
        [meta._type]: {
          _rerenderItem: {
            ids: [meta.id],
            count: state[meta._type]._rerenderItem.count + 1,
          },
          data: {
            [meta._userId]: {
              data: {
                [meta.id]: {
                  tellStatus: TELL_STATUS.BLOCKED,
                },
              },
            },
          },
        },
      })

    case t.DELETE_PUBLIC_LINK_SUCCESS:
      return evolve(
        {
          results: {
            _rerenderItem: { ids: always(meta.userId), count: inc },
            data: {
              [meta.userId]: {
                linkData: {
                  data: remove(meta.linkType, 1),
                },
              },
            },
          },
        },
        state
      )

    case t.SEARCH:
      return {
        ...state,
        hasSearched: false,
        profiles: {
          ...state.profiles,
          isRefreshing: true,
        },
      }

    case t.SEARCH_SUCCESS: {
      const { data, hasMore } = payload
      const normalized = normalize(data)

      return {
        ...state,
        hasSearched: true,
        profiles: {
          ...state.profiles,
          hasMore,
          isRefreshing: false,
          data: normalized,
        },
      }
    }

    case t.SEARCH_ERROR:
      return {
        ...state,
        error: payload,
        profiles: {
          ...state.profiles,
          isRefreshing: false,
        },
      }

    case t.SET_HAS_TAKEN_MODERATION_ACTION:
      return {
        ...state,
        hasTakenModerationAction: payload,
      }

    case t.SET_NSFW_PICTURE_STATUS: {
      const picture = state.nsfwPictures.data.data[payload.pictureId]

      return mergeDeepRight(state, {
        nsfwPictures: {
          _rerenderItem: {
            ids: [picture.id],
            count: state.nsfwPictures._rerenderItem.count + 1,
          },
          data: {
            data: {
              [picture.id]: {
                _decision:
                  picture._decision === REPORT_DECISION.NOT_OKAY
                    ? REPORT_DECISION.SKIP
                    : picture._decision === REPORT_DECISION.SKIP
                    ? REPORT_DECISION.OKAY
                    : REPORT_DECISION.NOT_OKAY,
              },
            },
          },
        },
      })
    }

    case t.CLEAR_SELECTION: {
      const { type, userId } = payload

      const itemLens = lensPath([type, 'data', userId])

      const removeSelection = (items) =>
        items.ids.reduce(
          (acc, id) =>
            mergeDeepRight(acc, { data: { [id]: { _isSelected: false } } }),
          items
        )

      return over(itemLens, removeSelection, state)
    }

    case t.SET_SELECTION: {
      const { tellId, type, userId, isSelected } = payload

      const isSelectedLens = lensPath([
        type,
        'data',
        userId,
        'data',
        tellId,
        '_isSelected',
      ])

      return set(isSelectedLens, isSelected, state)
    }

    case t.SELECT_ALL: {
      const { type, userId } = payload

      const itemLens = lensPath([type, 'data', userId])

      const addSelection = (items) =>
        items.ids.reduce(
          (acc, id) =>
            mergeDeepRight(acc, { data: { [id]: { _isSelected: true } } }),
          items
        )

      return over(itemLens, addSelection, state)
    }

    case t.UPDATE_NSFW_PICTURES:
      return mergeDeepRight(state, { nsfwPictures: { isRefreshing: true } })

    case t.UPDATE_NSFW_PICTURES_ERROR:
      return mergeDeepRight(state, { nsfwPictures: { isRefreshing: false } })

    case t.UPDATE_NSFW_PICTURES_SUCCESS:
      return assoc('nsfwPictures', defaultInitialState.nsfwPictures, state)

    case t.ADD_BLACKLIST_ENTRY:
      return {
        ...state,
        blacklists: {
          ...state.blacklists,
          isRefreshing: true,
        },
      }

    case t.ADD_BLACKLIST_ENTRY_ERROR:
      return {
        ...state,
        error: payload,
        blacklists: {
          ...state.blacklists,
          isRefreshing: false,
        },
      }

    case t.ADD_BLACKLIST_ENTRY_SUCCESS:
      return {
        ...state,
        blacklists: {
          ...state.blacklists,
          isRefreshing: false,
        },
      }

    case t.REFRESH_BLACKLISTS:
      return {
        ...state,
        blacklists: {
          ...state.blacklists,
          isRefreshing: true,
        },
      }
    case t.REFRESH_BLACKLISTS_SUCCESS: {
      const normalizeBlacklists = (array) =>
        array.reduce(
          (acc, val, index) => {
            return {
              data: { ...acc.data, [index]: val },
              ids: [...acc.ids, index],
            }
          },
          { data: {}, ids: [] }
        )

      return {
        ...state,
        blacklists: {
          ...state.blacklists,
          isRefreshing: false,
          data: {
            ...state.blacklists.data,
            aboutMe: normalizeBlacklists(payload.aboutMe.data),
            socialLink: normalizeBlacklists(payload.socialLink.data),
            tell: normalizeBlacklists(payload.tell.data),
          },
        },
      }
    }
    case t.REFRESH_BLACKLISTS_ERROR:
      return {
        ...state,
        error: payload,
        blacklists: {
          ...state.blacklists,
          isRefreshing: false,
        },
      }

    case t.REFRESH_FOLLOWERS:
      return {
        ...state,
        followers: {
          ...state.followers,
          isRefreshing: true,
        },
      }
    case t.REFRESH_FOLLOWERS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.followers
      const normalized = normalize(data)
      return {
        ...state,
        followers: {
          ...state.followers,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.followers.data,
            [userId]: normalized,
          },
        },
      }
    }
    case t.REFRESH_FOLLOWERS_ERROR:
      return {
        ...state,
        error: payload,
        followers: {
          ...state.followers,
          isRefreshing: false,
        },
      }

    case t.FETCH_FOLLOWERS:
      return {
        ...state,
        followers: {
          ...state.followers,
          isFetching: true,
        },
      }
    case t.FETCH_FOLLOWERS_ERROR:
      return {
        ...state,
        error: payload,
        followers: {
          ...state.followers,
          isFetching: false,
        },
      }
    case t.FETCH_FOLLOWERS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.followers
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.followers.data[userId].ids, ...normalized.ids]),
      ]

      return mergeDeepRight(state, {
        followers: {
          hasMore:
            hasMore && ids.length > state.followers.data[userId].ids.length,
          isFetching: false,
          data: {
            [userId]: {
              ids,
              data: normalized.data,
            },
          },
        },
      })
    }

    case t.REFRESH_FOLLOWINGS:
      return {
        ...state,
        followings: {
          ...state.followings,
          isRefreshing: true,
        },
      }
    case t.REFRESH_FOLLOWINGS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.followings
      const normalized = normalize(data)
      return {
        ...state,
        followings: {
          ...state.followings,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.followings.data,
            [userId]: normalized,
          },
        },
      }
    }
    case t.REFRESH_FOLLOWINGS_ERROR:
      return {
        ...state,
        error: payload,
        followings: {
          ...state.followings,
          isRefreshing: false,
        },
      }

    case t.FETCH_FOLLOWINGS:
      return {
        ...state,
        followings: {
          ...state.followings,
          isFetching: true,
        },
      }
    case t.FETCH_FOLLOWINGS_ERROR:
      return {
        ...state,
        error: payload,
        followings: {
          ...state.followings,
          isFetching: false,
        },
      }
    case t.FETCH_FOLLOWINGS_SUCCESS: {
      const { userId } = meta
      const { data, hasMore } = payload.followings
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.followings.data[userId].ids, ...normalized.ids]),
      ]

      return mergeDeepRight(state, {
        followings: {
          hasMore:
            hasMore && ids.length > state.followings.data[userId].ids.length,
          isFetching: false,
          data: {
            [userId]: {
              ids,
              data: normalized.data,
            },
          },
        },
      })
    }

    case t.BLOCK_COMMENT_SUCCESS:
      return mergeDeepRight(state, {
        comments: {
          _rerenderItem: {
            ids: [meta.payload.id],
            count: state.comments._rerenderItem.count + 1,
          },
          data: {
            [meta.payload.userId]: {
              data: {
                [meta.payload.id]: {
                  status: COMMENT_STATUS.BLOCKED,
                },
              },
            },
          },
        },
      })

    case t.RESTORE_COMMENT_SUCCESS:
      return mergeDeepRight(state, {
        comments: {
          _rerenderItem: {
            ids: [meta.payload.id],
            count: state.comments._rerenderItem.count + 1,
          },
          data: {
            [meta.payload.userId]: {
              data: {
                [meta.payload.id]: {
                  status: COMMENT_STATUS.ACTIVE,
                },
              },
            },
          },
        },
      })

    default:
      return state
  }
}

const defaultReducerModule = {
  initialState: defaultInitialState,
  reducer: defaultReducer,
}

export const { initialState, reducer } = getMergedReducerModules(
  defaultReducerModule,
  [banCandidatesReducerModule]
)

export const dateTypes = {
  LAST_21_DAYS: 'LAST_21_DAYS',
  LAST_365_DAYS: 'LAST_365_DAYS',
  LAST_180_DAYS: 'LAST_180_DAYS',
  LAST_90_DAYS: 'LAST_90_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_14_DAYS: 'LAST_14_DAYS',
  LAST_7_DAYS: 'LAST_7_DAYS',
  YESTERDAY: 'YESTERDAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  CUSTOM_DATE: 'CUSTOM_DATE',
  LAST_3_WEEKS: 'LAST_3_WEEKS',
  LAST_12_WEEKS: 'LAST_12_WEEKS',
  LAST_24_WEEKS: 'LAST_24_WEEKS',
  LAST_53_WEEKS: 'LAST_53_WEEKS',
  SYNCED: 'SYNCED',
}

export const dateTypeDefaults = [
  dateTypes.LAST_21_DAYS,
  dateTypes.LAST_365_DAYS,
  dateTypes.LAST_180_DAYS,
  dateTypes.LAST_90_DAYS,
  dateTypes.LAST_30_DAYS,
  dateTypes.LAST_14_DAYS,
  dateTypes.LAST_7_DAYS,
  dateTypes.YESTERDAY,
  dateTypes.LAST_WEEK,
  dateTypes.LAST_MONTH,
  dateTypes.CUSTOM_DATE,
]

export const weekTypeDefaults = [
  dateTypes.LAST_3_WEEKS,
  dateTypes.LAST_12_WEEKS,
  dateTypes.LAST_24_WEEKS,
  dateTypes.LAST_53_WEEKS,
  dateTypes.CUSTOM_DATE,
]

export const defaultRowKeys = [
  'percentageOfUsers',
  'amountPerUnique',
  'amountUniques',
  'amountActions',
  'amountPerUser',
  'amountUsers',
]

const comparisonInterval = ['oneWeek', 'oneMonth', 'threeMonths', 'sixMonths']
export const comparisonIntervalOptions = comparisonInterval.map((v) => ({
  label: v
    .replace(/^one/, '1 ')
    .replace(/^three/, '3 ')
    .replace(/^six/, '6 '),
  value: v,
}))

export const statsTypes = {
  'ALL-EXP-MAIN': 'ALL-EXP-MAIN',
  'SIGNIFICANT-EXP-MAIN': 'SIGNIFICANT-EXP-MAIN',
  EXP: 'EXP',
  'ALL-MAIN': 'ALL-MAIN',
  ALL: 'ALL',
  SIGNIFICANT: 'SIGNIFICANT',
  'SIGNIFICANT-MAIN': 'SIGNIFICANT-MAIN',
  DAILY_UPDATE: 'DAILY_UPDATE',
}

export const shortnameOptions = {
  MAIN: 'All Main Shortnames',
  ACT: 'All Activation Shortnames',
  ALL: 'All Shortnames',
  'NU-OV': 'Activation Overview',
  ART: 'Artificial Tells Overview',
  IAP: 'IAP / Purchase Shortnames',
}

export const shortnameDataOptions = {
  SIGNIFICANT: 'Only show significant changes',
}

import { DeleteOutlined } from '@ant-design/icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import { COMMENT_STATUS } from '@tellonym/enums/lib/Comments'
import {
  REPORT_DECISION,
  REASON as REPORT_REASON,
  REPORT_TYPE,
} from '@tellonym/enums/lib/Report'
import { SENDER_STATUS } from '@tellonym/enums/lib/Tell'
import { USER_LOG_TYPE } from '@tellonym/enums/lib/User'
import {
  Button as AntdButton,
  Badge,
  Modal,
  Radio,
  Select,
  Spin,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import {
  Avatar,
  Box,
  Icon,
  Image,
  Link,
  PostImage,
  ScrollView,
  Text,
  TextTranslatable,
  View,
  history,
  hooks,
  styleSheets,
  theme,
} from '../../common'
import { AvatarGroup } from '../../common/components/AvatarGroup'
import { IconVerified } from '../../common/components/IconVerified'
import { convertObjectToOptions } from '../../common/helpers'
import { useProfileSettingsQuery } from '../../profile/queries'
import {
  blockAnswer,
  blockTell,
  changeSettings,
  createQuick7DayBan,
  deleteProfilePic,
  removePostComment,
  setHasTakenModerationAction,
} from '../actions'
import {
  useCommunityTellReportsQuery,
  usePostReportsQuery,
  useProfileReportsQuery,
  useQuickBanSexualMutation,
  useQuickBanSexualPermaMutation,
  useUserLogQuery,
  useVoteReportMutation,
} from '../queries'
import { getHasTakenModerationAction } from '../selectors'
import { AccountBansByDevice, PastBans } from './BanStats'
import { ModalActionDescriptions } from './ModalActionDescriptions'
import { ModalChatMessages } from './ModalChatMessages'
import { ModalCreateBan } from './ModalCreateBan'
import { ModalCreateWarning } from './ModalCreateWarning'
import { ModalFollowers } from './ModalFollowers'
import { ModalFollowings } from './ModalFollowings'
import { PageProfile } from './PageProfile'
import { ShadowBanButtons } from './ShadowBanButtons'
import { UserTypeBadge } from './UserTypeBadge'

const styles = {
  commentContainer: {
    maxWidth: 697,
    flexDirection: 'row',
    alignItems: 'center',
  },
  comment: {
    borderLeftColor: theme.colors.border,
    borderLeftStyle: 'solid',
    borderLeftWidth: 0.5,
    paddingLeft: 4,
    marginBottom: 4,
    width: '100%',
  },
  quickBanSexual: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 24,
    marginLeft: 4,
    marginRight: 4,
    backgroundColor: '#D85E40',
    cursor: 'pointer',
  },
  bottomMargin: { marginBottom: 8 },
}

const CommentsList = ({ comments, reportedUser }) => {
  const onPressShowChat = (userId) => (e) => {
    e.preventDefault()

    ModalChatMessages.show({
      issuerUserId: userId,
      reportedUserId: reportedUser.id,
    })
  }

  return (
    <View>
      {comments.map((comment, _index, _fullArray) => {
        const { textReason, isChat = false, ip, userId } = comment

        return (
          <View key={textReason} style={styles.commentContainer}>
            <View style={styles.comment}>
              <Box
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                {userId ? (
                  <Link to={`/user/${userId}`}>
                    <Text color={theme.colors.primary}>{userId}</Text>
                  </Link>
                ) : (
                  <Text>{ip}</Text>
                )}
                {isChat && (
                  <AntdButton type="link" onClick={onPressShowChat(userId)}>
                    Show Chat
                  </AntdButton>
                )}
              </Box>
              <TextTranslatable>{textReason}</TextTranslatable>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const PostComment = ({
  item,
  isMarkedForDeletion,
  selectedId,
  setMarkedForDeletion,
  setSelectedId,
}) => {
  const deleteComment = React.useCallback(() => {
    setMarkedForDeletion(item.id)
  }, [item.id, setMarkedForDeletion])

  const onMouseEnter = React.useCallback(() => {
    setSelectedId(item.id)
  }, [item.id, setSelectedId])

  const onMouseLeave = React.useCallback(() => {
    setSelectedId(undefined)
  }, [setSelectedId])

  const onPressUsername = React.useCallback(() => {
    history.push(`/user/${item.user?.id}?stage=Settings`)
  }, [item.user])

  const isSelected = React.useMemo(
    () => selectedId === item.id,
    [item.id, selectedId]
  )

  const isDeleted = item.status === COMMENT_STATUS.DELETED
  const hasDeletedState = isMarkedForDeletion || isDeleted

  const backgroundColor = (() => {
    switch (true) {
      case isDeleted:
        return colors.grey[3]
      case isSelected:
        return item.isReported ? colors.red[1] : colors.grey[2]
      case item.isReported:
        return colors.red[4]
      case isMarkedForDeletion:
        return colors.grey[3]
      default:
        return undefined
    }
  })()

  return (
    <Box
      key={item.id}
      backgroundColor={backgroundColor}
      flexDirection="row"
      justifyContent="space-between"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      padding={8}>
      <Box flexDirection="row" flex={1} transparent>
        <Text
          onPress={onPressUsername}
          style={{
            fontSize: 14,
            fontWeight: '700',
            marginRight: 8,
            marginTop: 2,
            whiteSpace: 'nowrap',
            ...(hasDeletedState && {
              color: colors.grey[7],
            }),
          }}>
          {item.user?.username}
        </Text>
        <TextTranslatable
          style={{
            fontSize: 16,
            fontWeight: '400',
          }}
          textStyle={{
            ...(hasDeletedState && {
              color: colors.grey[7],
            }),
          }}>
          {item.content}
        </TextTranslatable>
        {hasDeletedState && (
          <Text style={{ whiteSpace: 'nowrap', color: colors.grey[7] }}>
            (deleted)
          </Text>
        )}
      </Box>

      {!isDeleted && (
        <Box
          alignItems="center"
          marginLeft={8}
          height={28}
          width={28}
          padding={4}
          disabled={!isSelected}
          onPress={deleteComment}
          transparent>
          <Icon
            icon={faTrashAlt}
            size="lg"
            style={{
              color: isSelected
                ? item.isReported
                  ? colors.white[1]
                  : colors.black[1]
                : 'transparent',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const TellPostItem = ({ item, state, setState }) => {
  const getThemedColor = hooks.useThemedColor()

  const isCommentReport = item.type === REPORT_TYPE.POST_COMMENT

  const setCommentIdMarkedForDeletion = (commentId) => {
    setState((state) => ({
      ...state,
      commentIdsMarkedForDeletionMap: {
        ...state.commentIdsMarkedForDeletionMap,
        [commentId]: !state.commentIdsMarkedForDeletionMap[commentId],
      },
    }))
  }

  const [selectedId, setSelectedId] = React.useState(null)

  const deleteAnswer = () => {
    setState((state) => ({
      ...state,
      isAnswerMarkedForDeletion: !state.isAnswerMarkedForDeletion,
    }))
  }

  const textColor = state.isAnswerMarkedForDeletion
    ? getThemedColor(colors.grey[6], colors.grey[5])
    : getThemedColor(colors.black[1], colors.white[1])

  return (
    <Box
      transparent
      backgroundColor={getThemedColor(colors.white[1], colors.black[5])}
      borderWidth={1}
      borderColor={getThemedColor('#F0F0F3', colors.black[1])}
      borderRadius={20}
      borderStyle="solid"
      padding={32}
      marginTop={4}>
      {typeof item.tell.answer === 'string' ||
      (item.tell.media && item.tell.media.length) ? (
        <View>
          <Box
            transparent
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            paddingBottom={8}
            alignItems="center">
            <Box transparent flexDirection="row">
              <TextLabel.BoxItemMeta
                label="Sender"
                labelContainerStyle={{ marginBottom: undefined }}>
                {item.tell.sender?.id ? (
                  <Link
                    to={`/user/${item.tell.sender.id}`}
                    style={{ display: 'inline' }}>
                    @{item.tell.sender.username}
                  </Link>
                ) : (
                  `${
                    item.tell.sender?.ip ??
                    `${item.tell.community?.name} (${item.tell.community?.id})`
                  }`
                )}
              </TextLabel.BoxItemMeta>
              <TextLabel.BoxItemMeta
                label="Sender Status"
                labelContainerStyle={{ marginBottom: undefined }}>
                {SENDER_STATUS[item.tell.senderStatus] ||
                  item.tell.senderStatus}
              </TextLabel.BoxItemMeta>
            </Box>

            {item.postComments?.length > 0 ? (
              <Box
                borderWidth={1}
                borderColor={
                  state.isAnswerMarkedForDeletion ? textColor : colors.red[1]
                }
                borderRadius={24}
                borderStyle="solid"
                paddingHorizontal={9}
                paddingVertical={8}
                onPress={deleteAnswer}>
                <Icon
                  icon={faTrashAlt}
                  color={
                    state.isAnswerMarkedForDeletion ? textColor : colors.red[1]
                  }
                  size={24}
                />
              </Box>
            ) : (
              <Box />
            )}
          </Box>

          <BoxBlue>
            <TextLabel.BoxItemMeta
              label="Receiver"
              labelStyle={{
                color: textColor,
              }}>
              <Link
                to={`/user/${item.tell.receiver.id}`}
                style={{
                  display: 'inline',
                  color: textColor,
                }}>
                @{item.tell.receiver.username}
              </Link>
            </TextLabel.BoxItemMeta>

            <TextTell
              textStyle={{
                color: textColor,
              }}>
              {item.tell.tell}
            </TextTell>

            <TextTranslatable
              style={{
                marginTop: 8,
              }}
              textStyle={{
                color: textColor,
              }}>
              {item.tell.answer}
            </TextTranslatable>

            {item.tell.media && item.tell.media.length ? (
              <Link to={item.tell.media[0].url} target="_blank">
                <PostImage
                  imgUrl={item.tell.media[0].thumbUrl || item.tell.media[0].url}
                />
              </Link>
            ) : null}
          </BoxBlue>

          <Box transparent flexDirection="row" flexWrap="wrap">
            <TextLabel.BoxItemMeta
              label="Post ID"
              labelStyle={{ fontWeight: 400, fontStyle: 'italic' }}>
              <Link
                style={{ display: 'inline' }}
                to={`https://tellonym.me/${item.tell.receiver.username}/answer/${item.postId}`}
                target="_blank">
                {item.postId}
              </Link>
            </TextLabel.BoxItemMeta>
            <TextLabel.BoxItemMeta
              label="Report ID"
              labelStyle={{ fontWeight: 400, fontStyle: 'italic' }}
              textStyle={{ color: colors.black[1] }}>
              {item.id}
            </TextLabel.BoxItemMeta>
          </Box>

          {isCommentReport && (
            <Box>
              <Box alignItems="flex-end">
                <TextLabel.BoxItemMeta
                  label="Reported Comments"
                  textStyle={{
                    color:
                      item.postCommentReportsAmount > 0
                        ? colors.red[1]
                        : colors.black[1],
                  }}>
                  {item.postCommentReportsAmount ?? 0}
                </TextLabel.BoxItemMeta>
              </Box>
              <Box height={1} backgroundColor={colors.grey[7]} />
              <ScrollView
                style={{
                  marginTop: 24,
                  maxHeight: 450,
                }}>
                {item.postComments?.map((comment) => (
                  <PostComment
                    key={comment.id}
                    item={comment}
                    isMarkedForDeletion={
                      state.commentIdsMarkedForDeletionMap[comment.id] ?? false
                    }
                    setMarkedForDeletion={setCommentIdMarkedForDeletion}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                  />
                ))}
              </ScrollView>
            </Box>
          )}
        </View>
      ) : (
        <Text style={{ whiteSpace: 'pre-line' }}>{item.tell.tell}</Text>
      )}
    </Box>
  )
}

const TextLabel = ({ label, children, labelStyle, style, textStyle }) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Box marginBottom={8} transparent {...style}>
      <Text style={labelStyle}>
        {`${label}: `}
        <Text
          color={getThemedColor(colors.grey[7], colors.grey[1])}
          style={textStyle}>
          {children}
        </Text>
      </Text>
    </Box>
  )
}

TextLabel.BoxItemMeta = ({
  label,
  children,
  labelStyle,
  labelContainerStyle,
  style,
  textStyle,
}) => (
  <Box marginRight={8} transparent {...style}>
    <TextLabel
      label={label}
      style={labelContainerStyle}
      labelStyle={{ fontWeight: '700', fontSize: 12, ...labelStyle }}
      textStyle={{
        fontSize: 12,
        fontStyle: 'italic',
        fontWeight: '400',
        ...textStyle,
      }}>
      {children}
    </TextLabel>
  </Box>
)

const BoxBlue = ({ children }) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Box
      backgroundColor={getThemedColor(colors.saphire[1], colors.navy[3])}
      borderRadius={10}
      paddingVertical={8}
      paddingLeft={32}
      paddingRight={8}
      marginBottom={24}>
      {children}
    </Box>
  )
}

const TextTell = ({ children, textStyle, ...props }) => (
  <Box
    backgroundColor="transparent"
    borderLeftWidth={3}
    borderRightWidth={0}
    borderTopWidth={0}
    borderBottomWidth={0}
    borderColor={colors.saphire[6]}
    borderStyle="solid"
    paddingLeft={8}
    {...props}>
    <TextTranslatable textStyle={textStyle}>{children}</TextTranslatable>
  </Box>
)

const CommunityTellItem = ({ item }) => {
  return (
    <Box
      padding={32}
      borderRadius={20}
      borderWidth={1}
      borderColor="#F0F0F3"
      borderStyle="solid"
      maxWidth={532}>
      <Box flexDirection="row" flexWrap="wrap">
        <TextLabel.BoxItemMeta label="Community ID">
          {item.community.id}
        </TextLabel.BoxItemMeta>
        <TextLabel.BoxItemMeta label="Members">
          {shortenNumber(item.community.membersCount)}
        </TextLabel.BoxItemMeta>
        <TextLabel.BoxItemMeta label="Tell Answers">
          {shortenNumber(item.community.tellAnswersCount)}
        </TextLabel.BoxItemMeta>
        <TextLabel.BoxItemMeta label="Sender">
          {item.communityTell.sender.username}
        </TextLabel.BoxItemMeta>
      </Box>
      <BoxBlue>
        <TextTell>{item.communityTell.tell}</TextTell>
      </BoxBlue>
      <Text
        type="note"
        style={{
          fontStyle: 'italic',
        }}>{`Community Tell ID: ${item.communityTell.id}, Report ID: ${item.id}`}</Text>
    </Box>
  )
}

const OpenReportsLabel = ({
  value,
  textColor,
  borderColor,
  shouldAlignRight,
  style,
}) => (
  <Box
    paddingVertical={4}
    paddingHorizontal={8}
    borderWidth={1}
    borderColor={borderColor ?? '#F0F0F3'}
    borderStyle="solid"
    borderRadius={6}
    marginLeft={shouldAlignRight ? 'auto' : 11}
    {...style}>
    <Text
      type="small"
      color={textColor ?? colors.grey[7]}
      style={styles.metaText}>
      {value}
    </Text>
  </Box>
)

const ProfileStatsItem = ({ name, value, onPress }) => (
  <Box
    onPress={onPress}
    flex={1}
    flexDirection="row"
    justifyContent="space-between">
    <Text>{name}</Text>
    <Text bold>{shortenNumber(value)}</Text>
  </Box>
)

const PIC_DELETE_TYPES = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
  ALL: 'All Avatars',
}

const getPositionNumberFromString = (string) => {
  switch (string) {
    case PIC_DELETE_TYPES.LEFT:
      return [1]

    case PIC_DELETE_TYPES.CENTER:
      return [0]

    case PIC_DELETE_TYPES.RIGHT:
      return [2]

    case PIC_DELETE_TYPES.ALL:
      return [0, 1, 2]

    default:
      return []
  }
}

const MAIN_SECTION_LEFT_WIDTH = 290
const MAIN_SECTION_RIGHT_WIDTH = 160

const modalAvatarCreator = (avatarFileName) => () =>
  Modal.info({
    closable: true,
    maskClosable: true,
    width: '50%',
    content: (
      <View style={{ alignItems: 'center' }}>
        <Avatar
          rectangle
          borderColor={theme.colors.black}
          user={{ avatarFileName }}
          size={380}
        />
      </View>
    ),
  })

const ProfileWithActions = ({ report, profile }) => {
  const dispatch = ReactRedux.useDispatch()

  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const accessToken = ReactRedux.useSelector(getAccessToken)

  const toggleShouldTranslate = () => {
    setShouldTranslate(!shouldTranslate)
  }

  const deleteProfilePicCreator = (position) => () => {
    dispatch(setHasTakenModerationAction(true))

    const positions = getPositionNumberFromString(position)

    for (const position of positions) {
      dispatch(
        deleteProfilePic({
          userId: report.user.id,
          position,
        })
      )
    }
  }

  const deleteDisplayName = () => {
    dispatch(setHasTakenModerationAction(true))
    dispatch(changeSettings({ displayName: '_', id: report.user.id }))
  }

  const deleteProfileStatus = () => {
    dispatch(setHasTakenModerationAction(true))
    dispatch(changeSettings({ aboutMe: '', id: report.user.id }))
  }

  const hasNoAvatar = profile.avatars.length === 0

  const { data: userLog } = useUserLogQuery({
    userId: report.user.id,
    enabled: hasNoAvatar,
  })

  const currentAvatarFileNames = profile.avatars.map(
    ({ avatarFileName }) => avatarFileName
  )

  const userLogAvatars = Array.from(
    userLog?.logEntries?.reduce((acc, item) => {
      const isAvatarEntry = [
        USER_LOG_TYPE.AVATAR,
        USER_LOG_TYPE.UPLOAD_ADDITIONAL_AVATAR,
      ].includes(item.type)

      if (isAvatarEntry) {
        acc.add(item.oldValue)
        acc.add(item.newValue)
      }

      return acc
    }, new Set()) ?? []
  ).filter(
    (avatarFileName) =>
      !currentAvatarFileNames.includes(avatarFileName) &&
      avatarFileName !== 'null' &&
      typeof avatarFileName !== 'undefined'
  )

  return (
    <>
      <Box flexDirection="row">
        <Box>
          <AvatarGroup
            containerProps={{ width: MAIN_SECTION_LEFT_WIDTH }}
            creatorOnPressAvatar={modalAvatarCreator}
            user={profile}
          />
          <Box
            flexDirection="row"
            gap={8}
            paddingTop={8}
            justifyContent="space-between">
            <AntdButton
              type="dashed"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={deleteProfilePicCreator(PIC_DELETE_TYPES.LEFT)}
              style={{ width: 110, marginRight: 8 }}>
              Delete
            </AntdButton>
            <AntdButton
              type="dashed"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={deleteProfilePicCreator(PIC_DELETE_TYPES.CENTER)}
              style={{ width: 110, marginRight: 8 }}>
              Delete
            </AntdButton>
            <AntdButton
              type="dashed"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={deleteProfilePicCreator(PIC_DELETE_TYPES.RIGHT)}
              style={{ width: 110 }}>
              Delete
            </AntdButton>
          </Box>
          <Box paddingTop={8}>
            <AntdButton
              type="dashed"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={deleteProfilePicCreator(PIC_DELETE_TYPES.ALL)}>
              Delete All Avatars
            </AntdButton>
          </Box>
        </Box>
        <Box paddingLeft={24} width={MAIN_SECTION_RIGHT_WIDTH} />
      </Box>
      <Box flexDirection="row" marginTop={12}>
        <Box width={MAIN_SECTION_LEFT_WIDTH} alignItems="flex-start">
          <UserTypeBadge type={profile.type} />

          <Box flexDirection="row">
            <Box width={MAIN_SECTION_LEFT_WIDTH}>
              <Box flexDirection="row" alignItems="center" marginTop={8}>
                <Text bold style={{}}>
                  {profile.displayName}
                </Text>
                {(profile.verified || profile.isVerified) && (
                  <Box marginLeft={4}>
                    <IconVerified size={16} />
                  </Box>
                )}
              </Box>
            </Box>

            <Box width={MAIN_SECTION_RIGHT_WIDTH} paddingLeft={24}>
              <Box marginTop={8}>
                <AntdButton
                  type="dashed"
                  shape="round"
                  icon={<DeleteOutlined />}
                  onClick={deleteDisplayName}>
                  Display name
                </AntdButton>
              </Box>
            </Box>
          </Box>

          <Text
            color={theme.colors.placeholder}
            style={{ marginTop: 4 }}>{`@${profile.username}`}</Text>
          <Text color={theme.colors.placeholder}>
            {`joined ${dayjs(profile.registered).fromNow()}`}
          </Text>
        </Box>

        <Box width={MAIN_SECTION_RIGHT_WIDTH} paddingLeft={24} />
      </Box>

      <Box flexDirection="row">
        <Box width={MAIN_SECTION_LEFT_WIDTH}>
          <TextTranslatable.Pure
            shouldTranslate={shouldTranslate}
            style={styleSheets.margin.top[8]}>
            {profile.aboutMe}
          </TextTranslatable.Pure>
        </Box>

        <Box width={MAIN_SECTION_RIGHT_WIDTH} paddingLeft={24}>
          {profile.aboutMe !== '' && (
            <Box marginTop={8}>
              <AntdButton
                type="dashed"
                shape="round"
                icon={<DeleteOutlined />}
                onClick={deleteProfileStatus}>
                About Me
              </AntdButton>

              <AntdButton
                type="dashed"
                shape="round"
                onClick={toggleShouldTranslate}
                style={styleSheets.margin.top[8]}>
                {shouldTranslate ? 'Original' : 'Translate'}
              </AntdButton>
            </Box>
          )}
        </Box>
      </Box>

      {userLogAvatars?.length > 0 && (
        <Box
          marginTop={24}
          width={MAIN_SECTION_LEFT_WIDTH + MAIN_SECTION_RIGHT_WIDTH}>
          <Text style={{ fontWeight: '500' }}>Previous Avatars</Text>

          <Box flexDirection="row" overflowY="scroll">
            {userLogAvatars?.map((avatarFileName) => {
              const avatarPath = `${config.api.host}/admin/user/settings/avatarhistory/${avatarFileName}?access_token=${accessToken}`

              return (
                <Box
                  key={avatarFileName}
                  onPress={modalAvatarCreator(avatarPath)}
                  marginRight={8}>
                  <Avatar
                    rectangle
                    borderColor={theme.colors.black}
                    user={{ avatarFileName }}
                    size={100}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </>
  )
}

const LeftSection = ({ report, profile }) => {
  const isProfileReport = report.type === REPORT_TYPE.PROFILE

  return (
    <>
      <Text type="note" color={colors.grey[7]}>
        {report.id}
      </Text>
      {!isProfileReport && (
        <TextLabel label="Report Type">{REPORT_TYPE[report.type]}</TextLabel>
      )}
      <TextLabel label="Report Count">{report.issuerCount ?? 0}</TextLabel>
      <TextLabel label="Open since">
        <Tooltip title={dayjs(report.createdAt).toLocaleString()}>
          {dayjs(report.createdAt).fromNow()}
        </Tooltip>
      </TextLabel>
      <PastBans profile={profile} />
      {Boolean(isProfileReport) && <AccountBansByDevice profile={profile} />}
      <TextLabel label="Comments">
        {report?.comments?.length ? (
          <CommentsList comments={report.comments} reportedUser={report.user} />
        ) : (
          'none'
        )}
      </TextLabel>
    </>
  )
}

const RightSection = ({ profile, report, state }) => {
  const dispatch = ReactRedux.useDispatch()

  const isProfileReport = report.type === REPORT_TYPE.PROFILE
  const hasProfileReportData = isProfileReport && typeof profile !== 'undefined'
  const isCommunityTellReport = report.type === REPORT_TYPE.COMMUNITY_TELL
  const isCommentReport = report.type === REPORT_TYPE.POST_COMMENT

  const hasTakenAction = ReactRedux.useSelector(getHasTakenModerationAction)

  const { mutate: triggerQuickBanSexualMutation } = useQuickBanSexualMutation()

  const { mutate: triggerQuickBanSexualPermaMutation } =
    useQuickBanSexualPermaMutation()

  const { mutate: voteReportMutation } = useVoteReportMutation()

  const onPressVoteCreator = (chosenVoteType) => () => {
    dispatch(setHasTakenModerationAction(true))

    if (chosenVoteType !== REPORT_DECISION.NOT_OKAY) {
      voteReportMutation({
        decision: chosenVoteType,
        reportId: report.id,
        reportType: report.type,
      })

      return
    }

    const commentIdsMarkedForDeletion = Object.keys(
      state.commentIdsMarkedForDeletionMap
    ).map((id) => parseInt(id, 10))

    if (commentIdsMarkedForDeletion.length > 0) {
      for (const commentId of commentIdsMarkedForDeletion) {
        dispatch(
          removePostComment({
            id: commentId,
          })
        )
      }
    }

    if (state.isAnswerMarkedForDeletion) {
      dispatch(blockAnswer({ id: report.postId }))
      dispatch(blockTell({ id: report.tell.id }))
    }

    voteReportMutation({
      decision: chosenVoteType,
      reportId: report.id,
      reportType: report.type,
    })
  }

  const createBanCreator = (userId, lang) => async () => {
    const { hasCreatedBan } = await ModalCreateBan.show({ userId, lang })

    if (hasCreatedBan) {
      dispatch(setHasTakenModerationAction(true))
    }
  }

  const createBanProfile = createBanCreator(profile?.id, profile?.lang)

  const createBanReceiver = createBanCreator(
    report.tell?.receiver?.id,
    report.tell?.receiver?.lang
  )
  const createBanSender = createBanCreator(
    report.tell?.sender?.id,
    report.tell?.sender?.lang
  )

  const quickBanSexual = () => {
    if (isProfileReport) {
      triggerQuickBanSexualMutation({
        userId: report.user?.id,
        reportId: report.id,
      })
    }
  }

  const quick7DayBan = () => {
    if (isProfileReport) {
      dispatch(createQuick7DayBan({ userId: report.user?.id }))
    }
  }

  const quickBanSexualPerma = () => {
    if (isProfileReport) {
      triggerQuickBanSexualPermaMutation({
        userId: report.user?.id,
        reportId: report.id,
      })
    }
  }

  const createWarningCreator = (userId) => async () => {
    const { hasCreatedWarning } = await ModalCreateWarning.show({ userId })

    if (hasCreatedWarning) {
      dispatch(setHasTakenModerationAction(true))
    }
  }

  const createWarningProfile = createWarningCreator(profile?.id)
  const createWarningReceiver = createWarningCreator(report.tell?.receiver?.id)
  const createWarningSender = createWarningCreator(report.tell?.sender?.id)

  const areShortcutsActive = hooks.useCapsLockShortcuts(
    [REPORT_DECISION.NOT_OKAY, REPORT_DECISION.SKIP, REPORT_DECISION.OKAY].map(
      (action, index) => ({
        keyCode: 49 + index, // 49 is the keyCode for 1
        onPress: () => {
          if (typeof action === 'function') {
            action()
          } else {
            if (hasTakenAction) {
              // The user took an action so he can not skip or declare the profile as okay
              if (action === REPORT_DECISION.NOT_OKAY) {
                onPressVoteCreator(REPORT_DECISION.NOT_OKAY)()
              }
            } else {
              onPressVoteCreator(action)()
            }
          }
        },
      })
    )
  )

  return (
    <>
      <Box transparent flexDirection="row" justifyContent="space-between">
        <Badge count={areShortcutsActive ? 1 : 0} color={colors.grey[7]}>
          <AntdButton
            danger
            shape="round"
            onClick={onPressVoteCreator(REPORT_DECISION.NOT_OKAY)}>
            {isProfileReport || isCommentReport
              ? 'Took action'
              : isCommunityTellReport
              ? 'Delete including answers'
              : REPORT_DECISION[REPORT_DECISION.NOT_OKAY]}
          </AntdButton>
        </Badge>
        <Badge count={areShortcutsActive ? 2 : 0} color={colors.grey[7]}>
          <AntdButton
            shape="round"
            onClick={onPressVoteCreator(REPORT_DECISION.SKIP)}
            disabled={hasTakenAction}
            style={{ marginLeft: 12, marginRight: 12 }}>
            {REPORT_DECISION[REPORT_DECISION.SKIP]}
          </AntdButton>
        </Badge>
        <Badge count={areShortcutsActive ? 3 : 0} color={colors.grey[7]}>
          <AntdButton
            type="primary"
            shape="round"
            ghost
            onClick={onPressVoteCreator(REPORT_DECISION.OKAY)}
            disabled={hasTakenAction}>
            {REPORT_DECISION[REPORT_DECISION.OKAY]}
          </AntdButton>
        </Badge>
      </Box>

      {hasProfileReportData && (
        <Box transparent width={160} marginTop={24} alignSelf="flex-end">
          <ProfileStatsItem
            name="Follower"
            value={profile.follower}
            onPress={() => ModalFollowers.show({ userId: profile.id })}
          />
          <ProfileStatsItem
            name="Following"
            value={profile.followingCount}
            onPress={() => ModalFollowings.show({ userId: profile.id })}
          />
          <ProfileStatsItem name="Tells" value={profile.tellCount} />
          <ProfileStatsItem name="Answers" value={profile.answerCount} />
          <ProfileStatsItem name="Sent Tells" value={profile.sentTellCount} />
        </Box>
      )}

      <Box transparent alignItems="flex-end" marginTop={24}>
        <Box transparent>
          {isCommunityTellReport && (
            <AntdButton
              shape="round"
              onClick={onPressVoteCreator(REPORT_DECISION.NOT_OKAY_ONLY_TELL)}
              style={styles.bottomMargin}>
              Delete only Tell
            </AntdButton>
          )}
          <AntdButton
            shape="round"
            onClick={
              isProfileReport ? createWarningProfile : createWarningReceiver
            }
            style={styles.bottomMargin}>
            Create warning{isProfileReport ? '' : ' receiver'}
          </AntdButton>
          <AntdButton
            shape="round"
            onClick={isProfileReport ? createBanProfile : createBanReceiver}
            style={styles.bottomMargin}>
            Create ban{isProfileReport ? '' : ' receiver'}
          </AntdButton>
          {report.tell?.sender?.id ? (
            <>
              <AntdButton
                shape="round"
                onClick={createWarningSender}
                style={styles.bottomMargin}>
                Create warning sender
              </AntdButton>
              <AntdButton
                shape="round"
                onClick={createBanSender}
                style={styles.bottomMargin}>
                Create ban sender
              </AntdButton>
            </>
          ) : null}
          {isProfileReport && (
            <>
              <AntdButton
                shape="round"
                onClick={quickBanSexual}
                style={styles.bottomMargin}>
                Quick Ban Sexual
              </AntdButton>
              <AntdButton
                shape="round"
                onClick={quick7DayBan}
                style={styles.bottomMargin}>
                Quick 7d Ban
              </AntdButton>
              <AntdButton
                shape="round"
                onClick={quickBanSexualPerma}
                style={styles.bottomMargin}>
                Quick Perma Ban Sexual
              </AntdButton>
              <ShadowBanButtons profile={profile} report={report} />
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

const ReportSection = ({ report, profile }) => {
  const isProfileReport = report.type === REPORT_TYPE.PROFILE
  const hasProfileReportData = isProfileReport && typeof profile !== 'undefined'
  const isCommentReport = report.type === REPORT_TYPE.POST_COMMENT
  const isTellReport = typeof report.tell !== 'undefined'

  const getThemedColor = hooks.useThemedColor()

  const { isLoading: isLoadingBan } = useQuickBanSexualMutation()

  const { isLoading: isLoadingBanPerma } = useQuickBanSexualPermaMutation()

  const { isFetching } = useVoteReportMutation()

  const isLoading = isFetching || isLoadingBan || isLoadingBanPerma

  const [state, setState] = React.useState({
    commentIdsMarkedForDeletionMap: {},
    isAnswerMarkedForDeletion: false,
  })

  return (
    <Spin spinning={isLoading}>
      <Box
        borderBottomWidth={1}
        borderColor={getThemedColor(
          theme.colors.antdBackgroundElevatedBorder,
          colors.black[1]
        )}
        borderStyle="solid"
        backgroundColor={getThemedColor(colors.white[1], colors.black[5])}
        paddingHorizontal={16}
        paddingVertical={24}
        marginBottom={32}
        borderRadius={8}>
        <Box transparent position="absolute" top={4} left={0} right={0}>
          <Text center color={colors.grey[7]}>
            {report.reasons.map((reason) => REPORT_REASON[reason]).join(', ')}
          </Text>
        </Box>
        <Box transparent flexDirection="row">
          <Box flex={1} transparent>
            <LeftSection report={report} profile={profile} />
          </Box>
          <Box
            transparent
            flex={2}
            paddingLeft={24}
            paddingRight={24}
            alignItems="center">
            {hasProfileReportData ? (
              <ProfileWithActions report={report} profile={profile} />
            ) : isTellReport ? (
              <TellPostItem item={report} state={state} setState={setState} />
            ) : null}
          </Box>
          <Box transparent>
            <RightSection
              profile={profile}
              report={report}
              state={state}
              setState={setState}
            />
          </Box>
        </Box>

        {isCommentReport && (
          <Box transparent marginTop={24}>
            <Box
              backgroundColor={colors.grey[7]}
              height={1}
              marginBottom={48}
            />
            <TextLabel
              label="Okay Button"
              labelStyle={{ color: colors.grey[6] }}>
              Informs the report creator that he mistakenly reported.
            </TextLabel>
            <TextLabel
              label="Took Action"
              labelStyle={{ color: colors.grey[6] }}>
              Sends information to report creator that we appreciate his report
              and that we did something about it. When deleting a comment, we
              instantly inform the comment creator with a report notification.
            </TextLabel>
          </Box>
        )}
      </Box>
    </Spin>
  )
}

const TABS = {
  TELLS: 'tells',
  COMMUNITY_TELLS: 'communityTells',
  PROFILES: 'profiles',
}

const tabTitles = {
  [TABS.TELLS]: 'Answers',
  [TABS.COMMUNITY_TELLS]: 'Community Tells',
  [TABS.PROFILES]: 'Profiles',
}

const tabOptions = Object.values(TABS).map((value) => ({
  label: tabTitles[value],
  value,
}))

const defaultQueryParams = {
  moderationTab: TABS.TELLS,
}

const REPORT_FILTER_TYPES = {
  ALL_PROFILE_REPORTS: 'All Profile Reports',
  ALL_AUTO_REPORTS: 'All Automatic Reports',
  ONLY_AUTO_NSFW: 'Only Auto NSFW',
  ONLY_CHAT_GPT: 'Only ChatGPT',
  ONLY_SHADOW_BAN: 'Only Shadow Ban',
  ONLY_MANUAL_REPORTS: 'Only Manual Reports',
}

const filterOptions = convertObjectToOptions(REPORT_FILTER_TYPES)

const autoReportNumbers = [
  REPORT_REASON.AUTOMATED_NSFW_DETECTION,
  REPORT_REASON.AUTOMATED_GPT_PROFILE_DETECTION,
]
const manualReportNumbers = Object.values(REPORT_REASON).filter(
  (i) => typeof i === 'number' && autoReportNumbers.includes(i) === false
)

const getReportReasonsFromReportFilter = (filterValue) => {
  switch (filterValue) {
    case REPORT_FILTER_TYPES.ALL_AUTO_REPORTS:
      return [autoReportNumbers, true]

    case REPORT_FILTER_TYPES.ONLY_AUTO_NSFW:
      return [[REPORT_REASON.AUTOMATED_NSFW_DETECTION], false]

    case REPORT_FILTER_TYPES.ONLY_CHAT_GPT:
      return [[REPORT_REASON.AUTOMATED_GPT_PROFILE_DETECTION], false]

    case REPORT_FILTER_TYPES.ONLY_SHADOW_BAN:
      // shadow bans are saved with reason REPORT_REASON.NONE and text_reason "Shadow Ban ...", the latter is controlled with the boolean
      return [[REPORT_REASON.NONE], true]

    case REPORT_FILTER_TYPES.ONLY_MANUAL_REPORTS:
      return [manualReportNumbers, false]

    case REPORT_FILTER_TYPES.ALL_PROFILE_REPORTS:
    default:
      return [[], true]
  }
}

const HeaderBar = ({ onChangeReportReasonFilter, openReportsCount = 0 }) => {
  const [queryParams, setQueryParams] = hooks.useQueryParams(defaultQueryParams)

  const onChangeTab = React.useCallback(
    (e) => {
      setQueryParams({ moderationTab: e.target.value })
    },
    [setQueryParams]
  )

  const showActionDescriptions = React.useCallback(() => {
    ModalActionDescriptions.show()
  }, [])

  return (
    <Box
      flexDirection="row"
      marginTop={24}
      justifyContent="center"
      backgroundColor="transparent">
      <Radio.Group
        optionType="button"
        options={tabOptions}
        onChange={onChangeTab}
        value={queryParams.moderationTab}
        size="large"
      />
      <Box
        position="absolute"
        right={24}
        backgroundColor="transparent"
        flexDirection="row"
        alignItems="center"
        gap={12}>
        {queryParams.moderationTab === TABS.PROFILES && (
          <Select
            defaultValue={REPORT_FILTER_TYPES.ALL_PROFILE_REPORTS}
            dropdownMatchSelectWidth={false}
            onChange={onChangeReportReasonFilter}
            options={filterOptions}
            style={{ width: 160, fontSize: 12 }}
          />
        )}
        <OpenReportsLabel
          shouldAlignRight
          value={`${openReportsCount} open reports`}
          borderColor={colors.red[1]}
          textColor={colors.red[1]}
        />
        <Box onPress={showActionDescriptions} transparent>
          <Image
            source={require('../../common/assets/info-circle.png')}
            style={{ width: 32, height: 32 }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const PageModeration = () => {
  const [queryParams] = hooks.useQueryParams(defaultQueryParams)

  const getThemedColor = hooks.useThemedColor()

  const containerStyle = hooks.usePageContainerStyle()

  const [reportReasonsFilter, setReportReasonsFilter] = React.useState([])
  const [shouldShowShadowBans, setShouldShowShadowBans] = React.useState(true)

  const profileReports = useProfileReportsQuery({
    isActive: queryParams.moderationTab === TABS.PROFILES,
    reasonFilter: reportReasonsFilter,
    shouldShowShadowBans,
  })

  const postReports = usePostReportsQuery({
    isActive: queryParams.moderationTab === TABS.TELLS,
  })

  const communityTellReports = useCommunityTellReportsQuery({
    isActive: queryParams.moderationTab === TABS.COMMUNITY_TELLS,
  })

  const { data, isFetching } = React.useMemo(() => {
    switch (queryParams.moderationTab) {
      case TABS.TELLS:
        return postReports

      case TABS.COMMUNITY_TELLS:
        return communityTellReports

      case TABS.PROFILES:
        return profileReports

      default:
        return {}
    }
  }, [
    communityTellReports,
    postReports,
    profileReports,
    queryParams.moderationTab,
  ])

  const { data: user } = useProfileSettingsQuery({
    userId: data?.reports?.[0]?.user?.id,
  })

  const onChangeReportReasonFilter = (filterValue) => {
    const [reasonFilterNew, shouldFilterShadowBansNew] =
      getReportReasonsFromReportFilter(filterValue)

    setReportReasonsFilter(reasonFilterNew)
    setShouldShowShadowBans(shouldFilterShadowBansNew)
  }

  return (
    <Box
      flex={1}
      backgroundColor={getThemedColor(
        colors.modcp[3],
        theme.colors.antdDarkModeBackground
      )}
      style={containerStyle}>
      <Spin spinning={isFetching}>
        <HeaderBar
          onChangeReportReasonFilter={onChangeReportReasonFilter}
          openReportsCount={data?.openReports}
        />
        {data?.reports?.map((item) => (
          <Box transparent key={item.id} marginBottom={24} padding={20}>
            <ReportSection report={item} profile={user} />
            {typeof item.communityTell !== 'undefined' ? (
              <CommunityTellItem item={item} />
            ) : typeof user !== 'undefined' ? (
              <PageProfile profile={user} />
            ) : null}
          </Box>
        )) ?? <Box height={200} width={50} backgroundColor="transparent" />}
      </Spin>
    </Box>
  )
}

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Line } from '@ant-design/plots'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import { Button, Modal, Radio, Spin, Table, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { config } from '../../../config'
import { Box, Image, hooks, styleSheets } from '../../common'
import {
  useTikTokAccountsQuery,
  useTikTokCookiesMutation,
  useTikTokStatsQuery,
  useTikTokVideosQuery,
} from '../queries'

const Cover = ({ coverImageUrl, width, height, isVisible: isVisibleOuter }) => {
  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    setIsVisible(isVisibleOuter)
  }, [isVisibleOuter])

  if (!isVisible) {
    return (
      <Typography.Link onClick={() => setIsVisible(true)}>Show</Typography.Link>
    )
  }

  return (
    <Image
      source={coverImageUrl}
      style={{
        width: (width || 1080) / 10, // we need the || operator here since width is 0 for image slides
        height: (height || 1920) / 10, // we need the || operator here since height is 0 for image slides
      }}
    />
  )
}

function secondsToHms(seconds) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const hDisplay = h < 10 ? `0${h}` : h
  const mDisplay = m < 10 ? `0${m}` : m
  const sDisplay = s < 10 ? `0${s}` : s

  return `${hDisplay}:${mDisplay}:${sDisplay}`
}

const Graph = ({ videoIds }) => {
  const [metric, setMetric] = React.useState('views')

  const { data, isLoading: isLoadingData } = useTikTokStatsQuery({
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    endDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    videoIds,
  })

  const onChangeMetric = (e) => {
    setMetric(e.target.value)
  }

  const dataSource = React.useMemo(() => {
    if (typeof data?.data === 'undefined') {
      return []
    }

    return data.data
      .map((item) => ({
        comments: item.comments,
        likes: item.likes,
        shares: item.shares,
        sincePosted: Math.round(item.sincePosted / 5) * 5,
        title: item.title,
        videoId: item.videoId,
        views: item.views,
      }))
      .sort((a, b) => a.sincePosted - b.sincePosted)
  }, [data])

  const lineConfig = React.useMemo(
    () => ({
      padding: 'auto',
      xField: 'sincePosted',
      yField: metric,
      seriesField: 'videoId',
      width: 950,
      yAxis: {
        label: {
          formatter: shortenNumber,
        },
      },
      xAxis: {
        label: {
          formatter: secondsToHms,
        },
      },
      legend: {
        itemName: {
          formatter: (vId) =>
            data.data.find(({ videoId }) => vId === videoId).title,
        },
      },
      tooltip: {
        formatter: (item) => {
          return {
            name: secondsToHms(item.sincePosted),
            value: `${item[metric]} ${metric}`,
          }
        },
        title: 'title',
      },
    }),
    [data?.data, metric]
  )

  return (
    <>
      <Radio.Group
        optionType="button"
        size="small"
        value={metric}
        onChange={onChangeMetric}
        options={['views', 'likes', 'comments', 'shares']}
        style={styleSheets.margin.bottom[12]}
      />
      <Spin spinning={isLoadingData}>
        <Box flexDirection="row" flex={1} width="100%">
          <Line data={dataSource} {...lineConfig} />
        </Box>
      </Spin>
    </>
  )
}

const ModalGraph = ({ isVisible, onClose, ...props }) => (
  <Modal
    width={1000}
    title="TikTok video stats"
    open={isVisible}
    onOk={onClose}
    onCancel={onClose}>
    <Graph {...props} />
  </Modal>
)

export const PageTiktokStats = () => {
  const containerStyle = hooks.usePageContainerStyle()
  const [shouldShowAllCovers, setShouldShowAllCovers] = React.useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])
  const [isGraphVisible, setIsGraphVisible] = React.useState(false)

  const { data: accounts, isLoading: isLoadingAccounts } =
    useTikTokAccountsQuery()
  const { data, isLoading } = useTikTokVideosQuery()
  const { mutate, isLoadingCookies } = useTikTokCookiesMutation()

  const addTiktokAccount = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          window.open(`${config.api.host}/accounts/connect/tiktok`, '_self')
        },
      }
    )
  }

  const onPressIsGraphVisible = () => {
    setIsGraphVisible(true)
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowKeys.length > 0

  const columns = React.useMemo(
    () => [
      {
        title: 'Cover',
        dataIndex: 'coverImageUrl',
        key: 'coverImageUrl',
        render: (coverImageUrl, record) => (
          <Cover
            isVisible={shouldShowAllCovers}
            coverImageUrl={coverImageUrl}
            height={record.height}
            width={record.width}
          />
        ),
        filterDropdown: () => null,
        filterIcon: () =>
          shouldShowAllCovers ? <EyeInvisibleOutlined /> : <EyeOutlined />,
        onFilterDropdownOpenChange: (isVisible) => {
          setShouldShowAllCovers(isVisible)
        },
        onFilter: () => true,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title, record) => (
          <Box transparent>
            <Typography.Text style={{ fontSize: 12 }}>{title}</Typography.Text>
            {title !== record.description && (
              <Typography.Text type="secondary">
                {record.description}
              </Typography.Text>
            )}
          </Box>
        ),
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Views',
        dataIndex: 'views',
        key: 'views',
        sorter: (a, b) => a.views - b.views,
      },
      {
        title: 'Likes',
        dataIndex: 'likes',
        key: 'likes',
        sorter: (a, b) => a.likes - b.likes,
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
        sorter: (a, b) => a.comments - b.comments,
      },
      {
        title: 'Shares',
        dataIndex: 'shares',
        key: 'shares',
        sorter: (a, b) => a.shares - b.shares,
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('DD.MM.YYYY'),
        sorter: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? 1 : -1),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Account',
        dataIndex: ['account', 'displayName'],
        key: 'accountDisplayName',
        width: 180,
        render: (displayName, record) => (
          <Box flexDirection="row" alignItems="center" transparent>
            <Image
              source={record.account.avatarUrl}
              style={{ width: 20, height: 20, marginRight: 8 }}
            />
            <Typography.Text style={{ fontSize: 12 }}>
              {displayName}
            </Typography.Text>
          </Box>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'shareUrl',
        key: 'shareUrl',
        render: (shareUrl) => (
          <Typography.Link onClick={() => window.open(shareUrl, '_blank')}>
            open
          </Typography.Link>
        ),
      },
    ],
    [shouldShowAllCovers]
  )

  return (
    <Box style={containerStyle}>
      <ModalGraph
        isVisible={isGraphVisible}
        onClose={() => {
          setIsGraphVisible(false)
        }}
        videoIds={selectedRowKeys}
      />

      <Box padding={32} backgroundColor={colors.background}>
        <Typography.Title>Tiktok Stats</Typography.Title>

        <Spin spinning={isLoadingAccounts}>
          <Box
            marginTop={12}
            alignItems="center"
            flexDirection="row"
            flexWrap="wrap">
            <Button
              loading={isLoadingCookies}
              onClick={addTiktokAccount}
              style={{ marginRight: 12, marginTop: 12 }}>
              Add Tiktok Account
            </Button>
            {accounts?.map((account) => (
              <Box
                key={account.id}
                borderWidth={1}
                borderColor={colors.grey[4]}
                borderStyle="solid"
                borderRadius={8}
                paddingHorizontal={8}
                paddingVertical={4}
                marginRight={12}
                marginTop={12}
                flexDirection="row"
                alignItems="center">
                <Image
                  source={account.avatarUrl}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                <Typography.Text>{account.displayName}</Typography.Text>
              </Box>
            ))}
          </Box>
        </Spin>

        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={24}>
          <Button
            disabled={hasSelected === false}
            type="primary"
            onClick={onPressIsGraphVisible}
            style={{ marginRight: 12 }}>
            Show Graph
          </Button>

          <Typography.Text type="secondary">{`videos: ${
            data?.length ?? '-'
          }`}</Typography.Text>
        </Box>

        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowSelection={rowSelection}
          rowKey="id"
          size="medium"
          pagination={false}
          style={{ marginTop: 24 }}
        />
      </Box>
    </Box>
  )
}

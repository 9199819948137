import docs from '@tellonym/analytics-devdocs/docs'
import { APP, APP_UA, WEB_UA } from '@tellonym/enums/lib/AnalyticsEvents'
import { mergeRight } from 'ramda'

export const name = 'analytics'

export const EVENT_TYPE = {
  REGISTERED: 'registered',
  UNREGISTERED: 'unregistered',
}

export const EVENT_SEND_BATCH_SIZE = 50
export const EVENT_SEND_THRESHOLD_REGISTERED = 20
export const EVENT_SEND_THRESHOLD_UNREGISTERED = 4

export const eventTypes = [EVENT_TYPE.REGISTERED, EVENT_TYPE.UNREGISTERED]

const getMap = (obj) =>
  Object.keys(obj).reduce(
    (acc, key) => mergeRight(acc, { [obj[key]]: true }),
    {}
  )

export const REGISTERED_EVENTS_MAP = getMap(APP)

export const UNREGISTERED_EVENTS_MAP = mergeRight(
  getMap(APP_UA),
  getMap(WEB_UA)
)

export let eventProperties = {}

if (__DEV__) {
  eventProperties = ['appEvents', 'appUaEvents', 'webUaEvents'].reduce(
    (acc, group) => ({
      ...acc,
      ...Object.keys(docs[group]).reduce(
        (acc, event) => ({
          ...acc,
          [event]: [
            ...new Set(
              ['eventProperties', 'userProperties']
                .reduce(
                  (acc, category) => [
                    ...acc,
                    ...Object.keys(docs[group][event][category]).reduce(
                      (acc, param) =>
                        ['country', 'primaryLocale'].includes(
                          docs[group][event][category][param].propName
                        )
                          ? acc
                          : [
                              ...acc,
                              docs[group][event][category][param].propName,
                            ],
                      []
                    ),
                  ],
                  group.startsWith('web')
                    ? [
                        'activeExperiment',
                        'appLanguage',
                        'appVersion',
                        'browser',
                        'browserVersion',
                        'isDesktop',
                        'isInstagramInAppBrowser',
                        'platform',
                      ]
                    : [
                        'activeExperiment',
                        'appLanguage',
                        'appVersion',
                        'appView',
                        'deviceModel',
                        'deviceOs',
                        'platform',
                        'tellonymUserId',
                      ]
                )
                .sort()
            ),
          ],
        }),
        {}
      ),
    }),
    {}
  )
}

export const KochavaAttributionStatus = {
  UNEVALUATED: 'UNEVALUATED',
  IN_PROGRESS: 'IN_PROGRESS',
  ATTRIBUTED: 'ATTRIBUTED',
  NOT_ATTRIBUTED: 'NOT_ATTRIBUTED',
}

export const onboardingSteps = {
  CONTACTS_CONNECT: 'CONTACTS_CONNECT',
  AVATAR_ADD: 'AVATAR_ADD',
  PHONE_CONNECT: 'PHONE_CONNECT',
  PROFILE_SHARE: 'PROFILE_SHARE',
  ABOUT_ME: 'ABOUT_ME',
}
